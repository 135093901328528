<template>
  <div>
    <PaymentMethodForm
      class="mb-4 no-gutters"
      :stripe-spk="stripeSpk"
      :links="links"
      @update="onUpdate($event)"
    />

    <div class="position-relative">
      <div
        v-if="isLoading"
        class="overlay d-flex justify-content-center align-items-center"
      >
        <Spinner />
      </div>
      <div>
        <PaymentMethodListItem
          v-for="paymentMethod in paymentMethods"
          :key="paymentMethod.id"
          :payment-method="paymentMethod"
          @update="onUpdate($event)"
        />
      </div>
    </div>

    <FormError v-if="isGetRequestFailed">
      Your payment methods could not be loaded.
    </FormError>
  </div>
</template>

<script>
import axios from 'axios';

import FormError from '@/src/shared/components/FormError.vue';
import Spinner from '@/src/shared/components/Spinner.vue';
import { createResult } from '@/src/shared/result';

import PaymentMethodForm from './PaymentMethodForm.vue';
import PaymentMethodListItem from './PaymentMethodListItem.vue';

export default {
  name: 'PaymentMethodList',

  components: {
    PaymentMethodForm,
    PaymentMethodListItem,
    FormError,
    Spinner,
  },

  props: {
    /** @type {import('vue').PropOptions<string>} */
    stripeSpk: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      /** @type {{ id: string }[]} */
      paymentMethods: [],
      isGetRequestFailed: false,
    };
  },

  async created() {
    await this.getPaymentMethods();
  },

  methods: {
    async onUpdate(promise) {
      this.isLoading = true;

      await promise;
      await this.getPaymentMethods();

      this.isLoading = false;
    },

    async getPaymentMethods() {
      const result = await createResult(
        axios.get('/api/account/detail/payment-method').then((resp) => {
          /** @type {{ id: string }[]} */
          const data = resp.data;

          return data;
        })
      );

      if (result.isFailure) {
        this.isGetRequestFailed = true;

        return;
      }

      this.isGetRequestFailed = false;

      this.paymentMethods = result.value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $transparent-black-10;
  z-index: 10;
}
</style>
