import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import SubscriptionList from './SubscriptionList.vue';

export async function load() {
  const selector = 'zel-subscription-list';

  if (isVueAppInDOM(selector)) {
    renderApp(SubscriptionList, selector);
  }
}
