/**
 * @param {import('axios').AxiosInstance} axios
 * @param {string} appSiteUrl
 */
export function configureAxios(axios, appSiteUrl) {
  axios.interceptors.request.use(function (requestConfig) {
    requestConfig.url = `${appSiteUrl}${requestConfig.url}`;

    return requestConfig;
  });
  axios.interceptors.response.use(undefined, function (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code === 'NOT_AUTHENTICATED'
    ) {
      if (window.pageBuilderMode.isLiveMode) {
        window.location.reload();
      }
    }

    return Promise.reject(error);
  });
}
