<template>
  <span :title="iconTitle" class="mx-2 align-self-center">
    <FontAwesomeIcon
      v-if="!isSuccessful"
      :icon="icons.faExclamationCircle"
      class="text-danger"
    />
    <FontAwesomeIcon
      v-if="isSuccessful"
      :icon="icons.faCheckCircle"
      class="text-success"
    />
  </span>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'Notification',

  components: {
    FontAwesomeIcon,
  },

  props: {
    isSuccessful: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    icons() {
      return { faCheckCircle, faExclamationCircle };
    },

    /** @returns {string} */
    iconTitle() {
      return this.isSuccessful ? 'Update successful' : 'Update failed';
    },
  },
};
</script>
