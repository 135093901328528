<template>
  <small class="text-danger m-2 d-inline-block font-weight-lighter">
    <slot>There was an unexpected error.</slot>
  </small>
</template>

<script>
export default {
  name: 'FormError',
};
</script>
