<template>
  <a
    :href="report.linkUrl"
    target="_blank"
    rel="noopener"
    class="list-group-item list-group-item-action flex-wrap flex-md-nowrap d-flex align-items-start p-sm-3 p-md-4 overflow-hidden"
  >
    <div class="foldedcorner" :class="contextClass">
      <span :title="iconTitle">
        <FontAwesomeIcon
          v-if="report.hasAccess && isAccessContextVisible"
          :icon="icons.faCheck"
        />
        <FontAwesomeIcon
          v-if="report.isForSale && isAccessContextVisible"
          :icon="icons.faDollarSign"
        />
      </span>
    </div>
    <div class="col-12 col-md-8 col-lg-9 m-0 p-0 order-2 order-md-1">
      <h5 class="mb-1">
        {{ report.title }}
      </h5>
      <h6 v-if="report.subtitle" class="mb-1">{{ report.subtitle }}</h6>
    </div>

    <div
      class="col-12 col-md-4 col-lg-3 text-md-right mb-1 mb-md-0 p-0 order-1 order-md-2 d-flex d-md-block"
    >
      <span class="d-md-block col m-0 p-0">{{ formattedDate }}</span>
      <span class="d-md-block small">
        <span v-if="report.popularity" class="text-uppercase">
          <strong
            class="pr-1"
            style="font-size: 10px; color: rgba(0, 0, 0, 0.4)"
          >
            Popularity:
          </strong>
        </span>

        <!-- eslint-disable vue/no-unused-vars -->
        <!-- eslint-disable vue/valid-v-for -->
        <FontAwesomeIcon
          v-for="n in getPopularity(report.popularity)"
          :key="n"
          :icon="icons.faThumbsUp"
          class="ml-1 text-secondary"
        />
      </span>
      <span v-if="maxRelevance" class="d-md-block small ml-2 ml-md-0">
        <span class="text-uppercase">
          <strong
            class="pr-1"
            style="font-size: 10px; color: rgba(0, 0, 0, 0.4)"
          >
            Relevance:
          </strong>
        </span>
        <span class="text-danger font-weight-bold">{{ relevance }}</span>
      </span>
    </div>
  </a>
</template>

<script>
import {
  faCheck,
  faDollarSign,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { formatDate, formatNumber } from '@/src/shared/formatters';

import { reportSearchComputed } from './store';

export default {
  name: 'ReportSearchResult',

  components: {
    FontAwesomeIcon,
  },

  props: {
    /** @type {import('vue').PropOptions<import('./store/state').ReportResult>} */
    report: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...reportSearchComputed,

    /**
     * @returns {string}
     */
    relevance() {
      return formatNumber((this.report.score / this.maxRelevance) * 100);
    },

    /**
     * @returns {string}
     */
    formattedDate() {
      return formatDate(this.report.date);
    },

    /**
     * @returns {{ faThumbsUp: any, faCheck: any, faDollarSign: any }}
     */
    icons() {
      return { faThumbsUp, faCheck, faDollarSign };
    },

    /**
     * @returns {string}
     */
    iconTitle() {
      if (!this.contextClass) {
        return '';
      }

      return this.contextClass === 'owned'
        ? 'This report is part of your library'
        : 'This report is available for individual sale';
    },

    /**
     * @returns {string}
     */
    contextClass() {
      if (!this.isAccessContextVisible) {
        return '';
      }

      return this.report.hasAccess
        ? 'owned'
        : this.report.isForSale
        ? 'available'
        : '';
    },
  },

  methods: {
    getPopularity(popularity) {
      return Array.from({ length: popularity }, (v, i) => i);
    },
  },
};
</script>
