import { actions, action } from './actions';
import { getters, checkoutComputed } from './getters';
import { mutations, mutation } from './mutations';
import { state } from './state';

/**
 * @type {import('vuex').Module<CheckoutState>}
 */
export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { action, mutation, checkoutComputed };

/**
 * @typedef {import('./state').CheckoutState} CheckoutState
 */
