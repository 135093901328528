<template>
  <Stripe :spk="stripeSpk">
    <form>
      <Elements class="form-group">
        <label for="card">Card</label>
        <Card id="card" ref="card" :options="stripeOptions" />
        <FormError v-if="cardError">
          {{
            cardError ||
            'There was an issue adding your card. Please try again.'
          }}
        </FormError>
      </Elements>
      <div class="d-flex">
        <button
          class="btn btn-orange btn-zelman text-uppercase mr-3"
          type="button"
          @click="onSubmit"
        >
          Add Card
        </button>
        <button
          type="button"
          class="btn btn-orange-outline btn-zelman text-uppercase"
          @click="onClear"
        >
          Clear
        </button>
      </div>

      <div
        v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful"
        class="mt-3"
      >
        <p class="text-white bg-danger rounded py-2 px-3 mb-0">
          <FontAwesomeIcon :icon="icons.faExclamationCircle" />
          There was an issue adding your new payment method, please
          <a :href="links.contactUsUrl" class="alert-link text-white"
            >contact support</a
          >.
        </p>
      </div>

      <div
        v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful"
        class="mt-3"
      >
        <p class="py-2 px-3 rounded bg-success text-white mb-0">
          <FontAwesomeIcon :icon="icons.faCheckCircle" />
          Your new payment method was added and set as your default for future
          charges.
        </p>
      </div>
    </form>
  </Stripe>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  Card,
  Elements,
  getStripe,
  Stripe,
} from '@kenkou/vue-stripe-elements/dist/lib.common.js';
import axios from 'axios';

import FormError from '@/src/shared/components/FormError.vue';
import { createResult } from '@/src/shared/result';

export default {
  name: 'PaymentMethodForm',

  components: {
    FontAwesomeIcon,
    Card,
    Stripe,
    Elements,
    FormError,
  },

  props: {
    /** @type {import('vue').PropOptions<string>} */
    stripeSpk: {
      type: String,
      default: '',
    },

    /** @type {import('vue').PropOptions<{ contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      uiState: {
        hasSubmitted: false,
        isSubmissionSuccessful: false,
      },
      // see https://stripe.com/docs/stripe.js#element-options for details
      stripeOptions: {
        classes: {
          base: 'form-control',
        },
        style: {
          base: {
            fontSize: '18px',
          },
        },
        hidePostalCode: false,
      },
      cardError: '',
    };
  },

  computed: {
    /** @returns {{ [key: string]: import('@fortawesome/fontawesome-svg-core').IconDefinition }}*/
    icons() {
      return { faCheckCircle, faExclamationCircle };
    },
  },

  methods: {
    onSubmit() {
      const promise = this.saveCard();

      this.$emit('update', promise);
    },

    async saveCard() {
      /** @type {any} */
      const card = this.$refs.card;

      const cardEl = card.element();

      const stripe = await getStripe();
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardEl,
      });

      if (error) {
        this.cardError =
          error.message ||
          'There was a problem saving your payment information.';

        return;
      }

      this.cardError = '';

      const result = await createResult(
        axios.post('/api/account/detail/payment-method', {
          paymentMethodId: paymentMethod.id,
        })
      );

      if (result.isFailure) {
        this.uiState.hasSubmitted = true;
        this.uiState.isSubmissionSuccessful = false;

        return;
      }

      this.uiState.hasSubmitted = true;
      this.uiState.isSubmissionSuccessful = true;

      cardEl.clear();
    },

    onClear() {
      /** @type {any} */
      const card = this.$refs.card;
      const cardEl = card.element();

      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;
      this.cardError = '';

      cardEl.clear();
    },
  },
};
</script>
