import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import SetPassword from './SetPassword.vue';

export function load() {
  const selector = 'zel-set-password';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(SetPassword, selector);
}
