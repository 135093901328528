export const STEP_NAMES = {
  preview: 'preview',
  payment: 'payment',
  review: 'review',
  complete: 'complete',
};

/**
 * @type {CheckoutState}
 */
export const state = {
  cart: {
    items: [],
    discounts: [],
    billingAddress: {
      billingName: '',
      line1: '',
      line2: '',
      city: '',
      stateId: 0,
      postalCode: '',
      countryId: 0,
      id: 0,
    },
    totals: {
      price: 0,
      discount: 0,
      tax: 0,
      subtotal: 0,
    },
    paymentOptions: [],
    paymentOptionId: '',
    paymentToken: '',
  },
  ui: {
    currentStep: {
      isEnabled: true,
      name: 'preview',
    },
    isLoading: false,
    hasError: false,
  },
  settings: {
    states: [],
    countries: [],
    stripeSpk: '',
  },
};

/**
 * @typedef CartItem
 * @property {number} id
 * @property {string} name
 * @property {string} number
 * @property {string} description
 * @property {number} price
 * @property {number} discount
 * @property {string} discountSummary
 * @property {string} type

 * @typedef Address
 * @property {number} id
 * @property {string} billingName
 * @property {string} line1
 * @property {string} line2
 * @property {string} city
 * @property {number} stateId
 * @property {string} postalCode
 * @property {number} countryId

 * @typedef PaymentOption
 * @property {string} id
 * @property {string} brand
 * @property {string} number
 * @property {string} expiration
 * @property {Address} address

 * @typedef CartTotals
 * @property {number} price
 * @property {number} tax
 * @property {number} discount
 * @property {number} subtotal

 * @typedef Cart
 * @property {CartItem[]} items
 * @property {string[]} discounts
 * @property {Address} billingAddress
 * @property {CartTotals} totals
 * @property {string} paymentToken
 * @property {PaymentOption[]} paymentOptions
 * @property {string} paymentToken
 * @property {string} paymentOptionId

 * @typedef StepState
 * @property {boolean} isEnabled
 * @property {CART_STEP} name

 * @typedef StateOption
 * @property {number} id
 * @property {number} countryId
 * @property {string} name

 * @typedef CountryOption
 * @property {number} id
 * @property {string} name

 * @typedef CartUI
 * @property {boolean} isLoading
 * @property {StepState} currentStep
 * @property {boolean} hasError

 * @typedef CartSettings
 * @property {StateOption[]} states
 * @property {CountryOption[]} countries
 * @property {string} stripeSpk

 * @typedef CheckoutState
 * @property {Cart} cart
 * @property {CartUI} ui
 * @property {CartSettings} settings

 * @typedef {keyof STEP_NAMES} CART_STEP

 * @typedef {{ [k in CART_STEP ]: CART_STEP }} CartStepLookup
 */
