<template>
  <div class="col-12 col-sm-9 col-md-6 col-lg-4 ml-auto text-black-50">
    <div class="row">
      <div class="col">Subtotal</div>
      <div class="col text-right font-weight-bold">
        {{ formattedSubtotal }}
      </div>
    </div>
    <div class="row">
      <div class="col">Discount</div>
      <div class="col text-right font-weight-bold">
        {{ formattedDiscount }}
      </div>
    </div>
    <div class="row">
      <div class="col">Tax</div>
      <div class="col text-right font-weight-bold">{{ formattedTax }}</div>
    </div>
    <div class="row text-dark" style="font-size: 1.2rem">
      <div class="col">Total</div>
      <div class="col text-right font-weight-bold">
        {{ formattedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/src/shared/formatters';

import { checkoutComputed } from '../store';

export default {
  name: 'Totals',

  computed: {
    ...checkoutComputed,

    /** @returns {string} */
    formattedDiscount() {
      return formatCurrency(this.totals.discount);
    },

    /** @returns {string} */
    formattedSubtotal() {
      return formatCurrency(this.totals.subtotal);
    },

    /** @returns {string} */
    formattedPrice() {
      return formatCurrency(this.totals.price);
    },

    /** @returns {string} */
    formattedTax() {
      return formatCurrency(this.totals.tax);
    },
  },
};
</script>
