<template>
  <div class="row">
    <div class="col-12">
      <Stripe :spk="settings.stripeSpk">
        <Elements>
          <form id="payment-form" @submit.prevent="onSubmitCard">
            <div class="form-group">
              <label for="card">Card *</label>
              <Card
                id="card"
                ref="card"
                :options="stripeOptions"
                @change="onCardChange"
              />
              <FormError v-if="error" id="card-errors" role="alert">
                {{ error }}
              </FormError>
            </div>
            <div class="d-flex justify-content-between">
              <button
                v-if="cart.paymentOptions.length"
                type="button"
                class="btn btn-outline-primary btn-zelman mr-3"
                @click="onReturnClick"
              >
                Return to List
              </button>
              <button class="btn btn-sm btn-secondary btn-zelman" type="submit">
                Save Card
              </button>
            </div>
          </form>
        </Elements>
      </Stripe>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Elements,
  getStripe,
  Stripe,
} from '@kenkou/vue-stripe-elements/dist/lib.common.js';

import FormError from '@/src/shared/components/FormError.vue';

import { action, checkoutComputed } from '../store';

export default {
  name: 'PaymentForm',

  components: {
    Card,
    Stripe,
    Elements,
    FormError,
  },

  data() {
    return {
      token: '',
      formState: {
        brand: '',
        complete: true,
        elementType: '',
        empty: true,
        error: undefined,
        value: {},
      },
      // see https://stripe.com/docs/stripe.js#element-options for details
      stripeOptions: {
        style: {
          base: {
            fontSize: '18px',
          },
        },
        hidePostalCode: true,
      },
      cardError: '',
    };
  },

  computed: {
    ...checkoutComputed,

    /** @returns {string} */
    error() {
      return this.cardError;
    },
  },

  methods: {
    onReturnClick() {
      /** @type {any} */
      const card = this.$refs.card;

      card.element().clear();

      this.cardError = '';

      this.$emit('cancel');
    },

    onCardChange(event) {
      this.formState = event;
    },

    async onSubmitCard() {
      /** @type {any} */
      const card = this.$refs.card;

      const cardEl = card.element();

      cardEl.update({
        value: { postalCode: this.cart.billingAddress.postalCode || '' },
      });

      const stripe = await getStripe();

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardEl,
      });

      if (error) {
        this.cardError = error.message;

        return;
      }

      const result = await this.$store.dispatch(
        action('SET_PAYMENT_OPTION'),
        paymentMethod.id
      );

      if (result.isFailure) {
        this.cardError = 'There was a problem saving your payment information';

        return;
      }

      cardEl.clear();

      this.cardError = '';

      this.$emit('cancel');
    },
  },
};
</script>
