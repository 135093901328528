<template>
  <div>
    <div :class="getValidationClass(state)">
      <InputValidationIcon v-if="showIcon" :control-state="state" />
      <slot />
    </div>
    <FormError v-if="state.errors[0]">
      {{ state.errors[0] }}
    </FormError>
  </div>
</template>

<script>
import FormError from './FormError.vue';
import InputValidationIcon from './InputValidationIcon.vue';

export default {
  components: {
    FormError,
    InputValidationIcon,
  },
  props: {
    state: {
      type: Object,
      required: true,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    getValidationClass(validationState) {
      if (!validationState) {
        return '';
      }

      const { untouched, pristine, valid, invalid } = validationState;

      if (untouched || (pristine && valid)) {
        return '';
      }

      if (valid) {
        return 'valid';
      } else if (invalid) {
        return 'invalid';
      }

      return '';
    },
  },
};
</script>
