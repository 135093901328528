import $ from 'jquery';

export function load() {
  const app = document.querySelector('[data-app="tidbits"]');

  if (!app) {
    return;
  }

  $('#tidbitModal').on('show.bs.modal', function (event) {
    handleModalOpen(event, this);
  });

  $('#noImagesTidbitModal').on('show.bs.modal', function (event) {
    handleModalOpen(event, this);
  });

  // Handle deep linked tidbits
  const selectedTidbitId = $(app).data('selected-tidbit-id');

  if (selectedTidbitId) {
    const selectedTidbitButton = document.querySelector(
      `button[data-source="#tidbit-${selectedTidbitId}"]`
    );

    if (isButton(selectedTidbitButton)) {
      selectedTidbitButton.click();
    }
  }
}

/**
 * @param {JQuery.TriggeredEvent<HTMLElement, undefined, HTMLElement, HTMLElement>} event
 * @param {HTMLElement} modal
 */
function handleModalOpen(event, modal) {
  if (!isModalEvent(event)) {
    return;
  }

  const button = $(event.relatedTarget); // Button that triggered the modal
  const tidbitID = button.data('source');

  const tidbit = document.querySelector(tidbitID);

  if (!isHtmlElement(tidbit)) {
    return;
  }

  mapTextContent(tidbit, modal, '[data-title]');
  mapTextContent(tidbit, modal, '[data-date]');
  mapTextContent(tidbit, modal, '[data-author-name]');
  mapHTML(tidbit, modal, '[data-content]', []);
  mapHTML(tidbit, modal, '[data-reference-url]', [
    '[data-reference-url-container]',
  ]);
  mapHTML(tidbit, modal, '[data-images]', []);
  mapHTML(tidbit, modal, '[data-carousel-indicators]', [
    '[data-carousel-indicators]',
    '[data-carousel-navigation]',
  ]);
}

/**
 * @param {any} event
 * @returns {event is {relatedTarget: HTMLElement}}
 */
function isModalEvent(event) {
  return 'relatedTarget' in event;
}

/**
 * @param {any} selection
 * @returns {selection is HTMLElement}}
 */
function isHtmlElement(selection) {
  return selection instanceof HTMLElement;
}

/**
 *
 * @param {HTMLElement} tidbit
 * @param {HTMLElement} modal
 * @param {string} selector
 * @param {string[]} hideIfNoneSelectors
 */
function mapHTML(tidbit, modal, selector, hideIfNoneSelectors) {
  const modalSelection = modal.querySelector(selector);

  if (!modalSelection) {
    return;
  }

  /** @type {Element[]} */
  const hideSelections = [];
  for (const hideIfNoneSelector of hideIfNoneSelectors) {
    const els = modal.querySelectorAll(hideIfNoneSelector);

    for (let index = 0; index < els.length; index++) {
      const el = els[index];

      hideSelections.push(el);
    }
  }

  if (!hideSelections && hideIfNoneSelectors.length) {
    return;
  }

  const tidbitSelection = tidbit.querySelector(selector);

  if (!tidbitSelection) {
    for (const hideSelection of hideSelections) {
      hideSelection.classList.add('d-none');
    }

    return;
  }

  for (const hideSelection of hideSelections) {
    hideSelection.classList.remove('d-none');
  }

  modalSelection.innerHTML = tidbitSelection.innerHTML;
}

/**
 *
 * @param {HTMLElement} tidbit
 * @param {HTMLElement} modal
 * @param {string} selector
 */
function mapTextContent(tidbit, modal, selector) {
  const tidbitSelection = tidbit.querySelector(selector);

  if (!tidbitSelection) {
    return;
  }

  const modalSelection = modal.querySelector(selector);

  if (!modalSelection) {
    return;
  }

  modalSelection.textContent = tidbitSelection.textContent;
}

/**
 * @param {Element | null} element
 * @returns {element is HTMLElement}}
 */
function isButton(element) {
  return element instanceof HTMLButtonElement;
}
