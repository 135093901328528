import { load as loadAccessRequestForm } from './AccessRequest/access-request-form-loader';
import { load as loadSetPassword } from './AccessRequest/set-password-loader';
import { load as loadAccountDashboard } from './Account/Dashboard/account-dashboard-loader';
import { load as loadAccountDetails } from './Account/Details/account-details-loader';
import { load as loadEmailPreferences } from './Account/EmailPreferences/email-preferences-loader';
import { load as loadOrderList } from './Account/OrderHistory/order-list-loader';
import { load as loadAccountTibits } from './Account/Tidbits/tidbits-modal';
import { load as loadCheckout } from './Checkout/checkout-loader';
import { load as loadCheckoutThankYou } from './Checkout/ThankYou/checkout-thank-you-loader';
import { load as loadCompanyProfile } from './CompanyProfile/company-profile';
import { load as loadForgotPassword } from './ForgotPassword/forgot-password-loader';
import { load as loadLogin } from './Login/login-loader';
import { load as loadHeader } from './Navigation/Header/header-loader';
import { load as loadTopBar } from './Navigation/TopBar/top-bar-loader';
import { load as loadReportDetailCTA } from './Report/Details/report-detail-cta-loader';
import { load as loadReportSearch } from './Report/Search/report-search-loader';
import { load as loadVideo } from './Video/video-loader';

export function loadComponents() {
  loadAccessRequestForm();
  loadAccountDashboard();
  loadAccountDetails();
  loadEmailPreferences();
  loadOrderList();
  loadAccountTibits();
  loadCheckoutThankYou();
  loadCheckout();
  loadSetPassword();
  loadForgotPassword();
  loadReportSearch();
  loadLogin();
  loadHeader();
  loadReportDetailCTA();
  loadCompanyProfile();
  loadTopBar();
  loadVideo();
}
