<template>
  <div
    class="col mt-3 mt-md-0 px-3 px-md-4 px-lg-5 py-4 py-md-4 py-lg-5"
    :class="{ 'd-none': isHidden }"
  >
    <h5 class="text-left text-decor mb-3 pb-2">Your Subscriptions</h5>

    <SubscriptionsListItem
      v-for="subscription in subscriptions.displayed"
      :key="subscription.name"
      :subscription="subscription"
      :links="links"
    />

    <div
      v-if="subscriptions.hasCollapsed"
      id="collapseSubscriptions"
      class="collapse"
    >
      <SubscriptionsListItem
        v-for="subscription in subscriptions.collapsed"
        :key="subscription.name"
        :subscription="subscription"
        :links="links"
      />
    </div>
    <button
      v-if="subscriptions.hasCollapsed"
      class="btn btn-orange btn-zelman btn-tiny mt-4"
      type="button"
      data-toggle="collapse"
      data-target="#collapseSubscriptions"
      aria-expanded="false"
      aria-controls="collapseSubscriptions"
    ></button>
  </div>
</template>

<script>
import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { USER_TIERS } from '@/src/rootStore/user/state';

import { action, dashboardComputed } from './store';
import SubscriptionsListItem from './SubscriptionsListItem.vue';

export default {
  name: 'SubscriptionsList',

  components: {
    SubscriptionsListItem,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...dashboardComputed,
    userTier: rootComputed.userTier,

    /** @returns {boolean} */
    isHidden() {
      return (
        this.userTier !== USER_TIERS.SubscriberAndIndividualReports &&
        this.userTier !== USER_TIERS.Subscriber
      );
    },
  },

  watch: {
    isHidden: {
      immediate: true,
      handler(isHidden) {
        if (!isHidden) {
          this.$store.dispatch(action('REQUEST_SUBSCRIPTIONS'));
        }
      },
    },
  },
};
</script>
