<template>
  <div class="container py-2 py-md-3">
    <div class="row">
      <div class="col-6 col-md-8">
        <h3 class="text-left text-decor pb-2 mb-3">Your Orders</h3>
      </div>
    </div>
    <div class="row">
      <div v-if="orders.length" class="col">
        <OrderListItem
          v-for="order in orders"
          :key="order.id"
          :order="order"
          :links="links"
          @selectInvoice="onSelectInvoice($event)"
        />
      </div>
      <div v-else class="col">You have no orders</div>
    </div>

    <!-- used with print.css -->
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="invoiceHtml" id="printRoot" v-html="invoiceHtml"></div>

    <BModal
      id="modal-invoice"
      ref="modal"
      header-bg-variant="light"
      header-text-variant="dark"
      :title="`Invoice ${formatDate(invoiceDate)}`"
      size="lg"
      ok-title="Print"
      cancel-title="Close"
      @hide="onModalEvent($event)"
    >
      <section class="container">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="invoiceHtml"></div>
      </section>
    </BModal>
  </div>
</template>

<script>
import axios from 'axios';
import { BModal } from 'bootstrap-vue';

import { formatDate } from '@/src/shared/formatters';
import { createResult } from '@/src/shared/result';

import OrderListItem from './OrderListItem.vue';

export default {
  name: 'OrderList',

  components: {
    BModal,
    OrderListItem,
  },

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      /** @type {{ id: string }[]} */
      orders: [],
      invoiceHtml: '',
      invoiceDate: undefined,
    };
  },

  async created() {
    const result = await createResult(
      axios.get('/api/account/order').then((resp) => resp.data)
    );

    if (result.isFailure) {
      return;
    }

    this.orders = result.value;
  },

  methods: {
    formatDate,

    /** @returns {HTMLLinkElement | null} */
    getInvoiceStylesheet() {
      return document.querySelector('[rel="stylesheet"][data-role="invoice"]');
    },

    onSelectInvoice({ invoiceHtml, invoiceDate }) {
      this.invoiceHtml = invoiceHtml;
      this.invoiceDate = invoiceDate;

      const stylesheet = this.getInvoiceStylesheet();

      if (!stylesheet) {
        return;
      }

      stylesheet.disabled = false;

      /** @type {any} */
      const modal = this.$refs.modal;

      modal.show('modal-invoice');
    },

    onModalEvent($event) {
      if ($event.trigger === 'ok') {
        $event.preventDefault();

        window.print();

        return;
      }

      const stylesheet = this.getInvoiceStylesheet();

      if (!stylesheet) {
        return;
      }

      stylesheet.disabled = true;

      this.invoiceHtml = '';
      this.invoiceDate = undefined;
    },
  },
};
</script>
