<template>
  <div v-frag>
    <li class="nav-item">
      <a
        class="nav-link btn-link-orange bg-orange text-white"
        :href="links.accountUrl"
      >
        Dashboard
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link btn-link-primary bg-primary text-white"
        :href="links.logoutUrl"
        @click.prevent="onLogout"
      >
        Logout
      </a>
    </li>
  </div>
</template>

<script>
import frag from 'vue-frag';

import { clearGACookies } from '@/src/shared/authentication-utilities';

export default {
  name: 'ProfileHeader',

  directives: {
    frag,
  },

  props: {
    /** @type {import('vue').PropOptions<string>} */
    username: {
      default: '',
      type: String,
    },

    /** @type {import('vue').PropOptions<{ logoutUrl: string, accountUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      uiState: {
        isActive: false,
      },
    };
  },

  mounted() {
    document.addEventListener('click', this.onOutsideClick);
  },

  destroyed() {
    document.removeEventListener('click', this.onOutsideClick);
  },

  methods: {
    onOutsideClick(event) {
      if (!this.$el.contains(event.target) && this.uiState.isActive) {
        this.uiState.isActive = false;
      }
    },

    onLogout() {
      clearGACookies();

      window.location.href = this.links.logoutUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

.nav-item {
  color: $primary-color;
}
</style>
