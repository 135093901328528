import axios from 'axios';

import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';

export const actionTypes = {
  REQUEST_EMAIL_PREFERENCES: 'REQUEST_EMAIL_PREFERENCES',
  SUBSCRIBE_TO_EMAIL_SECTOR: 'SUBSCRIBE_TO_EMAIL_SECTOR',
  UNSUBSCRIBE_FROM_EMAIL_SECTOR: 'UNSUBSCRIBE_FROM_EMAIL_SECTOR',
  SUBSCRIBE_TO_REPORT_SERIES: 'SUBSCRIBE_TO_REPORT_SERIES',
  UNSUBSCRIBE_FROM_REPORT_SERIES: 'UNSUBSCRIBE_FROM_REPORT_SERIES',
};

/**
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `emailPreferences/${actionType}`;
}

export const actions = {
  async [actionTypes.REQUEST_EMAIL_PREFERENCES]({ commit }) {
    commit(mutationTypes.SET_LOADING_STATE, 'INITIAL_LOADING');

    const result = await createResult(
      axios.get(`/api/account/email-preferences`).then((resp) => resp.data)
    );

    commit(mutationTypes.SET_LOADING_STATE, 'LOADED');

    if (result.isFailure) {
      return;
    }

    commit(mutationTypes.SET_EMAIL_PREFERENCES, result.value);
  },

  async [actionTypes.SUBSCRIBE_TO_EMAIL_SECTOR]({ commit }, sectorName) {
    commit(mutationTypes.SET_LOADING_STATE, 'LOADING');

    const result = await createResult(
      axios
        .post(`/api/account/email-preferences/sector`, {
          sectorName,
          subscribe: true,
        })
        .then((resp) => resp.data)
    );

    const stateResult = {
      isSuccess: result.isSuccess,
      sectorName,
      isSubscribed: result.isSuccess ? true : false,
    };

    commit(mutationTypes.SET_LOADING_STATE, 'LOADED');
    commit(mutationTypes.SET_SECTOR_UPDATE_RESULT, stateResult);

    return stateResult;
  },

  async [actionTypes.UNSUBSCRIBE_FROM_EMAIL_SECTOR]({ commit }, sectorName) {
    commit(mutationTypes.SET_LOADING_STATE, 'LOADING');

    const result = await createResult(
      axios
        .post(`/api/account/email-preferences/sector`, {
          sectorName,
          subscribe: false,
        })
        .then((resp) => resp.data)
    );

    const stateResult = {
      isSuccess: result.isSuccess,
      sectorName,
      isSubscribed: result.isSuccess ? false : true,
    };

    commit(mutationTypes.SET_LOADING_STATE, 'LOADED');
    commit(mutationTypes.SET_SECTOR_UPDATE_RESULT, stateResult);

    return stateResult;
  },

  async [actionTypes.SUBSCRIBE_TO_REPORT_SERIES]({ commit }, reportTypeId) {
    commit(mutationTypes.SET_LOADING_STATE, 'LOADING');

    const result = await createResult(
      axios
        .post(`/api/account/email-preferences/${reportTypeId}`)
        .then((resp) => resp.data)
    );

    const stateResult = {
      isSuccess: result.isSuccess,
      reportTypeId,
      isSubscribed: result.isSuccess ? true : false,
    };

    commit(mutationTypes.SET_LOADING_STATE, 'LOADED');
    commit(mutationTypes.SET_INDIVIDUAL_UPDATE_RESULT, stateResult);

    return stateResult;
  },

  async [actionTypes.UNSUBSCRIBE_FROM_REPORT_SERIES]({ commit }, reportTypeId) {
    commit(mutationTypes.SET_LOADING_STATE, 'LOADING');

    const result = await createResult(
      axios
        .delete(`/api/account/email-preferences/${reportTypeId}`)
        .then((resp) => resp.data)
    );

    const stateResult = {
      isSuccess: result.isSuccess,
      reportTypeId,
      isSubscribed: result.isSuccess ? false : true,
    };

    commit(mutationTypes.SET_LOADING_STATE, 'LOADED');
    commit(mutationTypes.SET_INDIVIDUAL_UPDATE_RESULT, stateResult);

    return stateResult;
  },
};
