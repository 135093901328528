<template>
  <a v-if="canPurchaseReports" :href="links.checkoutUrl">
    <span class="position-relative inline-block header-icon">
      <FontAwesomeIcon :icon="icons.faShoppingCart" />
      <span
        id="lblCartCount"
        class="badge position-absolute bg-secondary text-white rounded-pill"
      >
        {{ cartItemCount }}
      </span>
    </span>
  </a>
</template>

<script>
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { store } from '@/src/rootStore';
import { rootComputed, USER_TIERS } from '@/src/rootStore/user';

export default {
  name: 'ShoppingCartIcon',

  components: {
    FontAwesomeIcon,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<{ checkoutUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      count: 0,
    };
  },

  computed: {
    ...rootComputed,

    /** @returns {boolean} */
    canPurchaseReports() {
      return (
        this.isAuthenticated &&
        this.userTier !== USER_TIERS.None &&
        this.userTier !== USER_TIERS.FullAccess
      );
    },

    /** @returns {{ [key: string]: import('@fortawesome/fontawesome-svg-core').IconDefinition }}*/
    icons() {
      return { faShoppingCart };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';
/* Sourced from https://stackoverflow.com/a/51304449/939634 */
.header-icon {
  transform: scale(1.25);
}

#lblCartCount {
  z-index: 2;
  right: 0;
  top: -5px;
  transform: translate(50%, -50%);
  font-size: 9px;
  height: 12px;
  padding: 2px 5px;
  line-height: 9px;
}
</style>
