import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import IndividualReportsList from './IndividualReportsList.vue';
import LatestVideo from './LatestVideo.vue';
import RecentReportsList from './RecentReportsList.vue';
import SubscriptionsList from './SubscriptionsList.vue';

export async function load() {
  if (!isVueAppInDOM('zel-account-dashboard-recent-reports')) {
    return;
  }

  renderApp(RecentReportsList, 'zel-account-dashboard-recent-reports');
  renderApp(SubscriptionsList, 'zel-account-dashboard-subscriptions');
  renderApp(IndividualReportsList, 'zel-account-dashboard-individual-reports');
  renderApp(LatestVideo, 'zel-account-dashboard-latest-video');
}
