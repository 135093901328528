<template>
  <div class="d-flex w-100 pt-3 border-top" :class="containerClass">
    <button
      v-if="stepPrevious.name && stepPrevious.isEnabled"
      class="btn btn-outline-primary btn-zelman mr-3"
      type="button"
      :disabled="isLoading"
      @click="onClick(stepPrevious)"
    >
      <slot name="previous" />
    </button>
    <button
      v-if="stepNext.name && stepNext.isEnabled"
      class="btn btn-primary btn-zelman"
      type="button"
      :disabled="isLoading"
      @click="onClick(stepNext)"
    >
      <slot name="next" />
    </button>
  </div>
</template>

<script>
import { action, checkoutComputed } from '../store';

export default {
  name: 'CartStepButtons',

  props: {
    stepNext: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    stepPrevious: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    isLoading: checkoutComputed.isLoading,

    containerClass() {
      if (!this.stepPrevious.name) {
        return 'justify-content-end';
      }

      if (!this.stepNext.name) {
        return 'justify-content-start';
      }

      return 'justify-content-between';
    },
  },

  methods: {
    onClick(step) {
      this.$store.dispatch(action('CHANGE_STEP'), step);
    },
  },
};
</script>
