import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import Header from './Header.vue';

export function load() {
  const selector = 'zel-header';
  const mobileSelector = 'zel-header-mobile';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(Header, selector);
  renderApp(Header, mobileSelector);
}
