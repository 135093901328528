import './styles.scss';
import axios from 'axios';
import Vue from 'vue';

import { loadComponents } from '../Views/component-loader';

import { configureAxios } from './configuration/axios-configuration';
import { configureVue } from './configuration/vue-configuration';
import { initIconLibrary } from './shared/icon-library';

const appSiteUrl = process.env.VUE_APP_SITE_URL || '';

configureAxios(axios, appSiteUrl);
configureVue(Vue);

loadComponents();
initIconLibrary();
