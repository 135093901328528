import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {import('./state').UserState} state
   */
  isAuthenticated: ({ isAuthenticated }) => isAuthenticated.value,

  /**
   * @param {import('./state').UserState} state
   */
  username: ({ username }) => username,

  /**
   * @param {import('./state').UserState} state
   */
  userTier: ({ userTier }) => userTier,

  /**
   * @param {import('./state').UserState} state
   */
  isLoading: ({ cartItemCount, isAuthenticated }) =>
    areLoading([cartItemCount.requestState, isAuthenticated.requestState]),

  /**
   * @param {import('./state').UserState} state
   */
  cartItemCount: ({ cartItemCount }) => cartItemCount.value,
};

/**
 * @param {import('./state').RequestState[]} requestStates
 */
function areLoading(requestStates) {
  return requestStates.some((s) => s === 'REQUESTING');
}

export const rootComputed = /** @type {RootComputed} */ (
  createNamespacedHelpers('user').mapGetters([
    'isAuthenticated',
    'isLoading',
    'username',
    'userTier',
    'cartItemCount',
  ])
);

/**
 * @typedef RootComputed
 * @property {() => boolean} isAuthenticated
 * @property {() => string} username
 * @property {() => UserTier} userTier
 * @property {() => boolean} isLoading
 * @property {() => number} cartItemCount
 *
 * @typedef {import('./state').UserState} UserState
 * @typedef {import('./state').UserTier} UserTier
 */
