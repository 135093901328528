import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import ReportSearch from './ReportSearch.vue';

export function load() {
  const selector = 'zel-report-search';
  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(ReportSearch, selector);
}
