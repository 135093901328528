import { createNamespacedHelpers } from 'vuex';

import { categoryGroupKeys, dateFilterOptions, orderByOptions } from './state';

export const getters = {
  /**
   * @param {ReportSearchStoreState} state
   * @returns {SearchRequest}
   */
  searchRequest: ({ searchSettings }) => {
    const { pageNumber, orderBy, term } = searchSettings;

    const request = {
      pageNumber,
      orderBy,
      searchTerm: term,
      dateTo: searchSettings.filters.dates.to,
      dateFrom: searchSettings.filters.dates.from,
    };

    for (const groupKey of categoryGroupKeys) {
      request[groupKey] = searchSettings.filters[groupKey];
    }

    return request;
  },

  /**
   * @param {ReportSearchStoreState} state
   */
  isDefaultSearchState: ({ searchSettings }) => {
    const { pageNumber, orderBy, term, filters } = searchSettings;

    const areCategoryFiltersEmpty = categoryGroupKeys.every(
      (key) => filters[key].length === 0
    );

    const areDateFiltersEmpty = filters.dates.option === dateFilterOptions.None;

    return (
      pageNumber === 1 &&
      orderBy === orderByOptions.iirReportPagePublishedDateDesc.value &&
      term === '' &&
      areCategoryFiltersEmpty &&
      areDateFiltersEmpty
    );
  },

  /**
   * @param {ReportSearchStoreState} state
   * @returns {Object<string, ResultCategoryFilter[]>}
   */
  appliedCategories: ({ searchResults }) => {
    /** @type {Object<string, ResultCategoryFilter[]>} */
    const appliedFilters = {};

    for (const groupKey of categoryGroupKeys) {
      appliedFilters[groupKey] = searchResults.filters[groupKey].filter(
        (c) => c.isSelected
      );
    }

    return appliedFilters;
  },

  /**
   * @param {ReportSearchStoreState} state
   */
  categoryFilters: ({ searchResults }) => searchResults.filters,

  /**
   * @param {ReportSearchStoreState} state
   */
  dateFilters: ({ searchSettings }) => searchSettings.filters.dates,

  /**
   * @param {ReportSearchStoreState} state
   */
  orderBy: ({ searchSettings }) => searchSettings.orderBy,

  /**
   * @param {ReportSearchStoreState} state
   */
  maxRelevance: ({ maxRelevance }) => maxRelevance,

  /**
   * @param {ReportSearchStoreState} state
   */
  searchResults: ({ searchResults }) => searchResults,

  /**
   * @param {ReportSearchStoreState} state
   */
  searchTerm: ({ searchSettings }) => searchSettings.term,

  /**
   * @param {ReportSearchStoreState} state
   */
  pageNumber: ({ searchSettings }) => searchSettings.pageNumber,

  /**
   * @param {ReportSearchStoreState} state
   */
  isLoading: ({ ui }) => ui.isLoading,

  /**
   * @param {ReportSearchStoreState} state
   */
  isInitialLoad: ({ ui }) => ui.isInitialLoad,

  /**
   * @param {ReportSearchStoreState} state
   */
  isAccessContextVisible: ({ ui }) => ui.isAccessContextVisible,
};

export const reportSearchComputed = /** @type {ReportSearchComputed} */ (
  createNamespacedHelpers('search').mapGetters([
    'searchRequest',
    'isDefaultSearchState',
    'appliedCategories',
    'categoryFilters',
    'dateFilters',
    'orderBy',
    'maxRelevance',
    'searchResults',
    'searchTerm',
    'pageNumber',
    'isLoading',
    'isInitialLoad',
    'isAccessContextVisible',
  ])
);

/**
 * @typedef ReportSearchComputed
 * @property {() => SearchRequest} searchRequest
 * @property {() => boolean} isDefaultSearchState
 * @property {() => Object<string, ResultCategoryFilter[]>} appliedCategories
 * @property {() => ResultFilters} categoryFilters
 * @property {() => DateFilter} dateFilters
 * @property {() => string} orderBy
 * @property {() => number} maxRelevance
 * @property {() => SearchResults} searchResults
 * @property {() => string} searchTerm
 * @property {() => number} pageNumber
 * @property {() => boolean} isLoading
 * @property {() => boolean} isInitialLoad
 * @property {() => boolean} isAccessContextVisible
 *
 * @typedef SearchRequest
 * @property {number} pageNumber
 * @property {string} orderBy
 * @property {string} searchTerm
 * @property {string | undefined} dateTo
 * @property {string | undefined} dateFrom
 *
 * @typedef {import('./state').ReportSearchStoreState} ReportSearchStoreState
 * @typedef {import('./state').SearchResults} SearchResults
 * @typedef {import('./state').DateFilter} DateFilter
 * @typedef {import('./state').ResultFilters} ResultFilters
 * @typedef {import('./state').ResultCategoryFilter} ResultCategoryFilter
 */
