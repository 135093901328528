import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=aab1c246&scoped=true&"
import script from "./Review.vue?vue&type=script&lang=js&"
export * from "./Review.vue?vue&type=script&lang=js&"
import style0 from "./Review.vue?vue&type=style&index=0&id=aab1c246&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab1c246",
  null
  
)

export default component.exports