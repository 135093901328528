<template>
  <li class="list-group-item p-2 p-md-3">
    <div class="d-flex justify-content-between">
      <div class="custom-control custom-checkbox">
        <input
          :id="preference.reportTypeId"
          type="checkbox"
          :aria-label="preference.name"
          class="custom-control-input"
          :checked="form.isSubscribed"
          :disabled="isDisabled"
          @change="onToggle"
        />
        <label
          :for="preference.reportTypeId"
          :class="{ 'font-italic': isDisabled }"
          class="custom-control-label"
          :title="title"
        >
          {{ form.name }}
        </label>
      </div>
      <Notification
        v-if="submission.isSubmitted"
        :is-successful="submission.isSuccess"
      />
    </div>
  </li>
</template>

<script>
import Notification from './Notification.vue';
import { action, emailPreferencesComputed } from './store';

export default {
  name: 'EmailPreferenceItem',

  components: {
    Notification,
  },

  props: {
    /** @type {import('vue').PropOptions<import('./store/state').EmailPreference>}*/
    preference: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        isSubscribed: false,
      },

      submission: {
        isSubmitted: false,
        isSuccess: false,
      },
    };
  },

  computed: {
    ...emailPreferencesComputed,

    /** @returns {boolean} */
    isDisabled() {
      return this.isLoading || !this.preference.hasAccess;
    },

    /** @returns {string} */
    notificationMessage() {
      return this.submission.isSuccess ? 'Update completed' : 'Update failed';
    },

    /** @returns {string} */
    title() {
      if (!this.preference.hasAccess) {
        return 'You do not have access to this report series';
      }

      return this.preference.isSubscribed
        ? 'Unsubscribe from this report series'
        : 'Subscribe to this report series';
    },
  },

  watch: {
    preference: {
      immediate: true,
      handler: function (newValue, _oldValue) {
        if (newValue) {
          this.form = newValue;
        }
      },
    },
  },

  methods: {
    async onToggle() {
      this.submission.isSubmitted = false;
      this.submission.isSuccess = false;

      const operation = this.form.isSubscribed
        ? action('UNSUBSCRIBE_FROM_REPORT_SERIES')
        : action('SUBSCRIBE_TO_REPORT_SERIES');

      const result = await this.$store.dispatch(
        operation,
        this.preference.reportTypeId
      );

      this.submission.isSuccess = result.isSuccess;
      this.submission.isSubmitted = true;
    },
  },
};
</script>
