<template>
  <div
    v-if="video"
    class="col mt-3 mt-md-0 px-3 px-md-4 px-lg-5 py-4 py-md-4 py-lg-5 shadow-sm"
  >
    <a name="video" class="fragment-anchor"></a>

    <h5 class="text-left text-decor mb-3 pb-2">Latest Video</h5>

    <div class="card border-0">
      <!-- eslint-disable vue/no-v-html -->
      <div
        :class="{
          'embed-responsive embed-responsive-16by9': !isVimeoResponsiveVideo,
        }"
        v-html="video.embedCodeHTML"
      ></div>
      <div class="card-body pl-0 pr-0 pb-0">
        <p class="small mb-1">{{ video.publishedDate }}</p>
        <h5 class="card-title">{{ video.title }}</h5>
        <h6 v-if="video.subtitle" class="card-subtitle mb-2 text-muted">
          {{ video.subtitle }}
        </h6>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="card-text small" v-html="video.descriptionHTML"></div>
      </div>
    </div>

    <a
      :href="links.videoLibraryUrl"
      class="btn btn-orange btn-zelman btn-tiny mt-4"
    >
      VIEW ALL
    </a>
  </div>
</template>

<script>
import axios from 'axios';

import { createResult } from '@/src/shared/result';

export default {
  name: 'LatestVideo',

  props: {
    /** @type {import('vue').PropOptions<{ videoLibraryUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      /** @type {VideoResponse | undefined} */
      video: undefined,
    };
  },

  computed: {
    /**
     * Vimeo videos have responsive styles, where other videos (ex YouTube) do not
     * @returns {boolean}
     */
    isVimeoResponsiveVideo() {
      if (!this.video) {
        return false;
      }

      return (this.video.embedCodeHTML.indexOf('56.25') || -1) > -1;
    },
  },

  async created() {
    const result = await createResult(
      axios.get('/api/account/dashboard/video/latest').then((resp) => {
        /** @type {VideoResponse[]} */
        const data = resp.data;

        return data;
      })
    );

    if (result.isFailure) {
      return;
    }

    this.video = result.value.length ? result.value[0] : undefined;
  },
};

/**
 * @typedef VideoResponse
 * @property {string} title
 * @property {string} subtitle
 * @property {string} publishedDate
 * @property {string} descriptionHTML
 * @property {string} embedCodeHTML
 */
</script>
