/**
 * Creates a deep link url for the report search page with the provided
 * category ids as query params
 * @param {String} reportSearchUrl
 * @param {Number[]} categoryIds
 */
export function reportSearchUrlDeepLink(reportSearchUrl, categoryIds) {
  return `${reportSearchUrl}?c=${categoryIds.join(',')}`;
}

/**
 * Encodes the current browser URL and appends it as a ReturnUrl query string
 * value to the given loginUrl parameter
 * @param {string} loginUrl
 * @returns {string}
 */
export function getLoginRedirectURL(loginUrl) {
  const path = window.location.pathname;

  return `${loginUrl}?ReturnUrl=${encodeURIComponent(path)}`;
}
