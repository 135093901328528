<template>
  <div
    class="card border-0"
    :style="{ overflowHidden: 'overflow:hidden;' }"
    style="border-radius: 0"
  >
    <div
      :id="`${identifier}Heading`"
      class="card-header p-0"
      @click="show = !show"
    >
      <h5 class="mb-0">
        <button
          :class="{ collapsed: !show }"
          class="btn btn-orange d-block w-100 text-left position-relative px-3 py-2 rounded-0"
          aria-expanded="true"
        >
          {{ title }}
          <div class="accord-icon">
            <span class="a-i1" />
            <span class="a-i2" />
          </div>
        </button>
      </h5>
    </div>
    <transition name="accordion-fade-slide" mode="out-in">
      <div
        v-show="show"
        :id="`${identifier}Filter`"
        :aria-labelledby="`${identifier}Heading`"
      >
        <slot :id="identifier" name="filter" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ReportSearchFilterBox',

  props: {
    /** @type {import('vue').PropOptions<boolean>} */
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },

    /** @type {import('vue').PropOptions<string>} */
    identifier: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    title: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<boolean>} */
    overflowHidden: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      show: false,
    };
  },

  watch: {
    defaultOpen: {
      immediate: true,
      /** @param {boolean} defaultOpen */
      handler(defaultOpen) {
        this.show = defaultOpen;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

.accord-icon {
  border-radius: 50%;
  border: 1px solid $white;
  width: 20px;
  height: 0;
  padding: 0 0 20px;
  box-sizing: content-box;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  .a-i1 {
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
  }
  .a-i2 {
    transform: none;
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
    transition: all 0.25s;
  }
}

.collapsed .accord-icon .a-i2 {
  transform: rotate(90deg);
}

.accordion-fade-slide {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s;
  }
  &-enter {
    height: 0;
    opacity: 0;
  }
  &-enter-to {
    max-height: 390px;
    overflow: hidden;
    opacity: 1;
  }
  &-leave {
    max-height: 390px;
    overflow: hidden;
    opacity: 1;
  }
  &-leave-to {
    height: 0px;
    opacity: 0;
  }
}
</style>
