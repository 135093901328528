<template>
  <div
    class="list-group-item list-group-item-action flex-wrap flex-md-nowrap d-flex align-items-start p-3"
  >
    <div class="col m-0 p-0">
      <div class="row">
        <div class="col-8">
          <h5 class="mb-2 small">
            <a
              class="font-weight-bold text-uppercase"
              :href="reportCategoryLink"
            >
              {{ report.title }}
            </a>
          </h5>
        </div>
        <div class="col-4 text-right bg-transparent">
          <span class="small text-muted d-md-block m-0 p-0">
            {{ reportDate }}
          </span>
        </div>
      </div>
      <h6 class="mb-2">
        <a :href="report.pageLinkUrl">
          {{ report.subtitle }}
        </a>
      </h6>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/src/shared/formatters';
import { reportSearchUrlDeepLink } from '@/src/shared/links';

export default {
  name: 'SubscriptionsListItem',

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<SubscriptionResponse>} */
    subscription: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isDownloadFailed: false,
    };
  },

  computed: {
    /** @returns {ReportResponse} */
    report() {
      return this.subscription.report;
    },

    /** @returns {string} */
    reportDate() {
      return formatDate(this.report.publishedDate);
    },

    /** @returns {string} */
    reportCategoryLink() {
      return reportSearchUrlDeepLink(this.links.reportSearchUrl, [
        this.report.categoryId,
      ]);
    },
  },
};

/**
 * @typedef {import('./store/state').SubscriptionResponse} SubscriptionResponse
 * @typedef {import('./store/state').ReportResponse} ReportResponse
 */
</script>
