import vue from 'vue';
import Vuex from 'vuex';

import { store as dashboard } from '@/Views/Account/Dashboard/store';
import { store as emailPreferences } from '@/Views/Account/EmailPreferences/store';
import { store as checkout } from '@/Views/Checkout/store';
import { store as reportDetail } from '@/Views/Report/Details/store';
import { store as search } from '@/Views/Report/Search/store';
import { store as videoDetail } from '@/Views/Video/store';

import { store as user } from './user';

vue.use(Vuex);

export const store = new Vuex.Store({
  strict: true,
  modules: {
    checkout,
    dashboard,
    emailPreferences,
    user,
    reportDetail,
    search,
    videoDetail,
  },
});
