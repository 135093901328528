import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {EmailPreferencesState} state
   */
  sectors: ({ sectors }) => sectors,

  /**
   * @param {EmailPreferencesState} state
   */
  isLoading: ({ loadingState }) => loadingState === 'LOADING',

  /**
   * @param {EmailPreferencesState} state
   */
  isInitialLoading: ({ loadingState }) => loadingState === 'INITIAL_LOADING',
};

export const emailPreferencesComputed =
  /** @type {EmailPreferencesComputed} */ (
    createNamespacedHelpers('emailPreferences').mapGetters([
      'sectors',
      'isLoading',
      'isInitialLoading',
    ])
  );
/**
 * @typedef {import('./state').EmailPreferencesState} EmailPreferencesState
 * @typedef {import('./state').EmailPreferenceSector} EmailPreferenceSector
 *
 * @typedef EmailPreferencesComputed
 * @property {() => EmailPreferenceSector[]} sectors
 * @property {() => boolean} isLoading
 * @property {() => boolean} isInitialLoading
 */
