<template>
  <div>
    <p v-if="isAuthenticated">
      You are currently logged in. Please use the Change Password form on
      <a :href="links.accountUrl">your account page</a>.
    </p>
    <ValidationObserver
      v-if="!isAuthenticated"
      ref="observer"
      v-slot="{ invalid, pristine }"
      tag="div"
    >
      <form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="form-group col-12">
            <ValidationProvider
              v-slot="props"
              rules="required"
              :name="fieldNames.forgotPasswordEmail"
            >
              <label for="forgotPasswordEmail">Email *</label>
              <ValidatingControl :state="props">
                <input
                  id="forgotPasswordEmail"
                  v-model="form.email"
                  name="forgotPasswordEmail"
                  type="email"
                  class="form-control"
                />
              </ValidatingControl>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group">
          <button
            :disabled="pristine || invalid || uiState.isLoading"
            type="submit"
            class="btn btn-primary btn-zelman btn-block"
          >
            Send Email Recovery Link
          </button>
        </div>
      </form>
      <Spinner v-if="uiState.isLoading" class="align-self-center" />
      <div v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful">
        <p class="py-2 px-3 rounded text-white bg-danger">
          <FontAwesomeIcon :icon="icons.faExclamationCircle" />
          There was an issue when trying to process your request, please
          <a :href="links.contactUsUrl" class="alert-link text-white">
            contact support </a
          >.
        </p>
      </div>

      <div v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful">
        <p class="py-2 px-3 rounded bg-success text-white">
          <FontAwesomeIcon :icon="icons.faCheckCircle" />
          If your email is in our system, you will receive an email shortly with
          directions to reset your password.
        </p>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import Spinner from '@/src/shared/components/Spinner.vue';
import ValidatingControl from '@/src/shared/components/ValidatingControl.vue';
import { fieldNames } from '@/src/shared/form-validation';
import { createResult } from '@/src/shared/result';

export default {
  name: 'ForgotPassword',

  components: {
    FontAwesomeIcon,
    ValidationProvider,
    ValidationObserver,
    Spinner,
    ValidatingControl,
  },

  props: {
    /** @type {import('vue').PropOptions<{ accountUrl: string, contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  data() {
    return {
      fieldNames,
      uiState: {
        isLoading: false,
        isSubmissionSuccessful: false,
        hasSubmitted: false,
        isAuthenticated: false,
      },
      form: {
        email: '',
      },
    };
  },

  computed: {
    isAuthenticated: rootComputed.isAuthenticated,

    icons() {
      return { faCheckCircle, faExclamationCircle };
    },

    /** @returns {InstanceType<typeof ValidationObserver>} */
    observer() {
      /** @type {any} */
      const observer = this.$refs.observer;

      return observer;
    },
  },

  methods: {
    async onSubmit() {
      const isValid = await this.observer.validate();

      if (!isValid) {
        return;
      }

      this.uiState.isLoading = true;
      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;

      const result = await createResult(
        axios.post('/api/forgot-password', this.form)
      );

      this.uiState.isLoading = false;
      this.uiState.hasSubmitted = true;

      if (result.isFailure) {
        this.uiState.isSubmissionSuccessful = false;

        return;
      }

      this.form.email = '';
      this.uiState.isSubmissionSuccessful = true;

      requestAnimationFrame(() => {
        this.observer.reset();
      });
    },
  },
};
</script>
