<template>
  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">
        {{ subscription.productName }}
      </h5>

      <div class="d-flex justify-content-between">
        <div>
          <BFormCheckbox
            :id="identifier"
            :checked="subscription.isAutoRenewEnabled"
            :name="identifier"
            :disabled="isDisabled"
            switch
            @change="onAutoRenewChange"
          >
            Auto-Renewal
          </BFormCheckbox>
        </div>
        <Spinner v-if="isLoading" />
      </div>
      <FormError v-if="!isUpdateSuccessful">
        There was an error updating this subscription.
      </FormError>
    </div>
    <div class="card-footer text-muted">
      <div class="row">
        <div class="col text-left">
          <small v-if="isExpired">Expired on {{ endDate }}</small>
          <small v-else>Expires {{ endDate }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { BFormCheckbox } from 'bootstrap-vue';

import FormError from '@/src/shared/components/FormError.vue';
import Spinner from '@/src/shared/components/Spinner.vue';
import { formatDate } from '@/src/shared/formatters';
import { createResult } from '@/src/shared/result';

/**
 * @typedef CreditCard
 * @property {string} expirationMonth
 * @property {string} expirationYear
 */

/**
 * @typedef Subscription
 * @property {string} endDate
 * @property {string} id
 * @property {CreditCard} card
 * @property {boolean} isAutoRenewEnabled
 * @property {string} productName
 */

export default {
  name: 'SubscriptionListItem',

  components: {
    Spinner,
    FormError,
    BFormCheckbox,
  },

  props: {
    /** @type {import('vue').PropOptions<Subscription>} */
    subscription: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isUpdateSuccessful: true,
    };
  },

  computed: {
    /** @returns {boolean} */
    isExpired() {
      if (!this.subscription.endDate) {
        return false;
      }

      return new Date(this.subscription.endDate) <= new Date();
    },

    /** @returns {boolean} */
    isDisabled() {
      return this.isLoading || this.isExpired;
    },

    /** @returns {string} */
    endDate() {
      return this.subscription.endDate
        ? formatDate(this.subscription.endDate)
        : '';
    },

    /** @returns {string} */
    cardExpiration() {
      return `${this.card.expirationMonth}/${this.card.expirationYear}`;
    },

    /** @returns {CreditCard} */
    card() {
      return this.subscription.card;
    },

    /** @returns {string} */
    identifier() {
      return `sub-${this.subscription.id}`;
    },
  },

  methods: {
    formatDate,

    async onAutoRenewChange(isAutoRenewEnabled) {
      this.isLoading = true;

      const result = await createResult(
        axios
          .put('/api/account/detail/subscription', {
            subscriptionId: this.subscription.id,
            isAutoRenewEnabled,
          })
          .then((resp) => resp.data)
      );

      this.isLoading = false;
      this.isUpdateSuccessful = result.isSuccess;

      if (result.isFailure) {
        this.$emit('update', this.subscription);

        return;
      }

      this.$emit('update', result.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-icon {
  font-size: 1.5rem;
}
</style>
