import { action, actions } from './actions';
import { getters, reportSearchComputed } from './getters';
import { mutation, mutations } from './mutations';
import { state } from './state';

/**
 * @type {import('vuex').Module<SearchState>}
 */
export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { action, mutation, reportSearchComputed };

/**
 * @typedef {import('./state').ReportSearchStoreState} SearchState
 */
