/**
 * @type {ReportDetailState}
 */
export const state = {
  nodeGuid: '',
  isAccessValidated: false,
  access: [],
  ui: {
    isLoadingAccess: true,
  },
};

/**
 * @typedef {Object} UIState
 * @property {Boolean} isLoadingAccess
 */

/**
 * @typedef {Object} ReportDetailState
 * @property {String} nodeGuid
 * @property {Boolean} isAccessValidated
 * @property {ReportAccess[]} access
 * @property {UIState} ui
 */

/**
 * @typedef ReportDetailCTAConfig
 * @property {string[]} subscriptions
 * @property {string} nodeGuid
 * @property {number} skuId
 * @property {number} skuPrice
 * @property {boolean} isForSale
 * @property {boolean} isSample
 */

/**
 * @typedef ReportSubscriptionConfig
 * @property {string} nodeGuid
 * @property {number} categoryId
 * @property {Object} links
 * @property {number} skuId
 * @property {number} skuPrice
 */

/**
 * @typedef {Object} ReportAccess
 * @property {String} nodeGuid
 * @property {Boolean} hasAccess
 * @property {Boolean} hasError
 * @property {Number} skuId
 * @property {Number} skuPrice
 */
