import { actions, action } from './actions';
import { getters, dashboardComputed } from './getters';
import { mutations, mutation } from './mutations';
import { state } from './state';

/**
 * @type {import('vuex').Module<DashbaordState>}
 */
export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { dashboardComputed, action, mutation };

/**
 * @typedef {import('./state').DashboardState} DashbaordState
 */
