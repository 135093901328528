<template>
  <div>
    <ValidationObserver
      v-if="!isAuthenticated"
      ref="observer"
      v-slot="{ invalid, pristine }"
      tag="div"
    >
      <form class="px-3 py-2" @submit.prevent="onSubmit">
        <div class="form-group">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.username"
          >
            <label for="username">Username *</label>
            <ValidatingControl :state="props">
              <input
                id="username"
                v-model="form.username"
                name="username"
                type="text"
                class="form-control"
                placeholder="email@example.com"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.password"
          >
            <label for="password">Password *</label>
            <ValidatingControl :state="props">
              <input
                id="password"
                v-model="form.password"
                type="password"
                name="password"
                class="form-control"
                placeholder="Password"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              id="rememberLogin"
              v-model="form.isPersistent"
              type="checkbox"
              class="form-check-input"
            />
            <label class="form-check-label" for="rememberLogin">
              Remember me
            </label>
          </div>
        </div>
        <div class="mb-3">
          <button
            type="submit"
            :disabled="pristine || invalid || uiState.isLoading"
            class="btn btn-orange mr-5 btn-zelman"
          >
            Sign in
          </button>
          <Spinner v-if="uiState.isLoading" />
        </div>

        <div v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful">
          <p class="py-2 px-3 rounded text-white bg-danger">
            <FontAwesomeIcon :icon="icons.faExclamationCircle" />
            Your username and/or password was not correct. Please try again or
            click the forgot password link below.
          </p>
        </div>

        <div class="d-flex justify-content-between">
          <a :href="links.accessRequestUrl">
            Don't have an account? Request access.
          </a>
          <a :href="links.forgotPasswordUrl">Forgot password?</a>
        </div>
      </form>
    </ValidationObserver>
    <div
      v-else
      class="w-100 text-center bg-warning mt-2 rounded py-2 px-3 d-inline-block font-weight-bolder"
    >
      You are already logged in. You can
      <a class="text-secondary" :href="links.reportSearchUrl">browse reports</a>
      or go to
      <a class="text-secondary" :href="links.accountUrl">your account page</a>.
    </div>
  </div>
</template>

<script>
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { setGACookies } from '@/src/shared/authentication-utilities';
import Spinner from '@/src/shared/components/Spinner.vue';
import ValidatingControl from '@/src/shared/components/ValidatingControl.vue';
import { fieldNames } from '@/src/shared/form-validation';
import { createResult } from '@/src/shared/result';

export default {
  name: 'Login',

  components: {
    FontAwesomeIcon,
    ValidatingControl,
    ValidationObserver,
    ValidationProvider,
    Spinner,
  },

  props: {
    /** @type {import('vue').PropOptions<{ accountUrl: string, forgotPasswordUrl: string, accessRequestUrl: string, reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  data() {
    return {
      fieldNames,
      form: {
        username: '',
        password: '',
        isPersistent: false,
      },
      uiState: {
        isLoading: false,
        hasSubmitted: false,
        isSubmissionSuccessful: false,
      },
    };
  },

  computed: {
    isAuthenticated: rootComputed.isAuthenticated,

    icons() {
      return { faExclamationCircle };
    },
  },

  methods: {
    async onSubmit() {
      this.uiState.isLoading = true;
      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;

      const result = await createResult(
        axios.post('/api/login', this.form).then((resp) => resp.data)
      );

      this.uiState.hasSubmitted = true;

      // Http request failure or authentication failure
      if (result.isFailure || !result.value.isSuccess) {
        this.uiState.isSubmissionSuccessful = false;
        this.uiState.isLoading = false;

        return;
      }

      setGACookies(result.value);

      this.uiState.isSubmissionSuccessful = true;
      window.location.href = result.value.returnUrl;
    },
  },
};
</script>
