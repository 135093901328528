import axios from 'axios';

import {
  clearGACookies,
  setGACookies,
} from '@/src/shared/authentication-utilities';
import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';
import { REQUEST_STATES, USER_TIERS } from './state';

export const actionTypes = {
  REQUEST_AUTHENTICATION_STATE: 'REQUEST_AUTHENTICATION_STATE',
  REQUEST_CART_ITEM_COUNT: 'REQUEST_CART_ITEM_COUNT',
};

/**
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `user/${actionType}`;
}

export const actions = {
  async [actionTypes.REQUEST_AUTHENTICATION_STATE]({ commit }) {
    commit(mutationTypes.SET_REQUEST_STATE, {
      key: 'isAuthenticated',
      requestState: REQUEST_STATES.REQUESTING,
    });

    const result = await createResult(
      axios.get('/api/account/user').then((resp) => resp.data)
    );

    commit(mutationTypes.SET_REQUEST_STATE, {
      key: 'isAuthenticated',
      requestState: REQUEST_STATES.REQUESTED,
    });

    if (result.isFailure || !result.value.isAuthenticated) {
      commit(mutationTypes.SET_IS_AUTHENTICATED, {
        isAuthenticated: false,
        username: '',
        ceContactGuid: '',
        userTier: USER_TIERS.None,
      });

      clearGACookies();

      return result;
    }

    commit(mutationTypes.SET_IS_AUTHENTICATED, result.value);

    setGACookies(result.value);

    return result;
  },

  async [actionTypes.REQUEST_CART_ITEM_COUNT]({ commit }) {
    commit(mutationTypes.SET_REQUEST_STATE, {
      key: 'cartItemCount',
      requestState: REQUEST_STATES.REQUESTING,
    });

    const result = await createResult(
      axios.get('/api/account/cart/item-count').then((resp) => resp.data)
    );

    commit(mutationTypes.SET_REQUEST_STATE, {
      key: 'cartItemCount',
      requestState: REQUEST_STATES.REQUESTED,
    });

    if (result.isFailure) {
      commit(mutationTypes.SET_CART_ITEM_COUNT, 0);

      return result;
    }

    commit(mutationTypes.SET_CART_ITEM_COUNT, result.value.count);

    return result;
  },
};
