<template>
  <div>
    <Spinner v-if="isLoadingAccess" />

    <div v-else-if="isAccessValidated">
      <ReportUnauthenticatedCTA v-if="!isAuthenticated" :links="links" />

      <DownloadButton v-else-if="isDownloadEnabled" />

      <ReportRequestPricingCTA v-else-if="!config.isForSale" />

      <ReportAddToCart
        v-else-if="config.isForSale"
        :product="product"
        :links="links"
      />
    </div>
  </div>
</template>

<script>
import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import Spinner from '@/src/shared/components/Spinner.vue';

import DownloadButton from './DownloadButton.vue';
import ReportAddToCart from './ReportAddToCart.vue';
import ReportRequestPricingCTA from './ReportRequestPricingCTA.vue';
import ReportUnauthenticatedCTA from './ReportUnauthenticatedCTA.vue';
import { action, reportDetailComputed } from './store';
import { findProduct, hasAccessToProduct } from './store/getters';

/**
 * @typedef {import('./store/state').ReportDetailCTAConfig} ReportDetailCTAConfig
 */

export default {
  name: 'ReportDetailCTA',

  components: {
    DownloadButton,
    ReportAddToCart,
    ReportRequestPricingCTA,
    ReportUnauthenticatedCTA,
    Spinner,
  },

  props: {
    /** @type {import('vue').PropOptions<ReportDetailCTAConfig>} */
    config: {
      type: Object,
      required: true,
    },
    /** @type {import('vue').PropOptions<any>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  computed: {
    ...reportDetailComputed,

    isAuthenticated: rootComputed.isAuthenticated,

    /** @returns {boolean} */
    isDownloadEnabled() {
      return hasAccessToProduct(this.access, this.config.nodeGuid);
    },

    /**
     * @returns {import('./store/state').ReportAccess}
     */
    product() {
      const { nodeGuid, skuId, skuPrice } = this.config;

      return findProduct(this.access, { nodeGuid, skuId, skuPrice });
    },
  },

  async created() {
    await this.$store.dispatch(action('VALIDATE_ACCESS'), {
      nodeGuid: this.config.nodeGuid,
      subscriptions: this.config.subscriptions,
    });
  },
};
</script>
