import { actions, action } from './actions';
import { getters, videoDetailComputed } from './getters';
import { mutations, mutation } from './mutations';
import { state } from './state';

/**
 * @type {import('vuex').Module<VideoDetailState>}
 */
export const store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export { action, mutation, videoDetailComputed };

/**
 * @typedef {import('./state').VideoDetailState} VideoDetailState
 */
