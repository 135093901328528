var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ReportSearchFilterBox',{attrs:{"identifier":"sectors","title":"Sector","default-open":true},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.sectors},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"reportTypes","title":"Report Type"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.reportTypes},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"thematics","title":"Thematic"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.thematics},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"proprietarySurveys","title":"Proprietary Surveys"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.proprietarySurveys},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"recurringPublications","title":"Recurring Publications"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.recurringPublications},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"referenceGuides","title":"Reference Guides"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.referenceGuides},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"companySpecifics","title":"Company Specific"},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchCategoryFilter',{attrs:{"group":id,"categories":_vm.categories.companySpecifics},on:{"change":_vm.onChangeCategory}})]}}])}),_c('ReportSearchFilterBox',{attrs:{"identifier":"dates","title":"Date Range","overflow-hidden":false},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var id = ref.id;
return [_c('ReportSearchDateFilter',{attrs:{"identifier":id,"options":_vm.dates},on:{"change":_vm.onChangeDate}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }