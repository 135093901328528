import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import PasswordForm from './PasswordForm.vue';

export function load() {
  const selector = 'zel-password-form';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(PasswordForm, selector);
}
