export const mutationTypes = {
  UPDATE_IS_ACCESS_VALIDATED: 'UPDATE_IS_ACCESS_VALIDATED',
  UPDATE_IS_LOADING_ACCESS: 'UPDATE_IS_LOADING_ACCESS',
  UPDATE_REPORT_NODE_GUID: 'UPDATE_REPORT_NODE_GUID',
  UPDATE_SUBSCRIPTION_ACCESS: 'UPDATE_SUBSCRIPTION_ACCESS',
};

/**
 *
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `reportDetail/${mutationType}`;
}

export const mutations = {
  /**
   *
   * @param {import('./state').ReportDetailState} state
   * @param {Boolean} isLoadingAccess
   */
  [mutationTypes.UPDATE_IS_LOADING_ACCESS](state, isLoadingAccess) {
    state.ui.isLoadingAccess = isLoadingAccess;
  },

  /**
   *
   * @param {import('./state').ReportDetailState} state
   * @param {Boolean} isAccessValidated
   */
  [mutationTypes.UPDATE_IS_ACCESS_VALIDATED](state, isAccessValidated) {
    state.isAccessValidated = isAccessValidated;
  },

  /**
   *
   * @param {import('./state').ReportDetailState} state
   * @param {String} nodeGuid
   */
  [mutationTypes.UPDATE_REPORT_NODE_GUID](state, nodeGuid) {
    state.nodeGuid = nodeGuid;
  },

  /**
   *
   * @param {import('./state').ReportDetailState} state
   * @param {import('./state').ReportAccess[]} access
   */
  [mutationTypes.UPDATE_SUBSCRIPTION_ACCESS](state, access) {
    state.access = access;
  },
};
