<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="pb-3">
        <button
          v-if="searchTerm"
          type="button"
          class="badge badge-pill badge-primary bg-orange border-0 mr-2"
          aria-label="Close"
          @click="onSearchTermClick"
        >
          Search: {{ searchTerm }}
          <span aria-hidden="true">
            <FontAwesomeIcon :icon="icons.faTimes" />
          </span>
        </button>

        <span v-for="key in categoryGroupKeys" :key="key">
          <button
            v-for="category in appliedCategories[key]"
            :key="category.id"
            type="button"
            class="badge badge-pill badge-primary bg-orange border-0 mr-2"
            aria-label="Close"
            @click="onCategoryClick(category.id, key)"
          >
            {{ categoryGroupNames[key] }}: {{ category.value }}
            <span aria-hidden="true">
              <FontAwesomeIcon :icon="icons.faTimes" />
            </span>
          </button>
        </span>

        <button
          v-if="showDateFilterOption"
          type="button"
          class="badge badge-pill badge-primary bg-orange border-0 mr-2"
          aria-label="Close"
          @click="onDateFilterClick"
        >
          Published Date: {{ dateFilters.option }}
          <span aria-hidden="true">
            <FontAwesomeIcon :icon="icons.faTimes" />
          </span>
        </button>
      </div>
      <div class="border-bottom" />
    </div>
  </div>
</template>

<script>
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { action, reportSearchComputed, mutation } from './store';
import {
  categoryGroupKeys,
  categoryGroupNames,
  dateFilterOptions,
} from './store/state';

export default {
  name: 'ReportSearchAppliedFilters',

  components: {
    FontAwesomeIcon,
  },

  data() {
    return {
      categoryGroupKeys,
      categoryGroupNames,
    };
  },

  computed: {
    ...reportSearchComputed,

    /**
     * @returns {boolean}
     */
    showDateFilterOption() {
      return this.dateFilters.option !== dateFilterOptions.None;
    },

    /**
     * @returns {{ faTimes: import('@fortawesome/fontawesome-svg-core').IconDefinition }}
     */
    icons() {
      return { faTimes };
    },
  },

  methods: {
    async onSearchTermClick() {
      this.$store.commit(mutation('UPDATE_SEARCH_TERM'), '');

      await this.$store.dispatch(action('SEARCH'));
    },

    async onDateFilterClick() {
      this.$store.commit(mutation('UPDATE_DATE_FILTER'), {
        to: undefined,
        from: undefined,
        option: dateFilterOptions.None,
      });
      await this.$store.dispatch(action('SEARCH'));
    },

    async onCategoryClick(categoryId, categoryGroup) {
      this.$store.commit(mutation('UPDATE_CATEGORY_GROUP_FILTER'), {
        categoryGroup,
        categoryId,
      });
      await this.$store.dispatch(action('SEARCH'));
    },
  },
};
</script>
