<template>
  <div class="loader">
    <ContentLoader
      :primary-color="primaryColor"
      :width="width"
      :height="height"
    >
      <rect
        v-for="rect in rects"
        :key="rect.y"
        :x="rect.x"
        :y="rect.y"
        :rx="rect.rx"
        :ry="rect.ry"
        :width="rect.width"
        :height="rect.height"
      />
    </ContentLoader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  components: {
    ContentLoader,
  },
  props: {
    sections: {
      type: Number,
      required: false,
      default: 1,
    },
    rowsPerSection: {
      type: Number,
      required: false,
      default: 3,
    },
  },
  data() {
    return {
      width: 600,
      height: this.sections * 130,
      primaryColor: '#b7b7b9',
    };
  },
  computed: {
    /** @returns {Array} */
    rects() {
      let offset = 0;

      const rects = Array.from(
        { length: this.rowsPerSection * this.sections },
        (_, i) => i
      ).map((index) => {
        if (index % this.rowsPerSection === 0 && !!index) {
          offset++;
        }

        return {
          x: 0,
          y: index * 20 + offset * 20,
          rx: 3,
          ry: 3,
          width:
            (this.width * Math.floor(Math.random() * (1000 - 500) + 500)) /
            1000,
          height: 10,
        };
      });

      return rects;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  margin-left: 1rem;
}
</style>
