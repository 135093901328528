<template>
  <div>
    <ValidationObserver
      v-if="!uiState.isSubmissionSuccessful && !isAuthenticated"
      ref="observer"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.firstName"
          >
            <label for="firstName">First Name *</label>
            <ValidatingControl :state="props">
              <input
                id="firstName"
                v-model="form.firstName"
                name="firstName"
                type="text"
                class="form-control"
                placeholder="First Name"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.lastName"
          >
            <label for="lastName">Last Name *</label>
            <ValidatingControl :state="props">
              <input
                id="lastName"
                v-model="form.lastName"
                name="lastName"
                type="text"
                class="form-control"
                placeholder="Last Name"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.emailAddress"
          >
            <label for="emailAddress">Email Address *</label>
            <ValidatingControl :state="props">
              <input
                id="emailAddress"
                v-model="form.email"
                name="emailAddress"
                type="email"
                class="form-control"
                aria-describedby="emailHelp"
                placeholder="Email Address"
                @change="onEmailChange"
              />
            </ValidatingControl>
            <small
              v-if="uiState.showInvalidEmailDomainsMessage"
              class="bg-warning mt-2 rounded py-2 px-3 d-inline-block font-weight-bolder"
              >{{ invalidEmailDomainsMessage }}</small
            >
          </ValidationProvider>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.companyName"
          >
            <label for="companyName">Company Name *</label>
            <ValidatingControl :state="props">
              <input
                id="companyName"
                v-model="form.companyName"
                name="companyName"
                type="text"
                class="form-control"
                placeholder="Company Name"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.companyPhone"
          >
            <label for="companyPhone">Company Phone *</label>
            <ValidatingControl :state="props">
              <input
                id="companyPhone"
                v-model="form.companyPhone"
                name="companyPhone"
                type="text"
                class="form-control"
                placeholder="Company Phone"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.personalDescription"
          >
            <label for="personalDescription">
              How would you describe yourself? *
            </label>
            <ValidatingControl :state="props" :show-icon="false">
              <select
                id="personalDescription"
                v-model="form.personalDescription"
                class="form-control"
                name="personalDescription"
              >
                <option disabled value="">Please select an option</option>
                <option
                  v-for="option in personalDescriptions"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </ValidatingControl>
          </ValidationProvider>
        </div>
        <div
          v-if="form.personalDescription === 'Other'"
          class="form-group col-md-6 col-sm-12"
        >
          <ValidationProvider
            v-slot="props"
            rules="required|max:200"
            :name="fieldNames.personalDescriptionOther"
          >
            <label for="personalDescriptionOther">
              Other (please fill in) *
            </label>
            <ValidatingControl :state="props">
              <input
                id="personalDescriptionOther"
                v-model="form.personalDescriptionOther"
                name="personalDescriptionOther"
                type="text"
                class="form-control"
                placeholder="Describe yourself"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <ValidationProvider
            v-slot="props"
            rules="required"
            :name="fieldNames.referenceSource"
          >
            <label for="referenceSource">
              How did you hear about Zelman? *
            </label>
            <ValidatingControl :state="props" :show-icon="false">
              <select
                id="referenceSource"
                v-model="form.referenceSource"
                class="form-control"
                name="referenceSource"
              >
                <option disabled value="">Please select an option</option>
                <option
                  v-for="option in referenceSources"
                  :key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </ValidatingControl>
          </ValidationProvider>
        </div>
        <div
          v-if="isReferenceSourceOtherVisible"
          class="form-group col-md-6 col-sm-12"
        >
          <ValidationProvider
            v-slot="props"
            rules="required|max:200"
            :name="fieldNames.referenceSourceOther"
          >
            <label for="referenceSourceOther">
              {{ referenceSourceOtherLabel }}
            </label>
            <ValidatingControl :state="props">
              <input
                id="referenceSourceOther"
                v-model="form.referenceSourceOther"
                name="referenceSourceOther"
                type="text"
                class="form-control"
                placeholder="How did you hear about Zelman?"
              />
            </ValidatingControl>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-group form-check">
        <ValidationProvider
          v-slot="props"
          :rules="{ required: { allowFalse: false } }"
          :name="fieldNames.hasAcceptedTerms"
        >
          <input
            id="hasAcceptedTerms"
            v-model="form.hasAcceptedTerms"
            name="hasAcceptedTerms"
            type="checkbox"
            class="form-check-input"
          />
          <label class="form-check-label small" for="hasAcceptedTerms">
            By clicking here, you permit Zelman & Associates to email content
            related to our products and agree to our
            <a :href="links.termsOfUseUrl" target="_blank" rel="noopener"
              >Terms of Use</a
            >
            and
            <a :href="links.privacyPolicyUrl" target="_blank" rel="noopener"
              >Privacy Policy</a
            >. *</label
          >
          <FormError v-if="props.errors">
            {{ props.errors[0] }}
          </FormError>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <button
          :disabled="uiState.isLoading"
          type="submit"
          class="btn btn-orange btn-zelman btn-block"
        >
          Request Access
        </button>
      </div>
    </ValidationObserver>

    <Spinner v-if="uiState.isLoading" class="align-self-center" />

    <div v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful">
      <p class="text-white bg-danger rounded py-2 px-3 mb-0">
        <FontAwesomeIcon :icon="icons.faExclamationCircle" />
        There was an issue processing your request for access, please
        <a :href="links.contactUsUrl" class="alert-link text-white"
          >contact support</a
        >.
      </p>
    </div>

    <div v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful">
      <p class="py-2 px-3 rounded bg-success text-white mb-0">
        <FontAwesomeIcon :icon="icons.faCheckCircle" />
        Thank you for requesting access. You will receive an email response
        within 24 hours.
      </p>
    </div>

    <div
      v-if="isAuthenticated"
      class="w-100 text-center bg-warning mt-2 rounded py-2 px-3 d-inline-block font-weight-bolder"
    >
      You are currently logged in and have already been approved for access.
    </div>
  </div>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import FormError from '@/src/shared/components/FormError.vue';
import Spinner from '@/src/shared/components/Spinner.vue';
import ValidatingControl from '@/src/shared/components/ValidatingControl.vue';
import { fieldNames } from '@/src/shared/form-validation';
import { createResult } from '@/src/shared/result';

export default {
  name: 'AccessRequestForm',

  components: {
    FontAwesomeIcon,
    ValidationObserver,
    ValidationProvider,
    Spinner,
    ValidatingControl,
    FormError,
  },

  props: {
    /** @type {import('vue').PropOptions<string[]>} */
    invalidEmailDomains: {
      type: Array,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    invalidEmailDomainsMessage: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ contactUsUrl: string, privacyPolicyUrl: string, termsOfUseUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  data() {
    return {
      fieldNames,
      uiState: {
        showInvalidEmailDomainsMessage: false,
        hasSubmitted: false,
        isLoading: false,
        isSubmissionSuccessful: false,
      },
      form: resetForm(),
    };
  },

  computed: {
    isAuthenticated: rootComputed.isAuthenticated,

    icons() {
      return { faCheckCircle, faExclamationCircle };
    },

    personalDescriptions() {
      return [
        'Owner/C-Suite of a Company',
        'Manager/Director at a Company',
        'Employee of a Company',
        'Institutional Investor – CIO',
        'Institutional Investor – PM',
        'Institutional Investor – Trader',
        'Institutional Investor – Analyst/Associate',
        'Family Office',
        'Personal Investor/Interest',
        'Wealth Manager',
        'Other',
      ];
    },

    referenceSources() {
      return [
        'Google/Search Engine',
        'Industry Publication',
        'Previous Relationship with Zelman',
        'Referred by Friend or Colleague',
        'Social Media',
        'TV / Radio',
        'Event / Podcast / Webinar',
        'Zelman Email',
        'Other',
      ];
    },

    /** @returns {boolean} */
    isReferenceSourceOtherVisible() {
      return (
        this.form.referenceSource === 'Other' ||
        this.form.referenceSource === 'Industry Publication' ||
        this.form.referenceSource === 'Event / Podcast / Webinar'
      );
    },

    /** @returns {string} */
    referenceSourceOtherLabel() {
      if (this.form.referenceSource === 'Other') {
        return 'Other (please fill in) *';
      }

      if (
        this.form.referenceSource === 'Industry Publication' ||
        this.form.referenceSource === 'Event / Podcast / Webinar'
      ) {
        return 'Please Specify *';
      }

      return '';
    },

    /** @returns {InstanceType<typeof ValidationObserver>} */
    observer() {
      /** @type {any} */
      const observer = this.$refs.observer;

      return observer;
    },
  },
  methods: {
    onEmailChange() {
      this.uiState.showInvalidEmailDomainsMessage =
        this.invalidEmailDomains.some((d) =>
          this.form.email.toLowerCase().includes(d)
        );
    },

    async onSubmit() {
      const isValid = await this.observer.validate();

      if (!isValid) {
        return;
      }

      this.uiState.isLoading = true;
      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;

      if (this.form.personalDescription !== 'Other') {
        this.form.personalDescriptionOther = '';
      }

      if (
        this.form.referenceSource !== 'Other' &&
        this.form.referenceSource !== 'Industry Publication' &&
        this.form.referenceSource !== 'Event / Podcast / Webinar'
      ) {
        this.form.referenceSourceOther = '';
      }

      const result = await createResult(
        axios.post('/api/access-request', this.form)
      );

      this.uiState.isLoading = false;
      this.uiState.hasSubmitted = true;

      if (result.isFailure) {
        this.uiState.isSubmissionSuccessful = false;

        return;
      }

      this.form = resetForm();
      this.uiState.isSubmissionSuccessful = true;
    },
  },
};

function resetForm() {
  return {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    companyPhone: '',
    hasAcceptedTerms: false,
    personalDescription: '',
    personalDescriptionOther: '',
    referenceSource: '',
    referenceSourceOther: '',
  };
}
</script>
