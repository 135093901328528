<template>
  <ValidationObserver ref="observer" tag="div">
    <div class="form-row">
      <div class="form-group col-md-6 col-sm-12">
        <ValidationProvider
          v-slot="props"
          ref="password"
          :name="fieldNames.newPassword"
          vid="password"
          rules="required|passwordComplexity"
        >
          <label for="setPassword">Password *</label>
          <ValidatingControl :state="props">
            <input
              id="newPassword"
              ref="password"
              v-model="password"
              name="newPassword"
              type="password"
              class="form-control"
              placeholder="Password"
              @change="onChange('password')"
            />
          </ValidatingControl>
        </ValidationProvider>
      </div>
      <div class="form-group col-md-6 col-sm-12">
        <ValidationProvider
          v-slot="props"
          ref="passwordConfirm"
          :name="fieldNames.newPasswordConfirm"
          rules="required|passwordConfirmed:password"
        >
          <label for="setPasswordConfirm">Password Confirmation *</label>
          <ValidatingControl :state="props">
            <input
              id="newPasswordConfirm"
              v-model="passwordConfirm"
              name="newPasswordConfirm"
              type="password"
              class="form-control"
              placeholder="Confirm Password"
              @change="onChange('passwordConfirm')"
            />
          </ValidatingControl>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import axios from 'axios';
import {
  configure,
  extend,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

import {
  createPasswordComplexityValidator,
  fieldNames,
} from '../form-validation';

import ValidatingControl from './ValidatingControl.vue';

export default {
  name: 'PasswordForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidatingControl,
  },

  data() {
    return {
      fieldNames,
      password: '',
      passwordConfirm: '',
    };
  },

  computed: {
    /** @returns {InstanceType<typeof ValidationObserver>} */
    observer() {
      /** @type {any} */
      const observer = this.$refs.observer;

      return observer;
    },
  },

  watch: {
    password() {
      this.update();
    },
    passwordConfirm() {
      this.update();
    },
  },

  async created() {
    configure({
      mode: 'passive',
    });

    extend('passwordConfirmed', {
      // @ts-ignore
      validate: (value, { other }) => value === other,
      message: 'The password confirmation does not match.',
      params: [{ name: 'other', isTarget: true }],
    });

    const requirements = await axios
      .get('/api/reset-password/requirements')
      .then((resp) => resp.data);

    extend(
      'passwordComplexity',
      createPasswordComplexityValidator(requirements)
    );
  },

  methods: {
    async update() {
      const isFormValid = await this.observer.validate({ silent: true });

      this.$emit('update', { isValid: isFormValid, password: this.password });
    },
    async onChange(field) {
      /** @type {any} */
      const fieldRef = this.$refs[field];

      const fieldValidation = await fieldRef.validate();
      const isFormValid = await this.observer.validate({ silent: true });
      this.$emit('update', {
        isValid: fieldValidation.valid && isFormValid,
        password: this.password,
      });
    },
  },
};
</script>
