<template>
  <div>
    <div v-for="item in cartItems" :key="item.id" class="card mb-3">
      <div class="row no-gutters align-items-center">
        <div class="col-md-2 text-center py-3">
          <span class="h1">
            <FontAwesomeIcon :icon="icons.faChartBar" />
          </span>
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h5 class="card-title">
              {{ item.name }}
            </h5>
            <p class="card-text">
              <small class="text-muted">
                {{ item.type }}
                {{ discountSummary(item) }}
              </small>
            </p>
          </div>
        </div>
        <div class="col-md-4 text-center py-3 pr-2">
          <small v-if="item.discount">
            ({{ formatCurrency(item.discount) }} discount)
          </small>
          <span>{{ formatCurrency(item.price) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { formatCurrency } from '@/src/shared/formatters';

import { checkoutComputed } from '../store';

export default {
  name: 'Items',

  components: {
    FontAwesomeIcon,
  },

  computed: {
    cartItems: checkoutComputed.cartItems,

    icons() {
      return { faChartBar };
    },
  },

  methods: {
    formatCurrency,

    discountSummary(item) {
      return item.discountSummary ? `- ${item.discountSummary}` : '';
    },
  },
};
</script>
