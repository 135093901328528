import Vue from 'vue';

/**
 * Queries for the app by a matching HTML element
 * @param {String} appName
 * @returns {boolean} true if the app is found
 */
export const isVueAppInDOM = (appName) => !!document.querySelector(appName);

/**
 * Creates an instance of a Vue app with the provided component as the root of the app,
 * targeting the element matching the given selector
 * @param {import('vue').VueConstructor<Vue>} rootComponent
 * @param {string} selector
 */
export const renderApp = (rootComponent, selector) =>
  new Vue({
    render: (create) =>
      create(rootComponent, {
        props: {
          ...window[selector],
        },
      }),
  }).$mount(selector);
