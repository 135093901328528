<template>
  <div class="card-body">
    <div class="form">
      <div class="form-row">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Published Date Filter</label>
          <select
            id="dateFilterOption"
            v-model="dateFilterOption"
            class="form-control"
            @change="onFilterOptionChange"
          >
            <option
              v-for="option in dateFilterOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="dateFilterOption === dateFilterOptions.Custom">
        <div class="form-row">
          <label for="dateFrom" class="mb-0"><small>From</small></label>
          <div class="input-group">
            <Datepicker
              id="dateFrom"
              ref="dateFrom"
              aria-describedby="inputDateFrom"
              input-class="form-control d-inline"
              :value="dateFrom"
              :disabled-dates="disabledDatesFrom"
              name="dateFrom"
              @selected="onSelected($event, 'from')"
            >
              <span
                id="inputDateFrom"
                slot="afterDateInput"
                class="input-group-text d-inline position-absolute calendar"
                @click="onCalendarClick('dateFrom')"
              >
                <FontAwesomeIcon :icon="icons.faCalendarAlt" />
              </span>
            </Datepicker>
          </div>
        </div>
        <div class="form-row mt-2">
          <label for="dateTo" class="mb-0"><small>To</small></label>
          <div class="input-group">
            <Datepicker
              id="dateTo"
              ref="dateTo"
              input-class="form-control d-inline"
              aria-describedby="inputDateTo"
              :value="dateTo"
              :disabled-dates="disabledDatesTo"
              name="dateTo"
              @selected="onSelected($event, 'to')"
            >
              <span
                id="inputDateTo"
                slot="afterDateInput"
                class="input-group-text d-inline position-absolute calendar"
                @click="onCalendarClick('dateTo')"
              >
                <FontAwesomeIcon :icon="icons.faCalendarAlt" />
              </span>
            </Datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Datepicker from 'vuejs-datepicker';

import { dateFilterOptions } from './store/state';

export default {
  name: 'ReportSearchDateFilter',

  components: {
    FontAwesomeIcon,
    Datepicker,
  },

  props: {
    /** @type {import('vue').PropOptions<DateFilter>} */
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dateFilterOptions,
      /** @type {Date | undefined} */
      dateFrom: undefined,
      /** @type {Date | undefined} */
      dateTo: undefined,
      dateFilterOption: dateFilterOptions.None,
    };
  },

  computed: {
    /**
     * @returns {{ from: Date | string | undefined }}
     */
    disabledDatesFrom() {
      return {
        from: this.dateTo,
      };
    },

    /**
     * @returns {{ to: Date | string | undefined}}
     */
    disabledDatesTo() {
      return {
        to: this.dateFrom,
      };
    },

    /**
     * @returns {{ faCalendarAlt: any }}
     */
    icons() {
      return { faCalendarAlt };
    },
  },

  watch: {
    options: {
      immediate: true,
      /** @param {DateFilter} newVal */
      handler(newVal) {
        if (newVal.from) {
          this.dateFrom = new Date(newVal.from);
        }
        if (newVal.to) {
          this.dateTo = new Date(newVal.to);
        }
        if (!!newVal.option && this.dateFilterOption !== newVal.option) {
          this.dateFilterOption = newVal.option;
        }
      },
    },
  },

  methods: {
    /**
     * @returns {void}
     */
    onFilterOptionChange() {
      if (this.dateFilterOption === dateFilterOptions.Custom) {
        this.dateFrom = undefined;
        this.dateTo = undefined;

        return;
      }

      let from = undefined;

      if (this.dateFilterOption === dateFilterOptions.PreviousSevenDays) {
        from = createDateDaysAgo(7);
      } else if (
        this.dateFilterOption === dateFilterOptions.PreviousThirtyDays
      ) {
        from = createDateDaysAgo(30);
      } else if (
        this.dateFilterOption === dateFilterOptions.PreviousSixMonths
      ) {
        from = createDateDaysAgo(180);
      }

      this.$emit('change', {
        from,
        to: undefined,
        option: this.dateFilterOption,
      });
    },

    /**
     * @returns {void}
     */
    onCalendarClick(property) {
      /** @type {any} */
      const datepicker = this.$refs[property];
      datepicker.showCalendar();
    },

    /**
     * @returns {void}
     */
    onSelected(value, property) {
      const payload = {
        from: this.dateFrom,
        to: this.dateTo,
        option: this.dateFilterOption,
      };

      payload[property] = value.toISOString();

      this.$emit('change', payload);
    },
  },
};

function createDateDaysAgo(days) {
  return new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString();
}

/**
 * @typedef {import('./store/state').DateFilter} DateFilter
 */
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

.accord-icon {
  border-radius: 50%;
  border: 1px solid $white;
  width: 20px;
  height: 0;
  padding: 0 0 20px;
  box-sizing: content-box;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  .a-i1 {
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
  }
  .a-i2 {
    transform: none;
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
    transition: all 0.25s;
  }
}

.collapsed .accord-icon .a-i2 {
  transform: rotate(90deg);
}

.calendar {
  right: 0px;
  bottom: 0px;
}
</style>
