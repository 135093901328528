<template>
  <FontAwesomeIcon
    v-if="icon"
    class="position-absolute"
    :class="iconClass"
    :icon="icon"
  />
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    controlState: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    icon() {
      const { untouched, pristine, valid, invalid } = this.controlState;

      if (untouched || (pristine && valid)) {
        return '';
      }

      if (valid) {
        return faCheckCircle;
      } else if (invalid) {
        return faExclamationCircle;
      }

      return '';
    },
    iconClass() {
      const { untouched, pristine, valid, invalid } = this.controlState;

      if (untouched || (pristine && valid)) {
        return '';
      }

      if (valid) {
        return 'text-success';
      } else if (invalid) {
        return 'text-danger';
      }

      return '';
    },
  },
};
</script>
