import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import Checkout from './Checkout.vue';

export function load() {
  const selector = 'zel-checkout';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(Checkout, selector);
}
