<template>
  <div class="card mb-3">
    <div class="card-body">
      <p class="card-title mb-0">
        <a
          :href="`#order-${order.id}`"
          data-toggle="collapse"
          role="button"
          :aria-expanded="isExpanded"
          class="row"
          @click="onClickToggleExpanded()"
        >
          <span class="col-4 col-sm-4 col-md-3">
            <small>{{ formatDate(order.date) }}</small>
          </span>
          <span class="col-4 col-sm-4 col-md-5 text-right">
            <span v-if="order.tax" class="mr-3 text-muted small">
              ({{ formatCurrency(order.tax) }} tax)
            </span>
            {{ formatCurrency(order.total) }}
            <FontAwesomeIcon :icon="icons.faEllipsisH" />
          </span>
          <span class="col-2 col-sm-2 col-md-2">
            {{ order.statusName }}
          </span>
          <span class="col text-right">
            <button
              class="btn btn-orange btn-tiny btn-zelman"
              @click.stop="onViewInvoiceClick"
            >
              View Invoice
            </button>
          </span>
        </a>
      </p>
      <div
        :id="`#order-${order.id}`"
        class="collapse mt-2"
        :class="{ show: isExpanded }"
      >
        <OrderListOrderItem
          v-for="(item, index) in order.items"
          :key="item.id"
          :item="item"
          :index="index"
          :links="links"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

import { formatCurrency, formatDate } from '@/src/shared/formatters';
import { createResult } from '@/src/shared/result';

import OrderListOrderItem from './OrderListOrderItem.vue';

/**
 * @typedef Order
 * @property {string} id
 * @property {string} date
 * @property {{ id: string }[]} items
 * @property {string} statusName
 * @property {number} tax
 * @property {number} total
 */

export default {
  name: 'OrderListItem',

  components: {
    FontAwesomeIcon,
    OrderListOrderItem,
  },

  props: {
    /** @type {import('vue').PropOptions<Order>} */
    order: {
      required: true,
      type: Object,
    },

    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    icons() {
      return { faEllipsisH };
    },
  },

  methods: {
    formatCurrency,
    formatDate,

    onClickToggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },

    async onViewInvoiceClick() {
      const result = await createResult(
        axios
          .get(`/api/account/order/${this.order.id}/invoice`)
          .then((resp) => {
            /** @type {{ invoiceHtml: string }} */
            const data = resp.data;

            return data;
          })
      );

      if (result.isFailure) {
        return;
      }

      this.$emit('selectInvoice', {
        invoiceDate: this.order.date,
        invoiceHtml: result.value.invoiceHtml,
      });
    },
  },
};
</script>
