/**
 * @type {DashboardState}
 */
export const state = {
  subscriptions: [],
  recentReports: [],
  individualReports: [],
  displayCounts: {
    recentReports: 5,
    subscriptions: 3,
    individualReports: 3,
  },
};

/**
 * @typedef ReportResponse
 * @property {number} categoryId
 * @property {string} title
 * @property {string} subtitle
 * @property {string} nodeGuid
 * @property {string} publishedDate
 * @property {string} pageLinkUrl

 * @typedef SubscriptionResponse
 * @property {string} name
 * @property {ReportResponse} report

 * @typedef DashboardState
 *
 * @property {SubscriptionResponse[]} subscriptions
 * @property {ReportResponse[]} recentReports
 * @property {ReportResponse[]} individualReports
 * @property {{ recentReports: number, subscriptions: number, individualReports: number }} displayCounts
 */
