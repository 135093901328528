<template>
  <div>
    <form v-if="showFilterBox" class="bg-light-2 p-3">
      <div class="form-group m-0">
        <input
          v-model="categoryFilter"
          type="text"
          class="form-control"
          placeholder="Search"
        />
      </div>
    </form>
    <div class="card-body overflow-list overflow-auto">
      <div
        v-for="category in filteredCategories"
        :key="category.id"
        class="custom-control custom-checkbox"
      >
        <input
          :id="getId(category.id)"
          type="checkbox"
          class="custom-control-input"
          :checked="category.isSelected"
          :aria-label="category.value"
          @change="onChange(category.id)"
        />
        <label
          class="custom-control-label"
          :class="{ disabled: !category.count }"
          :for="getId(category.id)"
        >
          {{ category.value }}

          <span v-if="isNotComparableFinancialData(category)"
            >({{ category.count }})</span
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @typedef Category
 * @property {number} id
 * @property {string} value
 * @property {boolean} isSelected
 * @property {number} count
 */

export default {
  name: 'ReportSearchCategoryFilter',

  props: {
    /** @type {import('vue').PropOptions<Category[]>} */
    categories: {
      type: Array,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    group: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      categoryFilter: '',
    };
  },

  computed: {
    /**
     * @returns {boolean}
     */
    showFilterBox() {
      return this.categories.length > 15;
    },

    /**
     * @returns {Category[]}
     */
    filteredCategories() {
      return this.categories.filter((o) =>
        o.value.toLowerCase().includes(this.categoryFilter)
      );
    },
  },

  methods: {
    /**
     * @returns {string}
     */
    getId(categoryId) {
      return `category-${categoryId}`;
    },

    /**
     * @returns {void}
     */
    onChange(categoryId) {
      this.$emit('change', {
        categoryId,
        categoryGroup: this.group,
      });
    },

    /**
     * We want to explicitly remove the "(9)" count for the Comp Reports series
     * because we are excluding them from the default search results to keep them
     * out of the top results since they are updated daily.
     *
     * See: ~\docs\architectural-decision-record.md - `2020-01-12 - Keep Comparable Financial Data Reports out of Default Search Results`
     * @param {import('./store/state').ResultCategoryFilter} category
     * @returns {boolean}
     */
    isNotComparableFinancialData(category) {
      return category.id !== 204;
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-list {
  height: 300px;
}
</style>
