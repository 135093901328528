<template>
  <div class="card">
    <div class="card-header m-0 p-0">
      <h2 class="m-0 p-0">
        <slot name="header" :step="step" />
      </h2>
    </div>
    <transition name="expand">
      <div v-show="show" class="card-body">
        <slot name="body" />
      </div>
    </transition>
  </div>
</template>

<script>
import { checkoutComputed } from '../store';

export default {
  name: 'CartStep',

  props: {
    step: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentStep: checkoutComputed.currentStep,

    /** @return {boolean} */
    show() {
      return this.step.name === this.currentStep.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-leave,
.expand-enter-to {
  opacity: 1;
  max-height: 700px;
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease-in-out;
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
