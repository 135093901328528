<template>
  <div>
    <ReportSearchBox />

    <div class="section">
      <div class="container">
        <div class="row py-5">
          <div class="col-md-4 col-lg-4 d-none d-sm-none d-md-block">
            <ReportSearchFilters />
          </div>

          <div class="col d-block d-sm-block d-md-none mb-3">
            <div class="card">
              <div class="card-header p-0">
                <button
                  class="btn btn-primary btn-zelman btn-block"
                  @click="show = !show"
                >
                  FILTERS
                </button>
              </div>
              <div v-show="show" class="card-body">
                <ReportSearchFilters />
                <button
                  class="btn btn-primary btn-zelman w-100 text-center mt-3"
                  @click="show = !show"
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>

          <div v-if="isInitialLoad" class="col-md-8 col-lg-8">
            <ListContentLoader :sections="3" :rows-per-section="2" />
          </div>
          <div v-else class="col-md-8 col-lg-8">
            <ReportSearchResults />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'qss';

import { store } from '@/src/rootStore';
import ListContentLoader from '@/src/shared/components/ListContentLoader.vue';

import ReportSearchBox from './ReportSearchBox.vue';
import ReportSearchFilters from './ReportSearchFilters.vue';
import ReportSearchResults from './ReportSearchResults.vue';
import { action, reportSearchComputed, mutation } from './store';

export default {
  name: 'ReportSearch',

  components: {
    ListContentLoader,
    ReportSearchBox,
    ReportSearchResults,
    ReportSearchFilters,
  },

  data() {
    return {
      show: false,
    };
  },

  store,

  computed: {
    isInitialLoad: reportSearchComputed.isInitialLoad,
  },

  async created() {
    this.$store.commit(mutation('UPDATE_IS_INITIAL_LOAD'), true);

    /** @type {{[index: string]: unknown }} */
    const querystrings = decode(location.search.substring(1));

    const { c } = querystrings;

    if (c) {
      const categoryIds =
        typeof c === 'number' && Number.isInteger(c)
          ? [c]
          : `${c}`.split(',').map((n) => +n);

      await this.$store.dispatch(action('LOAD_SEARCH_SETTINGS'));

      this.$store.commit(mutation('SET_CATEGORY_FILTERS'), { categoryIds });
    }

    await this.$store.dispatch(action('SEARCH'));

    this.$store.commit(mutation('UPDATE_IS_INITIAL_LOAD'), false);
  },
};
</script>
