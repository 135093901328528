import axios from 'axios';

import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';

export const actionTypes = {
  REQUEST_RECENT_REPORTS: 'REQUEST_RECENT_REPORTS',
  REQUEST_SUBSCRIPTIONS: 'REQUEST_SUBSCRIPTIONS',
  REQUEST_INDIVIDUAL_REPORTS: 'REQUEST_INDIVIDUAL_REPORTS',
};

/**
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `dashboard/${actionType}`;
}

export const actions = {
  async [actionTypes.REQUEST_SUBSCRIPTIONS]({ commit }) {
    const result = await createResult(
      axios
        .get('/api/account/dashboard/report/subscription')
        .then((resp) => resp.data)
    );

    if (result.isFailure) {
      return;
    }

    commit(mutationTypes.SET_SUBSCRIPTIONS, result.value);
  },

  async [actionTypes.REQUEST_RECENT_REPORTS]({ commit }) {
    const result = await createResult(
      axios
        .get('/api/account/dashboard/report/recent')
        .then((resp) => resp.data)
    );

    if (result.isFailure) {
      return;
    }

    commit(mutationTypes.SET_RECENT_REPORTS, result.value);
  },

  async [actionTypes.REQUEST_INDIVIDUAL_REPORTS]({ commit }) {
    const result = await createResult(
      axios
        .get('/api/account/dashboard/report/individual')
        .then((resp) => resp.data)
    );

    if (result.isFailure) {
      return;
    }

    commit(mutationTypes.SET_INDIVIDUAL_REPORTS, result.value);
  },
};
