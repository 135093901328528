import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {CheckoutState} state
   */
  cart: ({ cart }) => cart,

  /**
   * @param {CheckoutState} state
   */
  cartItems: ({ cart }) => cart.items,

  /**
   * @param {CheckoutState} state
   */
  isLoading: ({ ui }) => ui.isLoading,

  /**
   * @param {CheckoutState} state
   */
  currentStep: ({ ui }) => ui.currentStep,

  /**
   * @param {CheckoutState} state
   */
  cartTotals: ({ cart }) => cart.totals,

  /**
   * @param {CheckoutState} state
   * @returns {PaymentOption}
   */
  selectedPaymentOption: ({ cart }) => {
    return (
      cart.paymentOptions.find((option) => cart.paymentToken === option.id) || {
        address: cart.billingAddress,
        brand: '',
        expiration: '',
        id: '',
        number: '',
      }
    );
  },

  /**
   * @param {CheckoutState} state
   */
  ui: ({ ui }) => ui,

  /**
   * @param {CheckoutState} state
   */
  settings: ({ settings }) => settings,

  /**
   * @param {CheckoutState} state
   * @returns {{ [k in CART_STEP]: StepState }}
   */
  stepStates: ({ cart, ui }) => {
    const { isLoading } = ui;

    return {
      complete: {
        isEnabled:
          !isLoading &&
          !!cart.items.length &&
          !!cart.paymentToken &&
          isAddressValid(cart.billingAddress),
        name: 'complete',
      },
      payment: {
        isEnabled: !isLoading && !!cart.items.length,
        name: 'payment',
      },
      preview: {
        isEnabled: true,
        name: 'preview',
      },
      review: {
        isEnabled:
          !isLoading &&
          !!cart.items.length &&
          !!cart.paymentToken &&
          isAddressValid(cart.billingAddress),
        name: 'review',
      },
    };
  },
};

/**
 * @param {Address} billingAddress
 * @returns {boolean}
 */
function isAddressValid(billingAddress) {
  const { id, billingName, city, countryId, postalCode, stateId, line1 } =
    billingAddress;

  return (
    !!id &&
    !!billingName &&
    !!city &&
    !!countryId &&
    !!postalCode &&
    !!stateId &&
    !!line1
  );
}

export const checkoutComputed = /** @type {CheckoutComputed} */ (
  createNamespacedHelpers('checkout').mapGetters([
    'cart',
    'isLoading',
    'cartItems',
    'currentStep',
    'totals',
    'selectedPaymentOption',
    'ui',
    'settings',
    'stepStates',
  ])
);

/**
 * @typedef {import('./state').CheckoutState} CheckoutState
 * @typedef {import('./state').Cart} Cart
 * @typedef {import('./state').CartSettings} CartSettings
 * @typedef {import('./state').CART_STEP} CART_STEP
 * @typedef {import('./state').CartItem} CartItem
 * @typedef {import('./state').StepState} StepState
 * @typedef {import('./state').CartUI} CartUI
 * @typedef {import('./state').Address} Address
 * @typedef {import('./state').CartTotals} CartTotals
 * @typedef {import('./state').PaymentOption} PaymentOption
 *
 * @typedef CheckoutComputed
 * @property {() => Cart} cart
 * @property {() => boolean} isLoading
 * @property {() => CartItem[]} cartItems
 * @property {() => StepState} currentStep
 * @property {() => CartTotals} totals
 * @property {() => PaymentOption} selectedPaymentOption
 * @property {() => CartUI} ui
 * @property {() => CartSettings} settings
 * @property {() => { [k in CART_STEP]: StepState }} stepStates
 */
