/**
 * Returns a custom VeeValidate password complexity validator
 * @param {{ regex: string, message: string }} requirements
 */
export const createPasswordComplexityValidator = (requirements) => {
  const regex = RegExp(requirements.regex);
  const message = requirements.message;

  return {
    message(_field, _) {
      return message;
    },
    validate(value, _) {
      return regex.test(value);
    },
  };
};

export const fieldNames = {
  addressBillingName: 'Full Name',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  addressCity: 'City',
  addressStateId: 'State',
  addressCountryId: 'Country',
  addressPostal: 'Postal Code',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailAddress: 'Email Address',
  companyName: 'Company Name',
  companyPhone: 'Company Phone',
  hasAcceptedTerms: 'Terms Of Use',
  currentPassword: 'Current Password',
  username: 'Username',
  password: 'Password',
  passwordConfirm: 'Password Confirmation',
  newPassword: 'New Password',
  newPasswordConfirm: 'Confirm New Password',
  forgotPasswordEmail: 'Email',
  personalDescription: 'How would you describe yourself?',
  personalDescriptionOther: 'This',
  referenceSource: 'How did you hear about Zelman?',
  referenceSourceOther: 'This',
};

export const passwordConfirmedValidator = {
  validate: (value, { password }) => value === password,
  message: 'The passwords do not match',
  params: [{ name: 'password', isTarget: true }],
};
