export function load() {
  const toggleElSelector = 'data-team-members-toggle';
  const teamMembersSelector = 'data-team-members';

  const toggleEl = document.querySelector(`[${toggleElSelector}]`);

  if (!toggleEl) {
    return;
  }

  toggleEl.addEventListener('click', function (e) {
    e.preventDefault();

    const target = e.target;
    const teamMembers = document.querySelector(`[${teamMembersSelector}]`);

    if (target.attributes[toggleElSelector].value === 'off') {
      target.attributes[toggleElSelector].value = 'on';
      teamMembers.classList.remove('d-none');
    } else {
      target.attributes[toggleElSelector].value = 'off';
      teamMembers.classList.add('d-none');
    }
  });
}
