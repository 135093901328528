import { extend, localize } from 'vee-validate';
import { email, max, min_value, required } from 'vee-validate/dist/rules';

import { store } from '../rootStore';
import { action as userAction } from '../rootStore/user';
import { passwordConfirmedValidator } from '../shared/form-validation';

/**
 * @param {import('vue/types/vue').VueConstructor} vue
 */
export function configureVue(vue) {
  extend('required', {
    ...required,
    message(field, _params) {
      return `${field} field is required`;
    },
  });

  extend('email', {
    ...email,
  });
  extend('min_value', {
    ...min_value,
  });
  extend('max', {
    ...max,
  });
  localize({
    en: {
      messages: {
        max: (_, { length }) =>
          `This field must have no more than ${length} characters`,
      },
    },
  });

  extend('passwordConfirmed', passwordConfirmedValidator);

  const config = vue.config;

  config.productionTip = false;

  config.errorHandler = function (err, vm, info) {
    console.error('VueJs Error');
    console.error(err, vm, info);
  };

  store.dispatch(userAction('REQUEST_AUTHENTICATION_STATE'));
  store.dispatch(userAction('REQUEST_CART_ITEM_COUNT'));
}
