<template>
  <div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-5">
            <h5>Payment</h5>
            <Payment />
          </div>

          <div class="billing-address">
            <div class="row no-gutter col-12">
              <h5 class="pr-3">Billing Address</h5>
              <FontAwesomeIcon
                tabindex="0"
                b-tooltip.hover
                :icon="icons.faQuestionCircle"
                title="If this isn't the address you're expecting, please save your billing address in the previous step."
              />
            </div>
            <BillingAddress />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <h5>Cart</h5>
          <Items />

          <Totals />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import BillingAddress from './BillingAddress.vue';
import Items from './Items.vue';
import Payment from './Payment.vue';
import Totals from './Totals.vue';

export default {
  name: 'Review',

  components: {
    FontAwesomeIcon,
    Payment,
    BillingAddress,
    Items,
    Totals,
  },

  computed: {
    icons() {
      return { faQuestionCircle };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/bootstrap';

.billing-address {
  @include media-breakpoint-down(md) {
    margin-bottom: 3em;
  }
}
</style>
