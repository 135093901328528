<template>
  <div class="row border-top p-2 no-gutters" :class="backgroundClass">
    <div class="col m-0 p-0">
      <div class="row">
        <div class="col-10">
          <h5 class="mb-2 small">
            <a
              :href="reportCategoryUrl"
              class="font-weight-bold text-uppercase"
            >
              {{ item.title }}
            </a>
          </h5>
        </div>
        <div class="col-2 text-right bg-transparent">
          <span class="small text-muted d-md-block m-0 p-0">
            {{ formatCurrency(item.price) }}
          </span>
        </div>
      </div>
      <h6 v-if="item.subtitle" class="mb-2">
        <a :href="item.linkUrl">{{ item.subtitle }}</a>
      </h6>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/src/shared/formatters';
import { reportSearchUrlDeepLink } from '@/src/shared/links';

/**
 * @typedef OrderItem
 * @property {number} categoryId
 * @property {string} linkUrl
 * @property {string} subtitle
 * @property {string} title
 * @property {number} price
 */

export default {
  name: 'OrderListOrderItem',

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<OrderItem>} */
    item: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<number>} */
    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      isDownloadFailed: false,
    };
  },

  computed: {
    /** @returns {string} */
    reportCategoryUrl() {
      return reportSearchUrlDeepLink(this.links.reportSearchUrl, [
        this.item.categoryId,
      ]);
    },

    /** @returns {string} */
    backgroundClass() {
      return this.index % 2 === 0 ? 'bg-light' : '';
    },
  },

  methods: {
    formatCurrency,
  },
};
</script>
