<template>
  <div
    class="col mt-3 mt-md-0 px-3 px-md-4 px-lg-5 py-4 py-md-4 py-lg-5"
    :class="{ 'd-none': isHidden }"
  >
    <h5 class="text-left text-decor mb-3 pb-2">Latest Reports</h5>

    <ReportListItem
      v-for="report in recentReports.displayed"
      :key="report.nodeGuid"
      :report="report"
      :links="links"
      :is-downloadable="true"
    />
  </div>
</template>

<script>
import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { USER_TIERS } from '@/src/rootStore/user/state';

import ReportListItem from './ReportListItem.vue';
import { action, dashboardComputed } from './store';

export default {
  name: 'RecentReportsList',

  components: {
    ReportListItem,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...dashboardComputed,
    userTier: rootComputed.userTier,

    /** @returns {boolean} */
    isHidden() {
      return this.userTier !== USER_TIERS.FullAccess;
    },
  },

  watch: {
    isHidden: {
      immediate: true,
      handler(isHidden) {
        if (!isHidden) {
          this.$store.dispatch(action('REQUEST_RECENT_REPORTS'));
        }
      },
    },
  },
};
</script>
