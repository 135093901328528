<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div v-for="item in cartItems" :key="item.id" class="card mb-3">
      <div class="row no-gutters align-items-center">
        <div class="col-md-2 text-center">
          <span class="h1">
            <FontAwesomeIcon :icon="icons.faChartBar" />
          </span>
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h5 class="card-title">
              {{ item.name }}
            </h5>
            <p class="card-text" v-html="item.description"></p>
            <p class="card-text">
              <small class="text-muted">
                {{ item.type }} {{ discountSummary(item) }}
              </small>
            </p>
          </div>
        </div>
        <div class="col-md-2 text-center">
          {{ formatCurrency(item.price) }}
          <small v-if="item.discount">
            ({{ formatCurrency(item.discount) }} discount)
          </small>
        </div>
        <div class="col">
          <a
            href=""
            class="text-center"
            @click.prevent.stop="onItemRemove(item)"
          >
            <span class="d-block small">REMOVE</span>
            <span class="d-block">
              <FontAwesomeIcon :icon="icons.faTimes" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { formatCurrency } from '@/src/shared/formatters';

import { action, checkoutComputed } from '../store';

export default {
  name: 'Items',

  components: {
    FontAwesomeIcon,
  },

  computed: {
    ...checkoutComputed,

    icons() {
      return { faTimes, faChartBar };
    },
  },

  methods: {
    formatCurrency,

    discountSummary(item) {
      return item.discountSummary ? `- ${item.discountSummary}` : '';
    },

    async onItemRemove(item) {
      if (this.isLoading) {
        return;
      }

      await this.$store.dispatch(action('REMOVE_CART_ITEM'), item);
    },
  },
};
</script>
