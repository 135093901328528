export const mutationTypes = {
  SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
  SET_REQUEST_STATE: 'SET_REQUEST_STATE',
  SET_CART_ITEM_COUNT: 'SET_CART_ITEM_COUNT',
};

/**
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `user/${mutationType}`;
}

export const mutations = {
  /**
   * @param {import('./state').UserState} state
   * @param {{ isAuthenticated: boolean, username: string, ceContactGuid: string, userTier: import('./state').UserTier }} response
   */
  [mutationTypes.SET_IS_AUTHENTICATED](
    state,
    { isAuthenticated, username, ceContactGuid, userTier }
  ) {
    state.isAuthenticated.value = isAuthenticated;
    state.username = username;
    state.ceContactGuid = ceContactGuid;
    state.userTier = userTier;
  },

  /**
   * @param {import('./state').UserState} state
   * @param {number} count
   */
  [mutationTypes.SET_CART_ITEM_COUNT](state, count) {
    state.cartItemCount.value = count;
  },

  /**
   * @param {import('./state').UserState} state
   * @param {{ key: string, requestState: import('./state').RequestState }
   */
  [mutationTypes.SET_REQUEST_STATE](state, { key, requestState }) {
    state[key].requestState = requestState;
  },
};
