<template>
  <div>
    <div class="row mb-3">
      <div class="col-md-12">
        <a
          :href="links.accountDashboardUrl"
          class="btn btn-orange btn-zelman mt-3"
        >
          Go To My Account
        </a>
        <a
          :href="links.reportSearchUrl"
          class="btn btn-orange btn-zelman mt-3 ml-2"
        >
          Explore Full Library
        </a>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <h4 v-if="individualReports.length">Individual Reports</h4>
        <ReportListItem
          v-for="report in individualReports"
          :key="report.nodeGuid"
          :report="report"
          :links="links"
          :is-downloadable="true"
          :show-category-link="true"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <h4 v-if="subscriptions.length">Subscriptions</h4>
        <SubscriptionListItem
          v-for="subscription in subscriptions"
          :key="subscription.categoryId"
          :links="links"
          :subscription="subscription"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { decode } from 'qss';

import { createResult } from '@/src/shared/result';
import ReportListItem from '@/Views/Account/Dashboard/ReportListItem.vue';

import SubscriptionListItem from './SubscriptionListItem.vue';

export default {
  name: 'CheckoutThankYou',

  components: {
    ReportListItem,
    SubscriptionListItem,
  },

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string, accountDashboardUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      /** @type {{ nodeGuid: string }[]} */
      individualReports: [],
      /** @type {{ categoryId: number }[]} */
      subscriptions: [],
      invoiceNumber: '',
    };
  },

  async created() {
    /** @type {{[index: string]: unknown }} */
    const querystrings = decode(location.search.substring(1));

    const { orderId } = querystrings;

    const result = await createResult(
      axios.get(`/api/checkout/order/${orderId}`).then((resp) => {
        /** @type {{ individualReports: [], subscriptions: [{ categoryId: number }], invoiceNumber: string }} */
        const data = resp.data;

        return data;
      })
    );

    if (result.isFailure) {
      return;
    }

    this.individualReports = result.value.individualReports;
    this.subscriptions = result.value.subscriptions;
    this.invoiceNumber = result.value.invoiceNumber;
  },
};
</script>
