import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {import('./state').DashboardState} state
   */
  subscriptions: ({ subscriptions, displayCounts }) =>
    bifurcateReports(subscriptions, displayCounts.subscriptions),

  /**
   * @param {import('./state').DashboardState} state
   */
  individualReports: ({ individualReports, displayCounts }) =>
    bifurcateReports(individualReports, displayCounts.individualReports),

  /**
   * @param {import('./state').DashboardState} state
   */
  recentReports: ({ recentReports, displayCounts }) =>
    bifurcateReports(recentReports, displayCounts.recentReports),
};

function bifurcateReports(reports, displayCount) {
  return hasCollapsedReports(reports, displayCount)
    ? {
        displayed: reports.slice(0, displayCount),
        collapsed: reports.slice(displayCount, reports.length),
        hasCollapsed: true,
      }
    : { displayed: reports, collapsed: [], hasCollapsed: false };
}

function hasCollapsedReports(reports, displayCount) {
  return reports && reports.length > displayCount;
}

export const dashboardComputed = /** @type {DashboardComputed} */ (
  createNamespacedHelpers('dashboard').mapGetters([
    'recentReports',
    'individualReports',
    'subscriptions',
  ])
);

/**
 * @typedef DashboardReportSet
 * @property {ReportResponse[]} displayed
 * @property {ReportResponse[]} collapsed
 * @property {boolean} hasCollapsed

 * @typedef DashboardSubscriptionSet
 * @property {SubscriptionResponse[]} displayed
 * @property {SubscriptionResponse[]} collapsed
 * @property {boolean} hasCollapsed

 * @typedef {import('./state').ReportResponse} ReportResponse
 * @typedef {import('./state').SubscriptionResponse} SubscriptionResponse

 * @typedef DashboardComputed
 * @property {() => DashboardReportSet} individualReports
 * @property {() => DashboardReportSet} recentReports
 * @property {() => DashboardSubscriptionSet} subscriptions
 */
