import { defaultSearchSettings, orderByOptions } from './state';

export const mutationTypes = {
  INITIALIZE_SEARCH_FILTERS: 'INITIALIZE_SEARCH_FILTERS',
  RESET_SEARCH_SETTINGS: 'RESET_SEARCH_SETTINGS',
  SET_CATEGORY_FILTERS: 'SET_CATEGORY_FILTERS',
  UPDATE_CANCELLATION_SOURCE: 'UPDATE_CANCELLATION_SOURCE',
  UPDATE_CATEGORY_GROUP_FILTER: 'UPDATE_CATEGORY_GROUP_FILTER',
  UPDATE_DATE_FILTER: 'UPDATE_DATE_FILTER',
  UPDATE_IS_INITIAL_LOAD: 'UPDATE_IS_INITIAL_LOAD',
  UPDATE_IS_LOADING: 'UPDATE_IS_LOADING',
  UPDATE_ORDER_BY: 'UPDATE_ORDER_BY',
  UPDATE_PAGE_NUMBER: 'UPDATE_PAGE_NUMBER',
  UPDATE_RESULTS: 'UPDATE_RESULTS',
  UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
};

/**
 *
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `search/${mutationType}`;
}

export const mutations = {
  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {import('./state').ResultFilters} searchFilters
   */
  [mutationTypes.INITIALIZE_SEARCH_FILTERS](state, searchFilters) {
    state.searchResults.filters = searchFilters;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {{ categoryIds: number[] }} payload
   */
  [mutationTypes.SET_CATEGORY_FILTERS](state, { categoryIds }) {
    if (!categoryIds.length) {
      return;
    }

    for (const id of categoryIds) {
      for (const key of Object.keys(state.searchResults.filters)) {
        const categoryFilter = state.searchResults.filters[key].find(
          (cf) => cf.id === id
        );

        if (categoryFilter) {
          state.searchSettings.filters[key].push(id);
        }
      }
    }
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {{ categoryId: number, categoryGroup: string }} payload
   */
  [mutationTypes.UPDATE_CATEGORY_GROUP_FILTER](
    state,
    { categoryId, categoryGroup }
  ) {
    const isCategorySelected = state.searchSettings.filters[categoryGroup].some(
      (c) => c === categoryId
    );

    /**
     * Remove the filter from the group if its already applied,
     * otherwise add it (effectively toggling the value)
     */
    if (isCategorySelected) {
      state.searchSettings.filters[categoryGroup] =
        state.searchSettings.filters[categoryGroup].filter(
          (c) => c !== categoryId
        );
    } else {
      state.searchSettings.filters[categoryGroup].push(categoryId);
    }

    state.searchSettings.pageNumber = 1;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {string} term
   */
  [mutationTypes.UPDATE_SEARCH_TERM](state, term) {
    const isOrderedByRelevance =
      state.searchSettings.orderBy === orderByOptions.relevance.value;

    /**
     * If switching from no search term to a search term, set order by to relevance
     */
    if (!state.searchSettings.term && term) {
      state.searchSettings.orderBy = orderByOptions.relevance.value;

      /**
       * If the search term has been cleared and relevance was used,
       * reset the order by since relevance only works with non-empty terms
       */
    } else if (state.searchSettings.term && !term && isOrderedByRelevance) {
      state.searchSettings.orderBy =
        orderByOptions.iirReportPagePublishedDateDesc.value;
    }

    state.searchSettings.term = term;
    state.searchSettings.pageNumber = 1;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {import('./state').DateFilter} dateFilter
   */
  [mutationTypes.UPDATE_DATE_FILTER](state, dateFilter) {
    state.searchSettings.filters.dates = dateFilter;

    state.searchSettings.pageNumber = 1;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {number} pageNumber
   */
  [mutationTypes.UPDATE_PAGE_NUMBER](state, pageNumber) {
    state.searchSettings.pageNumber = pageNumber;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {{
   *   sectors: import('./state').ResultCategoryFilter[],
   *   reportTypes: import('./state').ResultCategoryFilter[],
   *   thematics: import('./state').ResultCategoryFilter[],
   *   companySpecifics: import('./state').ResultCategoryFilter[],
   *   recurringPublications: import('./state').ResultCategoryFilter[],
   *   referenceGuides: import('./state').ResultCategoryFilter[],
   *   proprietarySurveys: import('./state').ResultCategoryFilter[],
   *   results: import('./state').ReportResult[],
   *   isAccessContextVisible: boolean
   *   count: number
   * }} data
   */
  [mutationTypes.UPDATE_RESULTS](state, data) {
    state.searchResults = {
      filters: {
        sectors: data.sectors,
        reportTypes: data.reportTypes,
        thematics: data.thematics,
        companySpecifics: data.companySpecifics,
        recurringPublications: data.recurringPublications,
        referenceGuides: data.referenceGuides,
        proprietarySurveys: data.proprietarySurveys,
      },
      reports: data.results,
      count: data.count,
    };

    state.ui.isAccessContextVisible = data.isAccessContextVisible;

    if (
      state.searchSettings.pageNumber === 1 &&
      state.searchSettings.orderBy === orderByOptions.relevance.value &&
      data.results.length
    ) {
      state.maxRelevance = data.results[0].score;
    } else if (
      state.searchSettings.orderBy !== orderByOptions.relevance.value
    ) {
      state.maxRelevance = 0;
    }
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   */
  [mutationTypes.RESET_SEARCH_SETTINGS](state) {
    state.searchSettings = defaultSearchSettings();
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {import('axios').CancelTokenSource} source
   */
  [mutationTypes.UPDATE_CANCELLATION_SOURCE](state, source) {
    state.cancellationSource = source;
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {boolean} isLoading
   */
  [mutationTypes.UPDATE_IS_LOADING](state, isLoading) {
    state.ui = { ...state.ui, isLoading };
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {boolean} isInitialLoad
   */
  [mutationTypes.UPDATE_IS_INITIAL_LOAD](state, isInitialLoad) {
    state.ui = { ...state.ui, isInitialLoad };
  },

  /**
   *
   * @param {import('./state').ReportSearchStoreState} state
   * @param {string} orderBy
   */
  [mutationTypes.UPDATE_ORDER_BY](state, orderBy) {
    state.searchSettings.orderBy = orderBy;
  },
};
