<template>
  <div v-frag>
    <ListContentLoader
      v-if="isInitialLoading"
      :rows-per-section="7"
      :sections="1"
    />
    <div v-else-if="sectors.length > 0" class="row row-cols-1">
      <EmailPreferenceSector
        v-for="sector in sectors"
        :key="sector.nameEncoded"
        :sector="sector"
      />
    </div>
    <div v-else class="row row-cols-1">
      <h4>
        We were unable to find any email preferences for your account. Please
        <a class="text-secondary" :href="links.contactUsUrl">contact support</a
        >.
      </h4>
    </div>
  </div>
</template>

<script>
import frag from 'vue-frag';

import { store } from '@/src/rootStore';
import ListContentLoader from '@/src/shared/components/ListContentLoader.vue';

import EmailPreferenceSector from './EmailPreferenceSector.vue';
import { emailPreferencesComputed, action } from './store';

export default {
  components: {
    EmailPreferenceSector,
    ListContentLoader,
  },

  directives: {
    frag,
  },

  props: {
    /** @type {import('vue').PropOptions<{ contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  computed: {
    ...emailPreferencesComputed,
  },

  async created() {
    await this.$store.dispatch(action('REQUEST_EMAIL_PREFERENCES'));
  },
};
</script>
