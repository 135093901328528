<template>
  <div
    class="list-group-item list-group-item-action flex-wrap flex-md-nowrap d-flex align-items-start p-3"
  >
    <div class="col m-0 p-0">
      <div class="row">
        <div class="col-9">
          <h5 class="mb-2 small">
            <a
              :href="reportCategoryUrl"
              class="font-weight-bold text-uppercase"
            >
              {{ report.title }}
            </a>
          </h5>
        </div>
        <div class="col-3 text-right bg-transparent">
          <span class="small text-muted d-md-block m-0 p-0">
            {{ reportDate }}
          </span>
        </div>
      </div>
      <h6 v-if="subtitle" class="mb-2">
        <a :href="report.pageLinkUrl">{{ subtitle }}</a>
      </h6>
      <div v-if="isDownloadable" class="mt-2">
        <button
          class="btn btn-orange btn-zelman btn-tiny"
          @click="onDownloadClick"
        >
          Download Now
        </button>
        <Spinner v-if="isLoading" />
        <FormError v-if="isDownloadFailed">
          There was a problem downloading your report. Please try again.
        </FormError>
      </div>
    </div>
  </div>
</template>

<script>
import FormError from '@/src/shared/components/FormError.vue';
import Spinner from '@/src/shared/components/Spinner.vue';
import { formatDate } from '@/src/shared/formatters';
import { reportSearchUrlDeepLink } from '@/src/shared/links';
import { downloadReport } from '@/src/shared/report-utilities';

export default {
  name: 'ReportListItem',

  components: {
    FormError,
    Spinner,
  },

  props: {
    /** @type {import('vue').PropOptions<ReportResponse>} */
    report: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<boolean>} */
    showCategoryLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    /** @type {import('vue').PropOptions<boolean>} */
    isDownloadable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isDownloadFailed: false,
      isLoading: false,
    };
  },

  computed: {
    /** @returns {string} */
    subtitle() {
      return this.report.subtitle || '';
    },

    /** @returns {string} */
    reportDate() {
      return formatDate(this.report.publishedDate);
    },

    /** @returns {string} */
    reportCategoryUrl() {
      return reportSearchUrlDeepLink(this.links.reportSearchUrl, [
        this.report.categoryId,
      ]);
    },
  },
  methods: {
    async onDownloadClick() {
      this.isLoading = true;

      const result = await downloadReport(this.report.nodeGuid);

      this.isLoading = false;

      this.isDownloadFailed = result.isFailure;
    },
  },
};

/** @typedef {import('./store/state').ReportResponse} ReportResponse */
</script>
