<template>
  <div>
    <ReportSearchAppliedFilters />

    <div class="row mb-3">
      <div class="col justify-content-between d-flex">
        <div>
          {{ searchResults.count }}
          <span v-if="searchResults.count === 1">Report</span>
          <span v-else>Reports</span>
        </div>
        <div class="col text-right p-0 m-0">
          Order By:
          <div
            v-for="orderByOption in orderByOptions"
            v-show="showOption(orderByOption)"
            :key="orderByOption.value"
            class="custom-control custom-radio custom-control-inline"
          >
            <input
              :id="orderByOption.value"
              v-model="orderByValue"
              class="custom-control-input"
              type="radio"
              name="inlineRadioOptions"
              :value="orderByOption.value"
              :aria-label="orderByOption.name"
              @change="onOrderByChange(orderByOption)"
            />
            <label class="custom-control-label" :for="orderByOption.value">
              {{ orderByOption.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <section class="results">
      <div class="list-group w-100 mb-3 shadow">
        <ReportSearchResult
          v-for="report in searchResults.reports"
          :key="report.id"
          :report="report"
        />
      </div>
      <nav class="justify-content-end">
        <BPagination
          v-if="pageNumber"
          :value="pageNumber"
          :total-rows="searchResults.count"
          :per-page="10"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          align="center"
          class="flex-wrap"
          @change="onPageChange"
        />
      </nav>
    </section>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';

import ReportSearchAppliedFilters from './ReportSearchAppliedFilters.vue';
import ReportSearchResult from './ReportSearchResult.vue';
import { action, reportSearchComputed, mutation } from './store';
import { orderByOptions } from './store/state';

export default {
  name: 'ReportSearchResults',

  components: {
    ReportSearchAppliedFilters,
    BPagination,
    ReportSearchResult,
  },

  data() {
    return {
      // @ts-ignore
      orderByValue: this.orderBy,
      orderByOptions,
    };
  },

  computed: {
    ...reportSearchComputed,
  },

  watch: {
    orderBy: {
      immediate: true,
      handler(newVal) {
        this.orderByValue = newVal;
      },
    },
  },

  methods: {
    /**
     * @returns {boolean}
     */
    showOption(orderByOption) {
      if (orderByOption.value !== orderByOptions.relevance.value) {
        return true;
      }

      return !!this.searchTerm;
    },

    /**
     * @returns {boolean}
     */
    isSelected(orderByOption) {
      return this.orderBy === orderByOption.value;
    },

    async onOrderByChange(orderByOption) {
      this.$store.commit(mutation('UPDATE_ORDER_BY'), orderByOption.value);

      await this.$store.dispatch(action('SEARCH'));
    },

    async onPageChange(pageNumber) {
      this.$store.commit(mutation('UPDATE_PAGE_NUMBER'), pageNumber);

      await this.$store.dispatch(action('SEARCH'));
    },
  },
};
</script>

<style lang="scss">
.highlight {
  font-weight: 700;
}
</style>
