import {
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons';

/**
 * Returns the font awesome icon definition that is used with the
 * <font-awesome-icon> vue component :icon="" property based
 * on the brand string
 * @param {string} brand brand name of the card
 */
export function getCardIcon(brand) {
  switch (brand.toLowerCase()) {
    case 'visa':
      return faCcVisa;

    case 'discover':
      return faCcDiscover;

    case 'mastercard':
      return faCcMastercard;

    default:
      return faCreditCardFront;
  }
}
