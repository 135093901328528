import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import Login from './Login.vue';

export function load() {
  const selector = 'zel-login';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(Login, selector);
}
