<template>
  <div
    class="list-group-item list-group-item-action flex-wrap flex-md-nowrap d-flex align-items-start p-sm-3 p-md-4"
  >
    <div
      class="col m-0 p-0 order-2 order-md-1 d-flex justify-content-between align-items-center"
    >
      <h6 class="mb-0">
        <a :href="reportCategoryUrl">
          {{ subscription.name }}
        </a>
      </h6>
      <a :href="reportCategoryUrl" class="uppercase btn btn-primary btn-zelman">
        Access Now
      </a>
    </div>
  </div>
</template>

<script>
import { reportSearchUrlDeepLink } from '@/src/shared/links';

export default {
  name: 'SubscriptionListItem',

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string, accountDashboardUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ name: string, categoryId: number }>} */
    subscription: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /** @returns {string} */
    reportCategoryUrl() {
      return reportSearchUrlDeepLink(this.links.reportSearchUrl, [
        this.subscription.categoryId,
      ]);
    },
  },
};
</script>
