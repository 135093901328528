import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import ShoppingCartIcon from './ShoppingCartIcon.vue';

export function load() {
  const selector = 'zel-shopping-cart-icon';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(ShoppingCartIcon, selector);
}
