<template>
  <div>
    <div class="col-12 py-3 mb-3 bg-light-2 rounded">
      <div class="address">
        {{ cart.billingAddress.billingName }}
        <br />
        {{ cart.billingAddress.line1 }}
        {{ cart.billingAddress.line2 }}
        <br />
        {{ cart.billingAddress.city }},
        {{ state }}
        {{ cart.billingAddress.postalCode }}
        <br />
        {{ country }}
      </div>
    </div>
  </div>
</template>

<script>
import { checkoutComputed } from '../store';

export default {
  name: 'BillingAddress',

  computed: {
    ...checkoutComputed,

    /** @returns {string} */
    state() {
      const state = this.settings.states.find(
        (s) => s.id === this.cart.billingAddress.stateId
      ) || { id: 0, countryId: 0, name: '' };
      return state.name;
    },

    /** @returns {string} */
    country() {
      const country = this.settings.countries.find(
        (s) => s.id === this.cart.billingAddress.countryId
      ) || { id: 0, countryId: 0, name: '' };
      return country.name;
    },
  },
};
</script>
