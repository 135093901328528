<template>
  <div>
    <form
      v-if="!(uiState.hasSubmitted && uiState.isSubmissionSuccessful)"
      @submit.prevent="onSubmit"
    >
      <PasswordForm @update="onPasswordFormUpdate" />
      <div class="form-group">
        <button
          :disabled="!isFormValid || uiState.isLoading"
          type="submit"
          class="btn btn-primary btn-zelman btn-block"
        >
          Set Password
        </button>
      </div>
    </form>
    <Spinner v-if="uiState.isLoading" class="align-self-center" />
    <div v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful">
      <p class="text-white bg-danger rounded py-2 px-3 mb-0">
        <FontAwesomeIcon :icon="icons.faExclamationCircle" />
        There was an issue finishing your registration, please
        <a :href="links.contactUsUrl">contact support</a>.
      </p>
    </div>

    <div v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful">
      <p class="py-2 px-3 rounded bg-success text-white mb-0">
        <FontAwesomeIcon :icon="icons.faCheckCircle" />
        Your password has been set and you can now
        <a :href="links.loginUrl">login</a>.
      </p>
    </div>
  </div>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

import PasswordForm from '@/src/shared/components/PasswordForm.vue';
import Spinner from '@/src/shared/components/Spinner.vue';
import { createResult } from '@/src/shared/result';

export default {
  name: 'SetPassword',

  components: {
    FontAwesomeIcon,
    Spinner,
    PasswordForm,
  },

  props: {
    /** @type {import('vue').PropOptions<string>} */
    token: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ contactUsUrl: string, loginUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    userGuid: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    apiUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      uiState: {
        isLoading: false,
        hasSubmitted: false,
        isSubmissionSuccessful: false,
      },
      isFormValid: false,
      password: '',
    };
  },

  computed: {
    icons() {
      return { faExclamationCircle, faCheckCircle };
    },
  },

  methods: {
    onPasswordFormUpdate({ isValid, password }) {
      this.password = password;
      this.isFormValid = isValid;
    },

    async onSubmit() {
      if (!this.isFormValid) {
        return;
      }

      this.uiState.isLoading = true;
      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;

      const result = await createResult(
        axios.post(this.apiUrl, {
          password: this.password,
          userGuid: this.userGuid,
          token: this.token,
        })
      );

      this.uiState.hasSubmitted = true;
      this.uiState.isLoading = false;

      if (result.isFailure) {
        this.uiState.isSubmissionSuccessful = false;

        return;
      }

      this.uiState.isSubmissionSuccessful = true;
    },
  },
};
</script>
