<template>
  <div class="row">
    <div class="col-12">
      <PaymentForm
        v-show="!cart.paymentOptions.length || isNewCard"
        class="mb-5"
        @cancel="isNewCard = false"
      />

      <div v-if="cart.paymentOptions.length && !isNewCard" class="row">
        <div class="col-12 col-lg-6 mb-3 mb-lg-0">
          <h5>Payment Options</h5>

          <PaymentList @create="isNewCard = true" />
        </div>

        <div class="col-12 col-lg-6">
          <h5>Billing Address</h5>

          <BillingAddressForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkoutComputed } from '../store';

import BillingAddressForm from './BillingAddressForm.vue';
import PaymentForm from './PaymentForm.vue';
import PaymentList from './PaymentList.vue';

export default {
  name: 'Payment',

  components: {
    BillingAddressForm,
    PaymentForm,
    PaymentList,
  },

  data() {
    return {
      isNewCard: false,
    };
  },

  computed: {
    ...checkoutComputed,
  },
};
</script>
