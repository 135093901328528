<template>
  <div v-if="isAccessValidated">
    <ReportAddToCart
      v-if="isAddToCartVisible"
      :product="product"
      :links="config.links"
      price-text="Annual Price"
    />

    <a
      v-else-if="hasAccess"
      :href="link"
      class="btn btn-orange btn-zelman btn-tiny"
    >
      View this Series
    </a>
  </div>
</template>

<script>
import { store } from '@/src/rootStore';
import { rootComputed, USER_TIERS } from '@/src/rootStore/user';
import { reportSearchUrlDeepLink } from '@/src/shared/links';

import ReportAddToCart from './ReportAddToCart.vue';
import { reportDetailComputed } from './store';
import {
  findProduct,
  hasAccessToProduct,
  isProductForSale,
} from './store/getters';

/** @typedef {import('./store/state').ReportSubscriptionConfig} ReportSubscriptionConfig */

export default {
  name: 'ReportSubscriptionCart',

  components: {
    ReportAddToCart,
  },

  props: {
    /** @type {import('vue').PropOptions<ReportSubscriptionConfig>} */
    config: {
      type: Object,
      required: true,
    },
  },

  store,

  computed: {
    ...reportDetailComputed,
    ...rootComputed,

    /**
     * @returns {import('./store/state').ReportAccess}
     */
    product() {
      return findProduct(this.access, {
        skuId: this.config.skuId,
        skuPrice: this.config.skuPrice,
        nodeGuid: this.config.nodeGuid,
      });
    },

    /** @returns {boolean} */
    isAddToCartVisible() {
      return (
        isProductForSale(
          this.access,
          this.isAuthenticated,
          this.config.nodeGuid
        ) && this.userTier !== USER_TIERS.FullAccess
      );
    },

    /** @returns {boolean} */
    hasAccess() {
      return hasAccessToProduct(this.access, this.config.nodeGuid);
    },

    /**
     * @returns {string}
     */
    link() {
      return reportSearchUrlDeepLink(this.config.links.reportSearchUrl, [
        this.config.categoryId,
      ]);
    },
  },
};
</script>
