import { actions, action } from './actions';
import { getters, emailPreferencesComputed } from './getters';
import { mutations, mutation } from './mutations';
import { state } from './state';

/**
 * @type {import('vuex').Module<EmailPreferencesState>}
 */
const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { action, emailPreferencesComputed, mutation, store };

/**
 * @typedef {import('./state').EmailPreferencesState} EmailPreferencesState
 */
