import axios from 'axios';

import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';

export const actionTypes = {
  VALIDATE_ACCESS: 'VALIDATE_ACCESS',
};

/**
 *
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `videoDetail/${actionType}`;
}

export const actions = {
  /**
   *
   * @param {{
   *   commit: (mutation: string, payload: any ) => void,
   * }} context
   * @param {{ nodeGuid: string }} payload
   */
  async [actionTypes.VALIDATE_ACCESS]({ commit }, { nodeGuid }) {
    const result = await createResult(
      axios.get(`/api/video/access/${nodeGuid}`).then((resp) => resp.data)
    );

    if (result.isFailure) {
      commit(mutationTypes.UPDATE_IS_ACCESS_VALIDATED, false);
      commit(mutationTypes.UPDATE_ACCESS, {
        hasAccess: false,
        embedCodeHtml: '',
      });
      commit(mutationTypes.UPDATE_IS_LOADING_ACCESS, false);

      return;
    }

    commit(mutationTypes.UPDATE_IS_ACCESS_VALIDATED, true);
    commit(mutationTypes.UPDATE_ACCESS, result.value);
  },
};
