import axios from 'axios';

import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';
import { STEP_NAMES } from './state';

/**
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `checkout/${actionType}`;
}

export const actionTypes = {
  APPLY_DISCOUNT_CODE: 'APPLY_DISCOUNT_CODE',
  CHANGE_STEP: 'CHANGE_STEP',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  REMOVE_DISCOUNT_CODE: 'REMOVE_DISCOUNT_CODE',
  REQUEST_CART: 'REQUEST_CART',
  REQUEST_SETTINGS: 'REQUEST_SETTINGS',
  SET_BILLING_ADDRESS: 'SET_BILLING_ADDRESS',
  SET_PAYMENT_OPTION: 'SET_PAYMENT_OPTION',
};

export const actions = {
  async [actionTypes.REQUEST_SETTINGS]({ commit }) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const result = await toggleError(
      createResult(
        axios.get('/api/checkout/settings').then((resp) => resp.data)
      ),
      commit
    );

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    if (result.isFailure) {
      return result;
    }

    commit(mutationTypes.UPDATE_SETTINGS, result.value);
  },

  async [actionTypes.REQUEST_CART](
    { commit },
    { toggleLoading } = { toggleLoading: false }
  ) {
    if (toggleLoading) {
      commit(mutationTypes.UPDATE_IS_LOADING, true);
    }

    const result = await toggleError(
      createResult(axios.get('/api/checkout/cart').then((resp) => resp.data)),
      commit
    );

    if (result.isSuccess) {
      commit(mutationTypes.UPDATE_CART, result.value);
    }

    if (toggleLoading) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);
    }

    return result;
  },

  async [actionTypes.CHANGE_STEP]({ commit }, step) {
    if (step.name === STEP_NAMES.complete) {
      commit(mutationTypes.UPDATE_IS_LOADING, true);

      const result = await toggleError(
        createResult(
          axios.put('/api/checkout/complete').then((resp) => resp.data)
        ),
        commit
      );

      commit(mutationTypes.UPDATE_IS_LOADING, false);

      if (result.isSuccess) {
        location.href = `/checkout/thank-you?orderId=${result.value.orderGuid}`;
      }

      return result;
    }

    commit(mutationTypes.UPDATE_CURRENT_STEP, step);

    return createResult(Promise.resolve());
  },

  async [actionTypes.REMOVE_CART_ITEM]({ commit, dispatch }, cartItem) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const deleteResult = await toggleError(
      createResult(axios.delete(`/api/checkout/cart/item/${cartItem.id}`)),
      commit
    );

    if (deleteResult.isFailure) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);

      return deleteResult;
    }

    const requestResult = await dispatch('REQUEST_CART');

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    return requestResult;
  },

  async [actionTypes.APPLY_DISCOUNT_CODE]({ commit, dispatch }, discountCode) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const addResult = await toggleError(
      createResult(
        axios
          .post('/api/checkout/cart/discount', { discountCode })
          .then((resp) => resp.data)
      ),
      commit
    );

    if (addResult.isFailure) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);

      return addResult;
    }

    await dispatch('REQUEST_CART');

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    return addResult;
  },

  async [actionTypes.REMOVE_DISCOUNT_CODE]({ commit, dispatch }, discountCode) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const deleteResult = await toggleError(
      createResult(axios.delete(`/api/checkout/cart/discount/${discountCode}`)),
      commit
    );

    if (deleteResult.isFailure) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);

      return deleteResult;
    }

    await dispatch('REQUEST_CART');

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    return deleteResult;
  },

  async [actionTypes.SET_PAYMENT_OPTION]({ commit, dispatch }, token) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const updateResult = await toggleError(
      createResult(
        axios
          .put('/api/checkout/cart/payment', { token })
          .then((resp) => resp.data)
      ),
      commit
    );

    if (updateResult.isFailure) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);

      return updateResult;
    }

    const requestResult = await dispatch('REQUEST_CART');

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    return requestResult;
  },

  async [actionTypes.SET_BILLING_ADDRESS](
    { commit, dispatch, getters },
    billingAddress
  ) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const paymentToken = getters.cart.paymentToken;

    const payload = { ...billingAddress, paymentToken };

    const request = billingAddress.id
      ? axios.put('/api/checkout/cart/address', payload)
      : axios.post('/api/checkout/cart/address', payload);

    const updateResult = await toggleError(
      createResult(request.then((resp) => resp.data)),
      commit
    );

    if (updateResult.isFailure) {
      commit(mutationTypes.UPDATE_IS_LOADING, false);

      return updateResult;
    }

    const requestResult = await dispatch('REQUEST_CART');

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    return requestResult;
  },
};

async function toggleError(promise, commit) {
  const result = await promise;

  if (result.isFailure) {
    commit(mutationTypes.UPDATE_CART_ERROR, true);
  } else {
    commit(mutationTypes.UPDATE_CART_ERROR, false);
  }

  return result;
}
