<template>
  <div class="col-12 py-3 mb-3 bg-light-2 rounded">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <strong class="mr-3">Promotional Codes</strong>
            <button
              v-for="discount in cart.discounts"
              :key="discount"
              class="badge badge-pill badge-primary border-0 mr-2"
              type="button"
              @click="onDiscountCodeRemove(discount)"
            >
              {{ discount }}
            </button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <form class="form-inline" @submit.prevent="onDiscountCodeAdd">
              <div class="form-group">
                <label for="discountCode" class="sr-only"
                  >Promotional Codes</label
                >
                <input
                  id="discountCode"
                  v-model="discountCode"
                  :disabled="isLoading"
                  name="discountCode"
                  type="text"
                  class="form-control"
                  placeholder="Promotional Code"
                />
              </div>
              <button
                type="submit"
                :disabled="isLoading"
                class="btn btn-sm btn-secondary btn-zelman ml-2 text-uppercase"
              >
                Add
              </button>
            </form>
          </div>
        </div>
        <div v-show="isDiscountInvalid" class="row mt-3">
          <div class="col-12 text-danger text-right">
            Discount code <strong>{{ discountCode }}</strong> could not be
            applied.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { action, checkoutComputed } from '../store';

export default {
  name: 'Discounts',

  data() {
    return {
      discountCode: '',
      isDiscountInvalid: false,
    };
  },

  computed: {
    ...checkoutComputed,
  },

  watch: {
    discountCode(newVal, oldVal) {
      // Clear isDiscountInvalid if the user changes the discount code input
      if (newVal !== oldVal && this.isDiscountInvalid) {
        this.isDiscountInvalid = false;
      }
    },
  },

  methods: {
    async onDiscountCodeAdd() {
      const result = await this.$store.dispatch(
        action('APPLY_DISCOUNT_CODE'),
        this.discountCode
      );

      if (result.isFailure) {
        return;
      }

      if (result.value.isValid) {
        this.discountCode = '';
      } else {
        this.isDiscountInvalid = true;
      }
    },

    async onDiscountCodeRemove(discountCode) {
      await this.$store.dispatch(action('REMOVE_DISCOUNT_CODE'), discountCode);
    },
  },
};
</script>
