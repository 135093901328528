<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p>
          To view additional report information, please Login or Request Access.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a class="btn btn-orange btn-zelman" :href="loginUrl">Login</a>

        <a
          class="btn btn-orange btn-zelman ml-3"
          :href="links.accessRequestUrl"
        >
          Request Access
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportUnauthenticatedCTA',

  props: {
    /** @type {import('vue').PropOptions<any>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /**
     * @returns {string}
     */
    loginUrl() {
      const path = window.location.pathname;

      return `${this.links.loginUrl}?ReturnUrl=${encodeURIComponent(path)}`;
    },
  },
};
</script>
