<template>
  <section class="bg-light-2 py-3 py-md-5" :class="{ 'd-none': isHidden }">
    <div class="container py-2">
      <h3 class="text-center text-decor pb-3 mb-4">Subscription Details</h3>
      <div class="row">
        <div class="col">
          <div v-if="!subscriptions.length">
            <p>You have no subscriptions</p>
          </div>
          <div v-else>
            <SubscriptionListItem
              v-for="subscription in subscriptions"
              :key="subscription.id"
              :subscription="subscription"
              :links="links"
              @update="onSubscriptionUpdate($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { USER_TIERS } from '@/src/rootStore/user/state';
import { createResult } from '@/src/shared/result';

import SubscriptionListItem from './SubscriptionListItem.vue';

export default {
  name: 'SubscriptionList',

  components: {
    SubscriptionListItem,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      /** @type {{ id: string }[]} */
      subscriptions: [],
      isUpdateSuccessful: true,
    };
  },

  computed: {
    userTier: rootComputed.userTier,

    /** @returns {boolean} */
    isHidden() {
      return (
        this.userTier === USER_TIERS.FullAccess ||
        this.userTier === USER_TIERS.None
      );
    },
  },

  watch: {
    isHidden: {
      immediate: true,
      handler(isHidden) {
        if (!isHidden) {
          this.requestSubscriptions();
        }
      },
    },
  },

  methods: {
    onSubscriptionUpdate(subscription) {
      this.subscriptions = this.subscriptions.map((s) =>
        s.id === subscription.id ? { ...subscription } : s
      );
    },

    async requestSubscriptions() {
      const result = await createResult(
        axios.get('/api/account/detail/subscription').then((resp) => resp.data)
      );

      if (result.isFailure) {
        return;
      }

      this.subscriptions = result.value;
    },
  },
};
</script>
