export const mutationTypes = {
  UPDATE_IS_ACCESS_VALIDATED: 'UPDATE_IS_ACCESS_VALIDATED',
  UPDATE_ACCESS: 'UPDATE_ACCESS',
};

/**
 *
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `reportDetail/${mutationType}`;
}

export const mutations = {
  /**
   *
   * @param {import('./state').VideoDetailState} state
   * @param {Boolean} isAccessValidated
   */
  [mutationTypes.UPDATE_IS_ACCESS_VALIDATED](state, isAccessValidated) {
    state.isAccessValidated = isAccessValidated;
  },

  /**
   *
   * @param {import('./state').VideoDetailState} state
   * @param {{ hasAccess: boolean, embedCodeHTML: string }} hasAccess
   */
  [mutationTypes.UPDATE_ACCESS](state, { hasAccess, embedCodeHTML }) {
    state.hasAccess = hasAccess;
    state.embedCodeHTML = embedCodeHTML;
  },
};
