<template>
  <div v-frag>
    <li class="nav-item">
      <a
        :href="links.membershipUrl"
        class="nav-link btn-link-orange bg-orange text-white"
      >
        Request Access
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link btn-link-primary bg-primary text-white"
        :href="loginLink"
      >
        Login
      </a>
    </li>
  </div>
</template>

<script>
import frag from 'vue-frag';

import { getLoginRedirectURL } from '@/src/shared/links';

export default {
  name: 'UnAuthenticatedHeader',

  directives: { frag },

  props: {
    /** @type {import('vue').PropOptions<{ loginUrl: string, membershipUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /** @returns {string} */
    loginLink() {
      return getLoginRedirectURL(this.links.loginUrl);
    },
  },
};
</script>
