export const mutationTypes = {
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_CART_ERROR: 'UPDATE_CART_ERROR',
  UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP',
  UPDATE_IS_LOADING: 'UPDATE_IS_LOADING',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_STRIPE_API_KEY: 'UPDATE_STRIPE_API_KEY',
};

/**
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `checkout/${mutationType}`;
}

export const mutations = {
  /**
   * @param {import('./state').CheckoutState} state
   * @param {{
   *  states: import('./state').StateOption[],
   *  countries: import('./state').CountryOption[]
   * }} settings
   */
  [mutationTypes.UPDATE_SETTINGS](state, settings) {
    state.settings.countries = settings.countries;
    state.settings.states = settings.states;
  },

  /**
   * @param {import('./state').CheckoutState} state
   * @param {import('./state').StepState} step
   */
  [mutationTypes.UPDATE_CURRENT_STEP](state, step) {
    state.ui = {
      ...state.ui,
      currentStep: step,
    };
  },

  /**
   * @param {import('./state').CheckoutState} state
   * @param {import('./state').Cart} cart
   */
  [mutationTypes.UPDATE_CART](state, cart) {
    state.cart = cart;
  },

  /**
   * @param {import('./state').CheckoutState} state
   * @param {boolean} isLoading
   */
  [mutationTypes.UPDATE_IS_LOADING](state, isLoading) {
    state.ui.isLoading = isLoading;
  },

  /**
   * @param {import('./state').CheckoutState} state
   * @param {string} stripeSpk
   */
  [mutationTypes.UPDATE_STRIPE_API_KEY](state, stripeSpk) {
    state.settings.stripeSpk = stripeSpk;
  },

  /**
   * @param {import('./state').CheckoutState} state
   * @param {boolean} hasError
   */
  [mutationTypes.UPDATE_CART_ERROR](state, hasError) {
    if (state.ui.hasError) {
      return;
    }

    state.ui.hasError = hasError;
  },
};
