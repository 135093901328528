<template>
  <div>
    <div class="row no-gutters col-12 py-3 mb-3 bg-light-2 rounded">
      <div class="col-8">
        <span class="pr-2">
          <FontAwesomeIcon :icon="icon" />
        </span>
        <span>
          {{ selectedPaymentOption.number }}
        </span>
      </div>
      <div class="col-4">
        {{ selectedPaymentOption.expiration }}
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { getCardIcon } from '@/src/shared/ecommerce-utilities';

import { checkoutComputed } from '../store';

export default {
  name: 'Payment',

  components: {
    FontAwesomeIcon,
  },

  computed: {
    selectedPaymentOption: checkoutComputed.selectedPaymentOption,

    icon() {
      return getCardIcon(this.selectedPaymentOption.brand);
    },
  },
};
</script>
