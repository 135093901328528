/**
 * @type {EmailPreferencesState}
 */
export const state = {
  sectors: [],
  loadingState: 'INITIAL_LOADING',
};

/**
 * @typedef EmailPreference
 * @property {boolean} hasAccess
 * @property {string} sector
 * @property {string} name
 * @property {string} reportAccessId
 * @property {string} reportTypeId
 * @property {boolean} isSubscribed
 * @property {boolean} isCompanySpecific

 * @typedef EmailPreferenceSector
 * @property {string} name
 * @property {string} nameEncoded
 * @property {EmailPreference[]} items

 * @typedef EmailPreferenceSectorResponse
 * @property {string} name
 * @property {EmailPreference[]} preferences

 * @typedef EmailPreferencesResponse
 * @property {EmailPreferenceSectorResponse[]} sectors

 * @typedef {'INITIAL_LOADING' | 'LOADED' | 'LOADING'} LoadingState

 * @typedef EmailPreferencesState
 *
 * @property {EmailPreferenceSector[]} sectors
 * @property {LoadingState} loadingState
 */
