<template>
  <section class="bg-light-2">
    <div class="container py-3 py-md-4">
      <div class="row">
        <div class="col">
          <form @submit.stop.prevent="() => {}">
            <input
              v-model="search"
              type="text"
              class="form-control w-100"
              :placeholder="placeholder"
              @input="onSearchTermChange"
            />

            <Spinner v-show="isLoading" class="position-absolute spinner" />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce';

import Spinner from '@/src/shared/components/Spinner.vue';

import { action, reportSearchComputed, mutation } from './store';

export default {
  name: 'ReportSearchBox',

  components: {
    Spinner,
  },

  data() {
    return {
      search: '',
      placeholder: 'Search over 10,000 reports',
    };
  },

  computed: {
    ...reportSearchComputed,
  },

  watch: {
    searchTerm(value) {
      this.search = value;
    },
  },

  methods: {
    onSearchTermChange: debounce(async function () {
      this.$store.commit(mutation('UPDATE_SEARCH_TERM'), this.search);

      await this.$store.dispatch(action('SEARCH'), this.search);
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';
@import '@/src/shared/styles/bootstrap';

.spinner {
  top: 0px;
  right: 10px;
}
</style>
