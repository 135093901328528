<template>
  <div
    class="col mt-3 mt-md-0 px-3 px-md-4 px-lg-5 py-4 py-md-4 py-lg-5"
    :class="{ 'd-none': isHidden }"
  >
    <h5 class="text-left text-decor mb-3 pb-2">Your Individual Reports</h5>
    <ReportListItem
      v-for="report in individualReports.displayed"
      :key="report.nodeGuid"
      :report="report"
      :links="links"
      :show-category-link="true"
    />

    <div
      v-if="individualReports.hasCollapsed"
      id="collapseIndividualReports"
      class="collapse"
    >
      <ReportListItem
        v-for="report in individualReports.collapsed"
        :key="report.nodeGuid"
        :report="report"
        :links="links"
        :show-category-link="true"
      />
    </div>

    <button
      v-if="individualReports.hasCollapsed"
      class="btn btn-orange btn-zelman btn-tiny mt-4"
      type="button"
      data-toggle="collapse"
      data-target="#collapseIndividualReports"
      aria-expanded="false"
      aria-controls="collapseIndividualReports"
    ></button>
  </div>
</template>

<script>
import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { USER_TIERS } from '@/src/rootStore/user/state';

import ReportListItem from './ReportListItem.vue';
import { action, dashboardComputed } from './store';

export default {
  name: 'IndividualReportsList',

  components: {
    ReportListItem,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<{ reportSearchUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...dashboardComputed,
    userTier: rootComputed.userTier,

    /** @returns {boolean} */
    isHidden() {
      return (
        this.userTier !== USER_TIERS.SubscriberAndIndividualReports &&
        this.userTier !== USER_TIERS.IndividualReports
      );
    },
  },

  watch: {
    isHidden: {
      immediate: true,
      handler(isHidden) {
        if (!isHidden) {
          this.$store.dispatch(action('REQUEST_INDIVIDUAL_REPORTS'));
        }
      },
    },
  },
};
</script>
