import { actions, action } from './actions';
import { getters, rootComputed } from './getters';
import { mutations, mutation } from './mutations';
import { state, USER_TIERS } from './state';

/**
 * @type {import('vuex').Module<import('./state').UserState>}
 */
export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { USER_TIERS, rootComputed, action, mutation };
