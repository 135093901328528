/**
 * @typedef SuccessResult
 * @property {'success'} type
 * @property {true} isSuccess
 * @property {false} isFailure
 * @property {T} value
 * @template T
 */

/**
 * @typedef FailureResult
 * @property {'failure'} type
 * @property {false} isSuccess
 * @property {true} isFailure
 * @property {T} error
 * @template T
 */

/**
 * Wraps a promise in a Result type
 * @param {Promise<T>} promise
 * @template T
 * @template E
 * @returns {Promise<SuccessResult<T> | FailureResult<E>>}
 */
export const createResult = (promise) =>
  promise
    .then((value) => {
      /** @type {SuccessResult<T>} */
      const success = {
        type: 'success',
        value,
        isSuccess: true,
        isFailure: false,
      };

      return success;
    })
    .catch((error) => {
      /** @type {FailureResult<E>} */
      const failure = {
        type: 'failure',
        isSuccess: false,
        isFailure: true,
        error,
      };

      return failure;
    });

/**
 * Wraps a value in a Result
 * @param {T} value
 * @template T
 * @returns {SuccessResult<T>}
 */
export function succeed(value) {
  /** @type {SuccessResult<T>} */
  const success = {
    type: 'success',
    isSuccess: true,
    isFailure: false,
    value,
  };

  return success;
}

/**
 * @param {E} error
 * @template E
 * @returns {FailureResult<E>}
 */
export function fail(error) {
  /** @type {FailureResult<E>} */
  const failure = {
    type: 'failure',
    isSuccess: false,
    isFailure: true,
    error,
  };

  return failure;
}
