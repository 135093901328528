const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const usNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

/** @returns {string} */
export function formatCurrency(value) {
  if (typeof value !== 'number') {
    return '';
  }

  return usdFormatter.format(value);
}

/** @returns {string} */
export function formatDate(value) {
  return new Date(value).toLocaleDateString();
}

/** @returns {string} */
export function formatNumber(value) {
  return usNumberFormatter.format(value);
}
