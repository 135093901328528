import axios from 'axios';
import * as FileSaver from 'file-saver';

import { createResult } from './result';

export async function downloadReport(nodeGuid) {
  return createResult(
    axios
      .get(`/api/report/access/${nodeGuid}/attachment`, {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      })
      .then((resp) => {
        const filename = getFilenameFromHeader(resp.headers);

        // @ts-ignore
        FileSaver.saveAs(new Blob([resp.data]), filename, {
          type: 'application/pdf;charset=utf-8',
        });

        return filename;
      })
  );
}

function getFilenameFromHeader(headers) {
  const contentDispositionHeader = headers['content-disposition'] || '';

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDispositionHeader);

  if (!!matches && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }

  return 'report';
}
