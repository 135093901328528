import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import AccessRequestForm from './AccessRequestForm.vue';

export function load() {
  const selector = 'zel-access-request-form';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(AccessRequestForm, selector);
}
