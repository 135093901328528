import Vue from 'vue';

import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import ReportDetailCTA from './ReportDetailCTA.vue';
import ReportSubscriptionCTA from './ReportSubscriptionCTA.vue';

export function load() {
  const reportDetailSelector = 'zel-report-detail-cta';
  const subscriptionSelector = 'zel-report-subscription-cta';

  if (!isVueAppInDOM(reportDetailSelector)) {
    return;
  }

  renderApp(ReportDetailCTA, reportDetailSelector);

  const subscriptionCTAs = Array.from(
    document.querySelectorAll(subscriptionSelector)
  );

  for (const component of subscriptionCTAs) {
    const nodeGuid = component.attributes['node-guid'].value;
    const categoryId = +component.attributes['category-id'].value;
    const skuId = +component.attributes['sku-id'].value;
    const skuPrice = +component.attributes['sku-price'].value;
    const links = {
      reportSearchUrl: window.navLookup.reportSearchUrl,
      checkoutUrl: window.navLookup.checkoutUrl,
    };

    const config = { nodeGuid, categoryId, skuId, skuPrice, links };

    new Vue({
      render: (create) =>
        create(ReportSubscriptionCTA, {
          props: {
            config,
          },
        }),
    }).$mount(subscriptionSelector);
  }
}
