<template>
  <div class="row">
    <div class="col-12">
      <ul class="list-group">
        <button
          type="button"
          class="list-group-item list-group-item-action"
          @click="$emit('create')"
        >
          Add New Card
        </button>
        <button
          v-for="option in cart.paymentOptions"
          :key="option.id"
          :class="{
            'list-group-item-secondary': selectedPaymentOption === option,
          }"
          type="button"
          class="list-group-item list-group-item-action"
          @click="onClickPaymentOption(option)"
        >
          <FontAwesomeIcon :icon="getCardIcon(option.brand)" />
          {{ option.number }} {{ option.expiration }}
        </button>
      </ul>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { getCardIcon } from '@/src/shared/ecommerce-utilities';

import { action, checkoutComputed } from '../store';

export default {
  name: 'PaymentList',

  components: {
    FontAwesomeIcon,
  },

  computed: {
    ...checkoutComputed,
  },

  methods: {
    getCardIcon,

    async onClickPaymentOption(option) {
      await this.$store.dispatch(action('SET_PAYMENT_OPTION'), option.id);
    },
  },
};
</script>
