import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {VideoDetailState} state
   */
  hasAccess: ({ hasAccess }) => hasAccess,

  /**
   * @param {VideoDetailState} state
   */
  isAccessValidated: ({ isAccessValidated }) => isAccessValidated,

  /**
   * @param {VideoDetailState} state
   */
  embedCodeHTML: ({ embedCodeHTML }) => embedCodeHTML,
};

export const videoDetailComputed = /** @type {VideoDetailComputed} */ (
  createNamespacedHelpers('videoDetail').mapGetters([
    'hasAccess',
    'isAccessValidated',
    'embedCodeHTML',
  ])
);

/**
 * @typedef VideoDetailComputed
 * @property {() => boolean} hasAccess
 * @property {() => boolean} isAccessValidated
 * @property {() => string} embedCodeHTML
 *
 * @typedef {import('./state').VideoDetailState} VideoDetailState
 */
