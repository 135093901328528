<template>
  <div>
    <div class="discounts">
      <div
        v-for="discount in cart.discounts"
        :key="discount"
        class="badge badge-pill badge-primary border-0 mr-2"
      >
        {{ discount }}
      </div>
    </div>
  </div>
</template>

<script>
import { checkoutComputed } from '../store';

export default {
  name: 'Discounts',

  computed: {
    cart: checkoutComputed.cart,
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/bootstrap';
@import '~bootstrap/scss/mixins/_breakpoints';

.discounts {
  text-align: left;
  @include media-breakpoint-down(sm) {
    text-align: right;
    margin-bottom: 1rem;
  }
}
</style>
