export const USER_TIERS = {
  NoPurchases: 'NoPurchases',
  IndividualReports: 'IndividualReports',
  SubscriberAndIndividualReports: 'SubscriberAndIndividualReports',
  Subscriber: 'Subscriber',
  FullAccess: 'FullAccess',
  None: 'None',
};

/**
 * @typedef { keyof USER_TIERS } UserTier
 */

export const REQUEST_STATES = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUESTING: 'REQUESTING',
  REQUESTED: 'REQUESTED',
};

/**
 * @typedef { keyof REQUEST_STATES } RequestState
 */

/**
 * @typedef UserState
 *
 * @property {string} username
 * @property {string} ceContactGuid
 * @property {keyof USER_TIERS} userTier
 * @property {{ requestState: RequestState, value: boolean }} isAuthenticated
 * @property {{ requestState: RequestState, value: number }} cartItemCount
 */

/**
 * @type {UserState}
 */
export const state = {
  username: '',
  ceContactGuid: '',
  userTier: 'None',
  isAuthenticated: {
    requestState: 'NOT_REQUESTED',
    value: false,
  },
  cartItemCount: {
    requestState: 'NOT_REQUESTED',
    value: 0,
  },
};
