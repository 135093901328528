<template>
  <div>
    <button
      class="text-link btn btn-orange btn-zelman"
      type="button"
      @click="onClick"
    >
      Download
    </button>

    <Spinner v-show="isLoading" />

    <FormError v-if="isDownloadFailed" class="align-self-center">
      There was an issue downloading your report. Please try again.
    </FormError>
  </div>
</template>

<script>
import FormError from '@/src/shared/components/FormError.vue';
import Spinner from '@/src/shared/components/Spinner.vue';

import { action } from './store';

export default {
  name: 'DownloadButton',

  components: {
    FormError,
    Spinner,
  },

  data() {
    return {
      isDownloadFailed: false,
      isLoading: false,
    };
  },

  methods: {
    async onClick() {
      this.isLoading = true;

      const result = await this.$store.dispatch(action('DOWNLOAD_REPORT'));

      this.isLoading = false;

      if (result.isFailure) {
        this.isDownloadFailed = true;
      }
    },
  },
};
</script>
