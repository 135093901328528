import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import EmailPreferences from './EmailPreferences.vue';

export function load() {
  const selector = 'zel-email-preferences';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(EmailPreferences, selector);
}
