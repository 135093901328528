<template>
  <div>
    <p>{{ priceText }}: {{ formatCurrency(product.skuPrice) }}</p>
    <button
      type="button"
      class="btn btn-orange btn-zelman"
      @click="onAddToCart"
    >
      Buy Now
    </button>
    <Spinner v-if="isLoading" />
  </div>
</template>

<script>
import Spinner from '@/src/shared/components/Spinner.vue';
import { formatCurrency } from '@/src/shared/formatters';

import { action } from './store/actions';

/**
 * @typedef {import('./store/state').ReportAccess} ReportAccess
 */

export default {
  name: 'ReportAddToCart',

  components: {
    Spinner,
  },

  props: {
    /** @type {import('vue').PropOptions<ReportAccess>} */
    product: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<any>} */
    links: {
      type: Object,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    priceText: {
      type: String,
      required: false,
      default: 'Price',
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    formatCurrency,

    async onAddToCart() {
      this.isLoading = true;

      const result = await this.$store.dispatch(
        action('ADD_TO_CART'),
        this.product.skuId
      );

      if (result.isSuccess) {
        window.location.href = this.links.checkoutUrl;
      }

      this.isLoading = false;
    },
  },
};
</script>
