import axios from 'axios';

import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';

export const actionTypes = {
  LOAD_SEARCH_SETTINGS: 'LOAD_SEARCH_SETTINGS',
  SEARCH: 'SEARCH',
};

/**
 *
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `search/${actionType}`;
}

export const actions = {
  async [actionTypes.LOAD_SEARCH_SETTINGS]({ commit }) {
    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const result = await createResult(
      axios.get('/api/report/search/settings').then((resp) => resp.data)
    );

    commit(mutationTypes.UPDATE_IS_LOADING, false);

    if (result.isFailure) {
      return result;
    }

    commit(mutationTypes.INITIALIZE_SEARCH_FILTERS, result.value);
  },

  /**
   *
   * @param {{
   *   commit: (mutation: string, payload: any ) => void,
   *   getters: Object,
   *   state: import('./state').ReportSearchStoreState
   * }} context
   */
  async [actionTypes.SEARCH]({ commit, getters, state }) {
    if (!state.cancellationSource) {
      commit(
        mutationTypes.UPDATE_CANCELLATION_SOURCE,
        axios.CancelToken.source()
      );
    } else {
      state.cancellationSource.cancel();

      commit(
        mutationTypes.UPDATE_CANCELLATION_SOURCE,
        axios.CancelToken.source()
      );
    }

    commit(mutationTypes.UPDATE_IS_LOADING, true);

    const searchRequest = getters.searchRequest;

    const result = await createResult(
      getters.isDefaultSearchState
        ? axios.get('/api/report/search/default').then((resp) => resp.data)
        : axios
            .post('/api/report/search/custom', searchRequest, {
              cancelToken: (
                state.cancellationSource || axios.CancelToken.source()
              ).token,
            })
            .then((resp) => resp.data)
    );

    commit(mutationTypes.UPDATE_CANCELLATION_SOURCE, undefined);
    commit(mutationTypes.UPDATE_IS_LOADING, false);

    if (result.isFailure) {
      return result;
    }

    commit(mutationTypes.UPDATE_RESULTS, result.value);
  },
};
