<template>
  <div class="row no-gutters border-bottom py-2 align-items-center">
    <div class="col">
      <div class="row">
        <div class="col-10">
          <span class="h1 mr-2 mb-0 align-middle">
            <FontAwesomeIcon :icon="cardIcon" />
          </span>
          <span class="align-middle">
            XXXX {{ paymentMethod.number }}
            <span> - {{ expiration }} </span>
          </span>
        </div>
        <div class="d-flex col-2 align-items-center">
          <a
            v-if="!paymentMethod.isDefault"
            href="#"
            class="text-uppercase p-1 default-payment-method"
            type="a"
            @click.prevent="onUpdate()"
          >
            <FontAwesomeIcon :icon="icons.faCheckCircle" />
          </a>
          <span
            v-else
            class="text-uppercase p-1"
            :disabled="paymentMethod.isDefault"
          >
            <FontAwesomeIcon :icon="icons.faCheckCircle" />
          </span>
        </div>
      </div>
      <div class="row">
        <FormError
          v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful"
          class="col"
        >
          Your default payment method could not be updated.
        </FormError>
        <div
          v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful"
          class="col"
        >
          <p class="py-2 px-3 rounded bg-success text-white mb-0">
            Primary card successfully changed.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

import FormError from '@/src/shared/components/FormError.vue';
import { getCardIcon } from '@/src/shared/ecommerce-utilities';
import { createResult } from '@/src/shared/result';

/**
 * @typedef PaymentMethod
 * @property {string} id
 * @property {string=} brand
 * @property {boolean} isDefault
 * @property {string} number
 * @property {string} expirationMonth
 * @property {string} expirationYear
 */

export default {
  name: 'PaymentMethodListItem',

  components: {
    FormError,
    FontAwesomeIcon,
  },

  props: {
    /** @type {import('vue').PropOptions<PaymentMethod>} */
    paymentMethod: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      uiState: {
        isRequestFailed: false,
        hasSubmitted: false,
      },
    };
  },

  computed: {
    /** @returns {{ [key: string]: import('@fortawesome/fontawesome-svg-core').IconDefinition }}*/
    icons() {
      return { faCheckCircle };
    },

    /** @returns {import('@fortawesome/fontawesome-svg-core').IconDefinition} */
    cardIcon() {
      return getCardIcon(this.paymentMethod.brand || '');
    },

    /** @returns {string} */
    expiration() {
      return `${this.paymentMethod.expirationMonth}/${this.paymentMethod.expirationYear}`;
    },
  },

  methods: {
    onUpdate() {
      const promise = this.updateCard();

      this.$emit('update', promise);
    },

    async updateCard() {
      const result = await createResult(
        axios.put(`/api/account/detail/payment-method/`, {
          paymentMethodId: this.paymentMethod.id,
        })
      );

      this.uiState.hasSubmitted = true;

      if (result.isFailure) {
        this.uiState.isSubmissionSuccessful = false;

        return result;
      }

      this.uiState.isSubmissionSuccessful = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

.default-payment-method {
  color: $secondary-color-transparent-50;

  &:hover {
    color: $secondary-color;
  }
}
</style>
