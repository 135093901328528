export const mutationTypes = {
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS',
  SET_INDIVIDUAL_REPORTS: 'SET_INDIVIDUAL_REPORTS',
  SET_RECENT_REPORTS: 'SET_RECENT_REPORTS',
};

/**
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `dashboard/${mutationType}`;
}

export const mutations = {
  /**
   * @param {DashboardState} state
   * @param {SubscriptionResponse[]} subscriptions
   */
  [mutationTypes.SET_SUBSCRIPTIONS](state, subscriptions) {
    state.subscriptions = subscriptions;
  },

  /**
   * @param {DashboardState} state
   * @param {ReportResponse[]} individualReports
   */
  [mutationTypes.SET_INDIVIDUAL_REPORTS](state, individualReports) {
    state.individualReports = individualReports;
  },

  /**
   * @param {DashboardState} state
   * @param {ReportResponse[]} recentReports
   */
  [mutationTypes.SET_RECENT_REPORTS](state, recentReports) {
    state.recentReports = recentReports;
  },
};

/**
 * @typedef {import('./state').DashboardState} DashboardState
 * @typedef {import('./state').SubscriptionResponse} SubscriptionResponse
 * @typedef {import('./state').ReportResponse} ReportResponse
 */
