import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faLinkedinIn as freeFabLinkedinIn,
  faTwitter as freeFabTwitter,
  faFacebookSquare as freeFabFacebookSquare,
  faInstagram as freeFabInstagram,
  faYoutube as freeFabYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUp as freeFasArrowUp,
  faTimesCircle as freeFasTimesCircle,
  faLock as freeFasLock,
} from '@fortawesome/free-solid-svg-icons';
import {
  faHistory as proFalHistory,
  faHome as proFalHome,
  faMapMarkerAlt as proFalMapMarkerAlt,
  faNewspaper as proFalNewspaper,
  faPhoneAlt as proFalPhoneAlt,
  faUserAlt as proFalUserAlt,
  faMailbox as proFalMailbox,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDown as proFarAngleDown,
  faAngleUp as proFarAngleUp,
  faCheck as proFarCheck,
  faCheckCircle as proFarCheckCircle,
  faExclamationCircle as proFarExclamationCircle,
  faTimes as proFarTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faQuoteLeft as proFasQuoteLeft,
  faQuoteRight as proFasQuoteRight,
} from '@fortawesome/pro-solid-svg-icons';

// See: https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core

export function initIconLibrary() {
  library.add(
    freeFasLock,
    proFalMailbox,
    proFalNewspaper,
    proFalHistory,
    proFalUserAlt,
    proFarCheck,
    proFarCheckCircle,
    proFarExclamationCircle,
    proFalHome,
    proFasQuoteRight,
    proFasQuoteLeft,
    proFarTimes,
    proFalMapMarkerAlt,
    proFalPhoneAlt,
    freeFasTimesCircle,
    freeFasArrowUp,
    proFarAngleDown,
    proFarAngleUp,
    freeFabTwitter,
    freeFabLinkedinIn,
    freeFabFacebookSquare,
    freeFabInstagram,
    freeFabYoutube
  );

  dom.watch();
}
