<template>
  <div class="col-12 py-3 mb-3 bg-light-2 rounded">
    <div class="row no-gutter">
      <div class="col-12 col-md-5">
        <Discounts />
      </div>
      <div class="col-12 text-black-50">
        <div class="row">
          <div class="col">Subtotal</div>
          <div class="col text-right font-weight-bold">
            {{ formattedSubtotal }}
          </div>
        </div>
        <div class="row">
          <div class="col">Discount</div>
          <div class="col text-right font-weight-bold">
            {{ formattedDiscount }}
          </div>
        </div>
        <div class="row">
          <div class="col">Tax</div>
          <div class="col text-right font-weight-bold">{{ formattedTax }}</div>
        </div>
        <div class="row text-dark">
          <div class="col" style="font-size: 1.2rem">Total</div>
          <div
            class="col text-right font-weight-bold"
            style="font-size: 1.2rem"
          >
            {{ formattedPrice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from '@/src/shared/formatters';

import { checkoutComputed } from '../store';

import Discounts from './Discounts.vue';

export default {
  name: 'Totals',

  components: {
    Discounts,
  },

  computed: {
    totals: checkoutComputed.totals,

    /** @returns {string} */
    formattedDiscount() {
      return formatCurrency(this.totals.discount);
    },

    /** @returns {string} */
    formattedSubtotal() {
      return formatCurrency(this.totals.subtotal);
    },

    /** @returns {string} */
    formattedPrice() {
      return formatCurrency(this.totals.price);
    },

    /** @returns {string} */
    formattedTax() {
      return formatCurrency(this.totals.tax);
    },
  },
};
</script>
