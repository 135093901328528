<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <ValidationObserver ref="observer" tag="div">
        <div class="form-row">
          <div class="form-group col-12">
            <ValidationProvider
              v-slot="props"
              rules="required"
              :name="fieldNames.addressBillingName"
            >
              <label for="billingName">
                {{ fieldNames.addressBillingName }} *
              </label>
              <ValidatingControl :state="props">
                <input
                  id="billingName"
                  v-model="form.billingName"
                  name="billingName"
                  type="text"
                  class="form-control"
                  :placeholder="fieldNames.addressBillingName"
                />
              </ValidatingControl>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <ValidationProvider
              v-slot="props"
              rules="required"
              :name="fieldNames.addressLine1"
            >
              <label for="addressLine1">{{ fieldNames.addressLine1 }} *</label>
              <ValidatingControl :state="props">
                <input
                  id="addressLine1"
                  v-model="form.line1"
                  name="addressLine1"
                  type="text"
                  class="form-control"
                  :placeholder="fieldNames.addressLine1"
                />
              </ValidatingControl>
            </ValidationProvider>
          </div>
          <div class="form-group col-md-6 col-sm-12">
            <label for="addressLine2">{{ fieldNames.addressLine2 }}</label>
            <input
              id="addressLine2"
              v-model="form.line2"
              name="addressLine2"
              type="text"
              class="form-control"
              :placeholder="fieldNames.addressLine2"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12 col-sm-12">
            <ValidationProvider
              v-slot="props"
              rules="required"
              :name="fieldNames.addressCity"
            >
              <label for="addressCity">{{ fieldNames.addressCity }} *</label>
              <ValidatingControl :state="props">
                <input
                  id="addressCity"
                  v-model="form.city"
                  name="addressCity"
                  type="text"
                  class="form-control"
                  :placeholder="fieldNames.addressCity"
                />
              </ValidatingControl>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-12">
            <ValidationProvider
              v-slot="props"
              rules="min_value:1"
              :name="fieldNames.addressCountryId"
            >
              <label for="addressCountryId">
                {{ fieldNames.addressCountryId }} *
              </label>
              <ValidatingControl :state="props" :show-icon="false">
                <select
                  id="addressCountryId"
                  v-model="form.countryId"
                  name="addressCountryId"
                  class="form-control"
                >
                  <option disabled :value="0">(Country)</option>
                  <option
                    v-for="country in settings.countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </ValidatingControl>
            </ValidationProvider>
          </div>
          <div
            v-if="filteredStates.length"
            class="form-group col-md-6 col-sm-12"
          >
            <ValidationProvider
              v-slot="props"
              rules="min_value:1"
              :name="fieldNames.addressStateId"
            >
              <label for="addressStateId">
                {{ fieldNames.addressStateId }} *
              </label>
              <ValidatingControl :state="props" :show-icon="false">
                <select
                  id="addressStateId"
                  v-model="form.stateId"
                  name="addressStateId"
                  class="form-control"
                >
                  <option disabled :value="0">(State)</option>
                  <option
                    v-for="state in filteredStates"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
              </ValidatingControl>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-5 col-sm-12">
            <ValidationProvider
              v-slot="props"
              rules="required"
              :name="fieldNames.addressPostal"
            >
              <label for="addressPostal">
                {{ fieldNames.addressPostal }} *
              </label>
              <ValidatingControl :state="props">
                <input
                  id="addressPostal"
                  v-model="form.postalCode"
                  name="addressPostal"
                  type="text"
                  class="form-control"
                  :placeholder="fieldNames.addressPostal"
                />
              </ValidatingControl>
            </ValidationProvider>
          </div>
          <div
            class="d-flex justify-content-end align-items-end form-group col-md-7 col-sm-12"
          >
            <button
              type="submit"
              class="btn btn-sm btn-secondary btn-zelman mr-3"
            >
              Save Address
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </form>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import ValidatingControl from '@/src/shared/components/ValidatingControl.vue';
import { fieldNames } from '@/src/shared/form-validation';

import { action, checkoutComputed } from '../store';

export default {
  name: 'BillingAddressForm',

  components: {
    ValidationProvider,
    ValidationObserver,
    ValidatingControl,
  },

  data() {
    return {
      fieldNames,
      form: {
        id: 0,
        billingName: '',
        line1: '',
        line2: '',
        city: '',
        stateId: 0,
        countryId: 271,
        postalCode: '',
      },
    };
  },

  computed: {
    ...checkoutComputed,

    /** @returns { { id: number, name: string}[] } */
    filteredStates() {
      return this.settings.states.filter(
        (s) => s.countryId === this.form.countryId
      );
    },

    /** @returns {InstanceType<typeof ValidationObserver>} */
    observer() {
      /** @type {any} */
      const observer = this.$refs.observer;

      return observer;
    },
  },

  watch: {
    selectedPaymentOption: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.form = newVal.address;
        }
      },
    },
  },

  methods: {
    async onSubmit() {
      const isValid = await this.observer.validate();

      if (isValid) {
        this.$store.dispatch(action('SET_BILLING_ADDRESS'), this.form);
      }
    },
  },
};
</script>
