<template>
  <div>
    <ReportSearchFilterBox
      identifier="sectors"
      title="Sector"
      :default-open="true"
    >
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.sectors"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox identifier="reportTypes" title="Report Type">
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.reportTypes"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox identifier="thematics" title="Thematic">
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.thematics"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox
      identifier="proprietarySurveys"
      title="Proprietary Surveys"
    >
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.proprietarySurveys"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox
      identifier="recurringPublications"
      title="Recurring Publications"
    >
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.recurringPublications"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox
      identifier="referenceGuides"
      title="Reference Guides"
    >
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.referenceGuides"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox
      identifier="companySpecifics"
      title="Company Specific"
    >
      <template #filter="{ id }">
        <ReportSearchCategoryFilter
          :group="id"
          :categories="categories.companySpecifics"
          @change="onChangeCategory"
        />
      </template>
    </ReportSearchFilterBox>
    <ReportSearchFilterBox
      identifier="dates"
      title="Date Range"
      :overflow-hidden="false"
    >
      <template #filter="{ id }">
        <ReportSearchDateFilter
          :identifier="id"
          :options="dates"
          @change="onChangeDate"
        />
      </template>
    </ReportSearchFilterBox>
  </div>
</template>

<script>
import ReportSearchCategoryFilter from './ReportSearchCategoryFilter.vue';
import ReportSearchDateFilter from './ReportSearchDateFilter.vue';
import ReportSearchFilterBox from './ReportSearchFilterBox.vue';
import { action, reportSearchComputed, mutation } from './store';

export default {
  name: 'ReportSearchFilters',

  components: {
    ReportSearchDateFilter,
    ReportSearchCategoryFilter,
    ReportSearchFilterBox,
  },

  computed: {
    ...reportSearchComputed,

    /**
     * @returns {import('./store/state').ResultFilters}
     */
    categories() {
      return this.categoryFilters;
    },

    /**
     * @returns {import('./store/state').DateFilter}
     */
    dates() {
      return this.dateFilters;
    },
  },

  methods: {
    /**
     * @returns {string}
     */
    getId(option) {
      return option.value ? option.value.replace(/ +/g, '-').toLowerCase() : '';
    },

    async onChangeCategory(event) {
      this.$store.commit(mutation('UPDATE_CATEGORY_GROUP_FILTER'), event);

      await this.$store.dispatch(action('SEARCH'));
    },

    async onChangeDate(event) {
      this.$store.commit(mutation('UPDATE_DATE_FILTER'), event);

      await this.$store.dispatch(action('SEARCH'));
    },
  },
};
</script>
