/**
 * @type {VideoDetailState}
 */
export const state = {
  nodeGuid: '',
  isAccessValidated: false,
  hasAccess: false,
  embedCodeHTML: '',
};

/**
 * @typedef {Object} VideoDetailState
 * @property {String} nodeGuid
 * @property {Boolean} isAccessValidated
 * @property {boolean} hasAccess
 * @property {string} embedCodeHTML
 */
