/**
 * @param {{ ceContactGuid: string, userTier: import('../rootStore/user/state').UserTier}} userInfo
 */
export function setGACookies({ ceContactGuid, userTier }) {
  const d = new Date();

  d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * 365 * 2);

  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `userId=${ceContactGuid}; ${expires}; path=/`;
  document.cookie = `userTier=${userTier}; ${expires}; path=/;`;
}

export function clearGACookies() {
  const d = new Date();

  d.setTime(d.getTime() - 1000 * 60 * 60 * 24 * 365 * 2);

  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `userId=; ${expires}; path=/`;
  document.cookie = `userTier=; ${expires}; path=/;`;
}
