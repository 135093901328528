import axios from 'axios';

export const dateFilterOptions = {
  None: 'None',
  PreviousSevenDays: 'Previous 7 Days',
  PreviousThirtyDays: 'Previous 30 Days',
  PreviousSixMonths: 'Previous 6 Months',
  Custom: 'Custom',
};

export const orderByOptions = {
  iirReportPagePublishedDateDesc: {
    name: 'Newest',
    value: 'iirReportPagePublishedDate desc',
  },
  iirReportPagePublishedDateAsc: {
    name: 'Oldest',
    value: 'iirReportPagePublishedDate asc',
  },
  iirReportPagePopularityLevel: {
    name: 'Popularity',
    value: 'iirReportPagePopularityLevel desc',
  },
  relevance: { name: 'Relevance', value: 'search.score() desc' },
};

export const categoryGroupKeys = [
  'sectors',
  'reportTypes',
  'thematics',
  'companySpecifics',
  'recurringPublications',
  'referenceGuides',
  'proprietarySurveys',
];

export const categoryGroupNames = {
  sectors: 'Sectors',
  reportTypes: 'Report Types',
  thematics: 'Thematics',
  companySpecifics: 'Company Specifics',
  recurringPublications: 'Recurring Publications',
  referenceGuides: 'Reference Guides',
  proprietarySurveys: 'Proprietary Surveys',
};

/**
 * Creates default search settings
 * @returns {SearchSettings}
 */
export function defaultSearchSettings() {
  return {
    pageNumber: 1,
    orderBy: orderByOptions.iirReportPagePublishedDateDesc.value,
    term: '',
    filters: {
      sectors: [],
      reportTypes: [],
      thematics: [],
      companySpecifics: [],
      recurringPublications: [],
      referenceGuides: [],
      proprietarySurveys: [],
      dates: {
        option: dateFilterOptions.None,
        from: undefined,
        to: undefined,
      },
    },
  };
}

/**
 * @type {ReportSearchStoreState}
 */
export const state = {
  searchSettings: defaultSearchSettings(),
  searchResults: {
    filters: {
      sectors: [],
      reportTypes: [],
      thematics: [],
      companySpecifics: [],
      recurringPublications: [],
      referenceGuides: [],
      proprietarySurveys: [],
    },
    reports: [],
    count: 0,
  },
  ui: {
    isLoading: false,
    isInitialLoad: false,
    isAccessContextVisible: false,
  },
  maxRelevance: 0,
  cancellationSource: axios.CancelToken.source(),
};

/**
 * @typedef {Object} DateFilter
 * @property {string} option
 * @property {string=} from
 * @property {string=} to
 */

/**
 * @typedef {Object} SearchFilters
 * @property {number[]} sectors
 * @property {number[]} reportTypes
 * @property {number[]} thematics
 * @property {number[]} companySpecifics
 * @property {number[]} recurringPublications
 * @property {number[]} referenceGuides
 * @property {number[]} proprietarySurveys
 * @property {DateFilter} dates
 */

/**
 * @typedef {Object} SearchSettings
 * @property {Number} pageNumber
 * @property {String} orderBy
 * @property {String} term
 * @property {SearchFilters} filters
 */

/**
 * @typedef {Object} ResultCategoryFilter
 * @property {boolean} isSelected
 * @property {string} value
 * @property {number} count
 * @property {number} id
 */

/**
 * @typedef ResultFilters
 * @property {ResultCategoryFilter[]} sectors
 * @property {ResultCategoryFilter[]} reportTypes
 * @property {ResultCategoryFilter[]} thematics
 * @property {ResultCategoryFilter[]} companySpecifics
 * @property {ResultCategoryFilter[]} recurringPublications
 * @property {ResultCategoryFilter[]} referenceGuides
 * @property {ResultCategoryFilter[]} proprietarySurveys
 */

/**
 * @typedef ReportResult
 * @property {string} id
 * @property {string} linkUrl
 * @property {string} title
 * @property {string} subtitle
 * @property {string} date
 * @property {number} score
 * @property {number} popularity
 * @property {boolean} hasAccess
 * @property {boolean} isForSale
 */

/**
 * @typedef {Object} SearchResults
 * @property {ResultFilters} filters
 * @property {ReportResult[]} reports
 * @property {Number} count
 */

/**
 * @typedef {Object} UIState
 * @property {Boolean} isLoading
 * @property {Boolean} isInitialLoad
 * @property {Boolean} isAccessContextVisible
 */

/**
 * @typedef {Object} ReportSearchStoreState
 * @property {SearchSettings} searchSettings
 * @property {SearchResults} searchResults
 * @property {UIState} ui
 * @property {number} maxRelevance
 * @property {import('axios').CancelTokenSource} [cancellationSource]
 */
