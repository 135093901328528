import { createNamespacedHelpers } from 'vuex';

export const getters = {
  /**
   * @param {ReportDetailState} state
   */
  access: ({ access }) => access,

  /**
   * @param {ReportDetailState} state
   */
  isLoadingAccess: ({ ui }) => ui.isLoadingAccess,

  /**
   * @param {ReportDetailState} state
   */
  isAccessValidated: ({ isAccessValidated }) => isAccessValidated,
};

/**
 * @param {ReportAccess[]} access
 * @param {boolean} isAuthenticated
 * @param {string} nodeGuid
 * @returns {boolean}
 */
export function isProductForSale(access, isAuthenticated, nodeGuid) {
  const { hasAccess, hasError } = findAccess(access, nodeGuid);

  return !hasAccess && !hasError && isAuthenticated;
}

/**
 * @param {ReportAccess[]} access
 * @param {string} nodeGuid
 * @returns {boolean}
 */
export function hasAccessToProduct(access, nodeGuid) {
  const { hasAccess, hasError } = findAccess(access, nodeGuid);

  return hasAccess && !hasError;
}

/**
 * @param {ReportAccess[]} access
 * @param {{nodeGuid: string, skuId: number, skuPrice: number }} product
 * @returns {ReportAccess}
 */
export function findProduct(access, { nodeGuid, skuId, skuPrice }) {
  return {
    ...findAccess(access, nodeGuid),
    skuPrice,
    skuId,
  };
}

/**
 *
 * @param {ReportAccess[]} access
 * @param {string} nodeGuid
 * @returns {ReportAccess}
 */
function findAccess(access, nodeGuid) {
  return (
    access.find((a) => a.nodeGuid === nodeGuid) || getDefaultReportAccess()
  );
}

/**
 * @returns {ReportAccess}
 */
function getDefaultReportAccess() {
  return {
    hasAccess: false,
    hasError: false,
    nodeGuid: '',
    skuId: 0,
    skuPrice: 0,
  };
}

export const reportDetailComputed = /** @type {ReportDetailComputed} */ (
  createNamespacedHelpers('reportDetail').mapGetters([
    'access',
    'isLoadingAccess',
    'isAccessValidated',
  ])
);

/**
 * @typedef ReportDetailComputed
 * @property {() => ReportAccess[]} access
 * @property {() => boolean} isLoadingAccess
 * @property {() => boolean} isAccessValidated
 *
 * @typedef {import('./state').ReportDetailState} ReportDetailState
 * @typedef {import('./state').ReportAccess} ReportAccess
 */
