<template>
  <div>
    <div v-if="!cartItems.length" class="card mb-3">
      <div class="row no-gutters align-items-center">
        <div class="col-12 p-2 text-center">
          There are no items in your cart
        </div>
      </div>
    </div>
    <div v-else>
      <Items />

      <Discounts />

      <Totals />
    </div>
  </div>
</template>

<script>
import { checkoutComputed } from '../store';

import Discounts from './Discounts.vue';
import Items from './Items.vue';
import Totals from './Totals.vue';

export default {
  name: 'Preview',

  components: {
    Discounts,
    Items,
    Totals,
  },

  computed: {
    cartItems: checkoutComputed.cartItems,
  },
};
</script>
