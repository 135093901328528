import { isVueAppInDOM, renderApp } from '@/src/shared/component-utilities';

import CheckoutThankYou from './CheckoutThankYou.vue';

export function load() {
  const selector = 'zel-checkout-thank-you';

  if (!isVueAppInDOM(selector)) {
    return;
  }

  renderApp(CheckoutThankYou, selector);
}
