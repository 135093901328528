<template>
  <div id="cart" class="rounded">
    <CartStep :step="stepStates.preview">
      <template #header="{ step }">
        <CartStepHeader :step="step"> Your Cart </CartStepHeader>
      </template>
      <template #body>
        <Preview />
        <CartStepButtons
          v-if="cartItems.length"
          :step-next="stepStates.payment"
        >
          <template #next> Next Step: Payment Information </template>
        </CartStepButtons>
        <div v-else class="d-flex justify-content-center w-100">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="emptyCartHtml.trim()"></div>
        </div>
      </template>
    </CartStep>
    <CartStep :step="stepStates.payment">
      <template #header="{ step }">
        <CartStepHeader :step="step"> Payment Information </CartStepHeader>
      </template>
      <template #body>
        <Payment />

        <CartStepButtons
          :step-next="stepStates.review"
          :step-previous="stepStates.preview"
        >
          <template #previous> Back to Cart </template>
          <template #next> Continue to Order Review </template>
        </CartStepButtons>
      </template>
    </CartStep>
    <CartStep :step="stepStates.review">
      <template #header="{ step }">
        <CartStepHeader :step="step"> Review Order </CartStepHeader>
      </template>
      <template #body>
        <Review />
        <CartStepButtons
          :step-previous="stepStates.payment"
          :step-next="stepStates.complete"
        >
          <template #previous> Back to Payment Selection </template>
          <template #next> Pay Now </template>
        </CartStepButtons>
      </template>
    </CartStep>
    <p v-if="ui.hasError" class="text-white bg-danger rounded py-2 px-3 mb-0">
      <FontAwesomeIcon :icon="icons.faExclamationCircle" />
      There was an error processing your shopping cart, please
      <a :href="links.contactUsUrl" class="alert-link text-white">
        contact support </a
      >.
    </p>
  </div>
</template>

<script>
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { store } from '@/src/rootStore';

import Payment from './Payment/Payment.vue';
import Preview from './Preview/Preview.vue';
import Review from './Review/Review.vue';
import CartStep from './StepComponents/CartStep.vue';
import CartStepButtons from './StepComponents/CartStepButtons.vue';
import CartStepHeader from './StepComponents/CartStepHeader.vue';
import { action, checkoutComputed, mutation } from './store';

export default {
  name: 'Checkout',

  components: {
    FontAwesomeIcon,
    CartStep,
    CartStepButtons,
    CartStepHeader,
    Preview,
    Payment,
    Review,
  },

  props: {
    /** @type {import('vue').PropOptions<string>} */
    stripeSpk: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<string>} */
    emptyCartHtml: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ reportSearchUrl: string, contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  store,

  computed: {
    ...checkoutComputed,

    icons() {
      return { faExclamationCircle };
    },
  },

  async created() {
    this.$store.commit(mutation('UPDATE_STRIPE_API_KEY'), this.stripeSpk);

    await Promise.all([
      this.$store.dispatch(action('REQUEST_SETTINGS')),
      this.$store.dispatch(action('REQUEST_CART'), { toggleLoading: true }),
    ]);
  },
};
</script>
