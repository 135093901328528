<template>
  <div>
    <ValidationObserver ref="observer" tag="form" @submit.prevent="onSubmit">
      <div class="form-group">
        <label for="username">Username</label>
        <span class="d-block">{{ username }}</span>
      </div>
      <div class="form-group">
        <ValidationProvider
          v-slot="props"
          rules="required"
          :name="fieldNames.currentPassword"
        >
          <label for="currentPassword">Current Password *</label>
          <ValidatingControl :state="props">
            <input
              id="currentPassword"
              v-model="form.currentPassword"
              name="currentPassword"
              type="password"
              class="form-control"
              aria-describedby="currentPassword"
              placeholder="Current Password"
            />
          </ValidatingControl>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          v-slot="props"
          vid="newPassword"
          rules="required|passwordComplexity"
          :name="fieldNames.newPassword"
        >
          <label for="newPassword">New Password *</label>
          <ValidatingControl :state="props">
            <input
              id="newPassword"
              v-model="form.newPassword"
              name="newPassword"
              type="password"
              class="form-control"
              placeholder="New Password"
            />
          </ValidatingControl>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          v-slot="props"
          rules="required|passwordConfirmed:newPassword"
          :name="fieldNames.newPasswordConfirm"
        >
          <label for="newPasswordConfirm">Confirm New Password *</label>
          <ValidatingControl :state="props">
            <input
              id="newPasswordConfirm"
              v-model="form.newPasswordConfirm"
              name="newPasswordConfirm"
              type="password"
              class="form-control"
              aria-describedby="newPasswordConfirm"
              placeholder="Confirm New Password"
            />
          </ValidatingControl>
        </ValidationProvider>
      </div>
      <div class="d-flex justify-content-between">
        <button
          type="submit"
          class="btn btn-orange btn-zelman text-uppercase mr-3"
        >
          Update Password
        </button>
        <button
          type="button"
          class="btn btn-orange-outline btn-zelman text-uppercase"
          @click.stop="onClear"
        >
          Clear
        </button>
      </div>
    </ValidationObserver>

    <Spinner v-if="uiState.isLoading" class="align-self-center" />

    <div
      v-if="uiState.hasSubmitted && !uiState.isSubmissionSuccessful"
      class="mt-5"
    >
      <p class="text-white bg-danger rounded py-2 px-3 mb-0">
        <FontAwesomeIcon :icon="icons.faExclamationCircle" />
        There was an issue updating your password. Please verify you typed your
        current password correctly.
      </p>
    </div>

    <div
      v-if="uiState.hasSubmitted && uiState.isSubmissionSuccessful"
      class="mt-5"
    >
      <p class="py-2 px-3 rounded bg-success text-white mb-0">
        <FontAwesomeIcon :icon="icons.faCheckCircle" />
        Your password has been updated.
      </p>
    </div>
  </div>
</template>

<script>
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import Spinner from '@/src/shared/components/Spinner.vue';
import ValidatingControl from '@/src/shared/components/ValidatingControl.vue';
import {
  createPasswordComplexityValidator,
  fieldNames,
} from '@/src/shared/form-validation';
import { createResult } from '@/src/shared/result';

export default {
  name: 'PasswordForm',

  components: {
    FontAwesomeIcon,
    ValidationObserver,
    ValidationProvider,
    Spinner,
    ValidatingControl,
  },

  data() {
    return {
      fieldNames,
      uiState: {
        isLoading: false,
        hasSubmitted: false,
        isSubmissionSuccessful: false,
      },
      form: resetForm(),
    };
  },

  store,

  computed: {
    username: rootComputed.username,

    /** @returns {{ [key: string]: import('@fortawesome/fontawesome-svg-core').IconDefinition }}*/
    icons() {
      return { faExclamationCircle, faCheckCircle };
    },

    /** @returns {InstanceType<typeof ValidationObserver>} */
    observer() {
      /** @type {any} */
      const observer = this.$refs.observer;

      return observer;
    },
  },

  async created() {
    const result = await createResult(
      axios.get('/api/reset-password/requirements').then(({ data }) => data)
    );

    if (result.isFailure) {
      return;
    }

    extend(
      'passwordComplexity',
      createPasswordComplexityValidator(result.value)
    );
  },

  methods: {
    async onSubmit() {
      const isValid = await this.observer.validate();

      if (!isValid) {
        return;
      }

      this.uiState.isSubmissionSuccessful = false;
      this.uiState.hasSubmitted = false;
      this.uiState.isLoading = true;

      const result = await createResult(
        axios.put('/api/account/detail/password', {
          currentPassword: this.form.currentPassword,
          newPassword: this.form.newPassword,
        })
      );

      this.uiState.isLoading = false;
      this.uiState.hasSubmitted = true;

      if (result.isFailure) {
        this.uiState.isSubmissionSuccessful = false;

        return;
      }

      this.form = resetForm();
      this.observer.reset();
      this.uiState.isSubmissionSuccessful = true;
    },

    onClear() {
      this.uiState.hasSubmitted = false;
      this.uiState.isSubmissionSuccessful = false;

      this.form = resetForm();

      this.observer.reset();
    },
  },
};

function resetForm() {
  return {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };
}
</script>
