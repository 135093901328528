export const mutationTypes = {
  SET_EMAIL_PREFERENCES: 'SET_EMAIL_PREFERENCES',
  SET_SECTOR_UPDATE_RESULT: 'SET_SECTOR_UPDATE_RESULT',
  SET_INDIVIDUAL_UPDATE_RESULT: 'SET_INDIVIDUAL_UPDATE_RESULT',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
};

/**
 * @param {keyof mutationTypes} mutationType
 */
export function mutation(mutationType) {
  return `emailPreferences/${mutationType}`;
}

export const mutations = {
  /**
   * @param {EmailPreferencesState} state
   * @param {EmailPreferencesResponse} response
   */
  [mutationTypes.SET_EMAIL_PREFERENCES](state, response) {
    state.sectors = response.sectors.map((sector) => ({
      name: sector.name,
      nameEncoded: htmlAttributeEncode(sector.name),
      items: sector.preferences,
    }));
  },

  /**
   * @param {EmailPreferencesState} state
   * @param {{ isSuccess: boolean, sectorName: string, isSubscribed: boolean }} result
   */
  [mutationTypes.SET_SECTOR_UPDATE_RESULT](state, result) {
    state.sectors = state.sectors.map((s) => {
      if (!result.isSuccess || s.name !== result.sectorName) {
        return s;
      }

      return {
        ...s,
        items: s.items.map((i) => ({
          ...i,
          isSubscribed: i.hasAccess ? result.isSubscribed : i.isSubscribed,
        })),
      };
    });
  },

  /**
   * @param {EmailPreferencesState} state
   * @param {{ isSuccess: boolean, reportTypeId: string, isSubscribed: boolean }} result
   */
  [mutationTypes.SET_INDIVIDUAL_UPDATE_RESULT](state, result) {
    for (const sector of state.sectors) {
      sector.items = sector.items.map((i) => {
        if (!result.isSuccess || i.reportTypeId !== result.reportTypeId) {
          return i;
        }

        return { ...i, isSubscribed: result.isSubscribed };
      });
    }
  },

  /**
   * @param {EmailPreferencesState} state
   * @param {LoadingState} loadingState
   */
  [mutationTypes.SET_LOADING_STATE](state, loadingState) {
    state.loadingState = loadingState;
  },
};

/**
 * @param {string} value
 * @returns {string}
 */
function htmlAttributeEncode(value) {
  return (value || '').replace(/'/g, '').replace(/\s/g, '').replace('&', '');
}

/**
 * @typedef {import('./state').EmailPreferencesState} EmailPreferencesState
 * @typedef {import('./state').EmailPreferencesResponse} EmailPreferencesResponse
 * @typedef {import('./state').LoadingState} LoadingState
 */
