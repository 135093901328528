<template>
  <button
    class="btn btn-link py-3 px-4 btn-block rounded-0 rounded-top"
    type="button"
    :disabled="!step.isEnabled"
    :class="{ expanded }"
    :aria-expanded="expanded"
    @click="onStepChange"
  >
    <Spinner v-show="isLoading && expanded" class="spinner position-absolute" />
    <slot />
  </button>
</template>

<script>
import Spinner from '@/src/shared/components/Spinner.vue';

import { action, checkoutComputed } from '../store';

export default {
  name: 'CartStepHeader',

  components: {
    Spinner,
  },

  props: {
    step: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...checkoutComputed,

    /** @returns {boolean} */
    expanded() {
      return this.step.name === this.currentStep.name;
    },
  },

  methods: {
    onStepChange() {
      this.$store.dispatch(action('CHANGE_STEP'), this.step);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

button {
  position: relative;

  &.expanded {
    background-color: $primary-orange;
    color: $white;

    &::after {
      position: absolute;
      z-index: 100;
      content: '';
      width: 41px;
      height: 22px;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: $primary-orange transparent transparent transparent;
    }
  }
}

.spinner {
  top: 0;
  right: 0;
}
</style>
