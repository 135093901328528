<template>
  <div class="col py-2 py-md-3">
    <div class="card">
      <div class="card-header p-0">
        <button
          class="btn btn-orange d-block w-100 text-left position-relative px-3 py-2 rounded-0 collapsed text-uppercase"
          data-toggle="collapse"
          :data-target="`#collapse-${sector.nameEncoded}`"
          role="button"
          aria-expanded="false"
          :aria-controls="`collapse-${sector.nameEncoded}`"
        >
          {{ sector.name }}
          <small class="ml-2">
            ({{ selectedCount }} / {{ sector.items.length }})
          </small>
          <div class="accord-icon">
            <span class="a-i1" />
            <span class="a-i2" />
          </div>
        </button>
      </div>
      <div :id="`collapse-${sector.nameEncoded}`" class="collapse">
        <div class="card-body">
          <div class="card-title">
            <EmailPreferencesSectorBulkSelector
              :items="sector.items"
              :name="sector.name"
            />
          </div>
          <div class="card-text">
            <div class="row">
              <div
                class="col-12"
                :class="{ 'col-md-6': companySpecificPreferences.length }"
              >
                <ul class="list-sector alt pl-0">
                  <EmailPreferenceItem
                    v-for="item in otherPreferences"
                    :key="item.reportTypeId"
                    :preference="item"
                  />
                </ul>
              </div>
              <div
                v-if="companySpecificPreferences.length"
                class="col-12 col-md-6"
              >
                <ul class="list-sector alt pl-0">
                  <EmailPreferenceItem
                    v-for="item in companySpecificPreferences"
                    :key="item.reportTypeId"
                    :preference="item"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailPreferenceItem from './EmailPreferenceItem.vue';
import EmailPreferencesSectorBulkSelector from './EmailPreferencesSectorBulkSelector.vue';

export default {
  name: 'EmailPreferenceSector',

  components: {
    EmailPreferenceItem,
    EmailPreferencesSectorBulkSelector,
  },

  props: {
    /** @type {import('vue').PropOptions<import('./store/state').EmailPreferenceSector>}*/
    sector: {
      type: Object,
      required: true,
      default: () => ({ items: [], name: '', nameEncoded: '' }),
    },
  },

  computed: {
    /** @returns {import('./store/state').EmailPreference[]} */
    companySpecificPreferences() {
      return this.sector.items.filter((i) => i.isCompanySpecific);
    },

    /** @returns {import('./store/state').EmailPreference[]} */
    otherPreferences() {
      return this.sector.items.filter((i) => !i.isCompanySpecific);
    },

    /**
     * @returns {number}
     */
    selectedCount() {
      return this.sector.items.reduce((prev, curr) => {
        if (curr.isSubscribed) {
          prev++;
        }

        return prev;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/src/shared/styles/variables';

.accord-icon {
  border-radius: 50%;
  border: 1px solid $white;
  width: 20px;
  height: 0;
  padding: 0 0 20px;
  box-sizing: content-box;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  .a-i1 {
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
  }
  .a-i2 {
    transform: none;
    position: absolute;
    width: 10px;
    height: 1px;
    background: $white;
    display: block;
    top: 10px;
    left: 5px;
    transition: all 0.25s;
  }
}

.collapsed .accord-icon .a-i2 {
  transform: rotate(90deg);
}
</style>
