<template>
  <div>
    <p>
      This report is limited to institutional relationships. Please email us at
      <a href="mailto:info@zelmanassociates.com">info@zelmanassociates.com</a>
      to learn more.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ReportRequestPricing',
};
</script>
