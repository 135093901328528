<template>
  <div v-frag>
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="currentHasAccess" v-html="embedCodeHTML"></div>
    <div
      v-else
      style="overflow: hidden; position: relative"
      class="card-img-top img-10x6"
    >
      <a
        :href="accessLink"
        class="btn btn-zelman btn-secondary"
        style="
          position: absolute;
          z-index: 5;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <FontAwesomeIcon :icon="lockIcon" />
        <span v-if="!isAuthenticated" class="ml-3">Login to Access</span>
        <span v-else class="ml-3">Contact Us to Access</span>
      </a>
      <div class="overlay-restricted"></div>
      <div class="card-img-top img-10x6" style="filter: blur(2px)">
        <img :src="teaserImageURL" :alt="title" />
      </div>
    </div>
  </div>
</template>

<script>
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import frag from 'vue-frag';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';
import { getLoginRedirectURL } from '@/src/shared/links';

import { action, videoDetailComputed } from './store';

export default {
  name: 'VideoPlayer',

  components: {
    FontAwesomeIcon,
  },

  directives: {
    frag,
  },

  props: {
    nodeGuid: {
      type: String,
      required: true,
    },
    teaserImageURL: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },

    /** @type {import('vue').PropOptions<{ loginUrl: string, contactUsUrl: string }>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...videoDetailComputed,
    isAuthenticated: rootComputed.isAuthenticated,
    currentHasAccess() {
      return this.hasAccess && this.isAccessValidated;
    },

    lockIcon() {
      return faLock;
    },

    accessLink() {
      return this.isAuthenticated
        ? this.links.contactUsUrl
        : getLoginRedirectURL(this.links.loginUrl);
    },
  },

  store,

  async created() {
    await this.$store.dispatch(action('VALIDATE_ACCESS'), {
      nodeGuid: this.nodeGuid,
    });
  },

  mounted() {
    const monitor = setInterval(() => {
      const elem = document.activeElement;
      if (elem && elem.tagName === 'IFRAME') {
        axios.post(`/api/video/play/${this.nodeGuid}`);
        clearInterval(monitor);
      }
    }, 100);
  },
};
</script>
