<template>
  <div v-frag>
    <UnAuthenticatedHeader v-if="!isAuthenticated" :links="links" />

    <ProfileHeader v-if="isAuthenticated" :links="links" />
  </div>
</template>

<script>
import frag from 'vue-frag';

import { store } from '@/src/rootStore';
import { rootComputed } from '@/src/rootStore/user';

import ProfileHeader from './ProfileHeader.vue';
import UnAuthenticatedHeader from './UnAuthenticatedHeader.vue';

/**
 * @typedef HeaderLinks
 * @property {string} loginUrl
 * @property {string} logoutUrl
 * @property {string} accountUrl
 * @property {string} forgotPasswordUrl
 * @property {string} membershipUrl
 */

export default {
  name: 'Header',

  directives: {
    frag,
  },

  components: {
    ProfileHeader,
    UnAuthenticatedHeader,
  },

  store,

  props: {
    /** @type {import('vue').PropOptions<HeaderLinks>} */
    links: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isAuthenticated: rootComputed.isAuthenticated,
  },
};
</script>
