import axios from 'axios';

import { downloadReport } from '@/src/shared/report-utilities';
import { createResult } from '@/src/shared/result';

import { mutationTypes } from './mutations';

export const actionTypes = {
  ADD_TO_CART: 'ADD_TO_CART',
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  VALIDATE_ACCESS: 'VALIDATE_ACCESS',
};

/**
 *
 * @param {keyof actionTypes} actionType
 */
export function action(actionType) {
  return `reportDetail/${actionType}`;
}

export const actions = {
  /**
   *
   * @param {{
   *   commit: (mutation: string, payload: any ) => void,
   * }} context
   * @param {{ nodeGuid: string, subscriptions: Object[] }} payload
   */
  async [actionTypes.VALIDATE_ACCESS]({ commit }, { nodeGuid, subscriptions }) {
    commit(mutationTypes.UPDATE_IS_LOADING_ACCESS, true);

    const subscriptionNodeGuids = subscriptions.map((s) => s.nodeGuid);

    const productNodeGuids = [nodeGuid, ...subscriptionNodeGuids];

    const result = await createResult(
      axios
        .get('/api/report/access', { params: { productNodeGuids } })
        .then((resp) => resp.data)
    );

    commit(mutationTypes.UPDATE_REPORT_NODE_GUID, nodeGuid);

    if (result.isFailure) {
      const access = productNodeGuids.map((nodeGuid) => ({
        nodeGuid,
        hasAccess: false,
        hasError: true,
      }));

      commit(mutationTypes.UPDATE_IS_ACCESS_VALIDATED, false);
      commit(mutationTypes.UPDATE_SUBSCRIPTION_ACCESS, access);
      commit(mutationTypes.UPDATE_IS_LOADING_ACCESS, false);

      return;
    }

    commit(mutationTypes.UPDATE_IS_ACCESS_VALIDATED, true);
    commit(
      mutationTypes.UPDATE_SUBSCRIPTION_ACCESS,
      result.value.reportProductAccesses
    );
    commit(mutationTypes.UPDATE_IS_LOADING_ACCESS, false);
  },

  /**
   *
   * @param {{
   *   state: import('./state').ReportDetailState
   * }} context
   */
  [actionTypes.DOWNLOAD_REPORT]({ state }) {
    return downloadReport(state.nodeGuid);
  },

  /**
   *
   * @param {{
   *   commit: (mutation: string, payload: any ) => void,
   * }} context
   * @param {Number} skuId
   */
  [actionTypes.ADD_TO_CART](_, skuId) {
    return createResult(axios.post('/api/checkout/cart/item', { skuId }));
  },
};
