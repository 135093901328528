<template>
  <div v-if="areAnyAvailable" class="d-flex justify-content-between">
    <div>
      <button
        v-if="!areAllItemsEnabled"
        :disabled="isLoading"
        class="btn btn-outline-primary mr-4"
        @click="onClick(true)"
      >
        Subscribe to All
      </button>
      <button
        v-if="!areNoItemsEnabled"
        :disabled="isLoading"
        class="btn btn-outline-primary"
        @click="onClick(false)"
      >
        Unsubscribe from All
      </button>
      <Spinner v-if="isLoading" />
    </div>
    <Notification
      v-if="submission.isSubmitted"
      :is-successful="submission.isSuccess"
    />
  </div>
</template>

<script>
import Spinner from '@/src/shared/components/Spinner.vue';

import Notification from './Notification.vue';
import { action } from './store';

export default {
  name: 'EmailPreferencesSectorBulkSelector',

  components: {
    Notification,
    Spinner,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    /** @type {import('vue').PropOptions<import('./store/state').EmailPreference[]>}*/
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      submission: {
        isSubmitted: false,
        isSuccess: false,
      },
      isLoading: false,
    };
  },

  computed: {
    /**
     * @returns {boolean}
     */
    areAllItemsEnabled() {
      return this.items.filter((i) => i.hasAccess).every((i) => i.isSubscribed);
    },

    /**
     * @returns {boolean}
     */
    areNoItemsEnabled() {
      return this.items
        .filter((i) => i.hasAccess)
        .every((i) => !i.isSubscribed);
    },

    /** @returns {boolean} */
    areAnyAvailable() {
      return this.items.some((i) => i.hasAccess);
    },
  },

  methods: {
    /** @param {boolean} subscribe */
    async onClick(subscribe) {
      this.submission.isSubmitted = false;
      this.submission.isSuccess = false;

      const operation = subscribe
        ? action('SUBSCRIBE_TO_EMAIL_SECTOR')
        : action('UNSUBSCRIBE_FROM_EMAIL_SECTOR');

      this.isLoading = true;

      const result = await this.$store.dispatch(operation, this.name);

      this.isLoading = false;

      this.submission.isSuccess = result.isSuccess;
      this.submission.isSubmitted = true;
    },
  },
};
</script>
